import React from "react";

// Layouts
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Pages
import Search from "./pages/Search";
import Account from "./pages/account";
import Member from "./pages/membership";
import StyleTemplate from "./pages/StyleTemplate";
import Prospect from "./pages/prospect";
import Offers from "./pages/offers";
import NewOrder from "./pages/prospect/NewOrder";
import OrderConfirmation from "./pages/prospect/OrderConfirmation";
import OrderVerification from "./pages/prospect/OrderVerification";
import OrderVerificationSteps from "./pages/prospect/OrderVerificationSteps";
import Cases from "./pages/cases/index";
import CasePage from "./pages/case/CasePage";

// Auth
import Page404 from "./pages/auth/Page404";
import Callback from "./pages/auth/Callback";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Search />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/styles",
        element: <StyleTemplate />,
      },
      {
        path: "/prospect/:prospect_guid",
        element: <Prospect />,
      },
      {
        path: "/order/:shopper_short_key/offers",
        element: <Offers />,
      },
      {
        path: "/order/:shopper_short_key/join",
        element: <OrderVerification />,
      },
      {
        path: "/order/:shopper_short_key/verify",
        element: <OrderVerificationSteps />,
      },
      {
        path: "/order/:shopper_short_key/confirm",
        element: <OrderVerificationSteps />,
      },
      {
        path: "/order/:shopper_short_key/final",
        element: <OrderConfirmation />,
      },
      {
        path: "/profiles/:membership_profile_id/accounts/:account_id",
        element: <Account />,
      },
      {
        path: "/profiles/:membership_profile_id",
        element: <Member />
      },
      {
        path: "/order",
        element: <NewOrder />
      },
      {
        path: "/cases",
        element: <Cases />
      },
      {
        path: "/cases/:case_guid",
        element: <CasePage />
      },
    ],
  },
  {
    path: "callback",
    element: <Callback />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    )
  },
];

export default routes;
