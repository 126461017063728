/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Badge, Button, Card, Container, Row, Col } from 'react-bootstrap'
import {
  Home, MapPin,
  MessageSquare,
  Phone, AtSign,
  Users, Hash,
  BarChart, PauseCircle,
  Plus, Clock, Mail,
  Edit3, Clipboard,
  Truck, Tv, Globe,
  UserCheck, User, DollarSign,
  CreditCard, Send } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTransformerBolt, faFireBurner, faFaceViewfinder } from '@fortawesome/pro-duotone-svg-icons'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { DateTime } from 'luxon'
import ContactPrefsModal from './ContactPrefsModal'
import { getMembership } from '../../apis/member'
import { getPTC } from '../../apis/pricing'
import useToast from '../../hooks/useToast'
import { isEmpty } from 'lodash'
import AddressModal from './AddressModal'
import { getAllRewardsForAccount } from '../../apis/rewards'
import Traits from './memberTraits/Traits'
import AccountDropInformation from './AccountDropInformation'
import { dateTimeUTC } from '../../utils/dateTimeUTC'

const MemberProfile = ({ account, prospect, setReloadMember, reloadMember }) => {
  const { membership_profile_id } = useParams()
  const [showContactModal, setShowContactModal] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [member, setMember] = useState({})
  const [allRewards, setAllRewards] = useState([])
  const [ptc, setPTC] = useState({})
  const navigate = useNavigate()
  const { handleAlert } = useToast()
  const [addressId, setAddressId] = useState('')
  const [addressType, setAddressType] = useState('')
  const accountId = account?.account_id
  const cancelDropStatusCodes = ['CANCELLED', 'CANCEL-PENDING', 'DROP_ACCEPT', 'DROP_REJECT', 'DROP_REQUEST']
  Object.freeze(cancelDropStatusCodes)
  const accountIsCancelledOrDropped = cancelDropStatusCodes.includes(account?.account_status_code)

  useEffect(() => {
    if (membership_profile_id) {
      getMembership(membership_profile_id, setMember)
    }
    if (account) {
      getPTC(account, setPTC, handleAlert)
      getAllRewardsForAccount(account.account_id, setAllRewards)
    }
  }, [membership_profile_id, account])

  const handleContactPrefs = () => {
    setShowContactModal(true)
  }

  const handleCloseContactPrefs = () => {
    setReloadMember(!reloadMember)
    setShowContactModal(false)
  }

  const handleAddressModal = (address_id, addressType) => {
    setAddressId(address_id)
    setAddressType(addressType)
    setShowAddressModal(true)
  }

  const handleCloseAddressModal = () => {
    setReloadMember(!reloadMember)
    setShowAddressModal(false)
  }

  const handleProductType = () => {
    if (account.product_type_code === 'NaturalGas') {
      return <FontAwesomeIcon icon={faFireBurner} className="ms-2" transform="shrink-2" color="#ffb002" size="2x" />
    } else {
      return <FontAwesomeIcon icon={faTransformerBolt} className="ms-2" color="#ffb002" fixedWidth size="2x"/>
    }
  }

  const printRewards = () => {
    return allRewards.map(reward => {
      return (
        <li key={reward.reward_id}>
          {parseRewardIcon(reward)} <b>{reward.description}</b>
          <p className='ms-4'>{reward.status_summary}</p>
        </li>
      )
    })
  }

  const parseRewardIcon = (reward) => {
    switch(reward.title) {
      case 'GIFT_CARD':
        return <CreditCard width={17} height={17} className="me-1" />
      case 'TrueBlue Points':
        return <Send width={17} height={17} className="me-1" />
      default:
        return <DollarSign width={17} height={17} className="me-1" />
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Profile" />
      <Container fluid className="p-0">
        {account ?
          <Card>
            <Card.Body className="px-2" data-testid="memberProfile">
              {account.membership_profile &&
              <>
                <Row>
                  <Col md="9">
                    <h1>
                      <Link to="" onClick={handleContactPrefs}>
                        {account.membership_profile.first_name} {account.membership_profile.last_name}
                      </Link>
                    </h1>
                  </Col>
                  <Col md="3">
                    <span className="fa-layers my-2">
                      <FontAwesomeIcon icon={faCircle} color="#ffb002" fixedWidth size="3x" />
                      {handleProductType()}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="9">
                    <Traits member={member}/>
                  </Col>
                </Row>
                </>
              }
              <div className="text-muted mb-2">{account.contact_relationship}</div>
                <div>
                  <Button
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_CRM_URL}/crm/index#/profiles/${membership_profile_id}/account/${accountId}`}
                    target="_blank"
                    size="sm"
                    variant="primary"
                    className="me-2"
                  >
                    Old CRM
                  </Button>
                  <Link to={`/profiles/${membership_profile_id}`} >
                    <Button size="sm" variant="primary" className="me-2">
                      <Users width={16} height={16} /> Member Profile
                    </Button>
                  </Link>
                  <Link to={`/prospect/${account.prospect_guid}`} >
                    <Button size="sm" variant="primary" className="me-0">
                      <User width={16} height={16} /> Prospect
                    </Button>
                  </Link>
                  {prospect?.extole_person_id &&
                    <Link to={`${process.env.REACT_APP_EXTOLE_SITE}/profiles/view#${prospect.extole_person_id}`}>
                      <Button size="sm" variant="primary" className="my-2">
                        <User width={16} height={16}/> Refer a Friend
                      </Button>
                    </Link>
                  }
                </div>
            </Card.Body>
            <hr className="my-0" />
            <Card.Body>
              <Card.Title tag="h5">Account Status</Card.Title>
              <Badge bg="primary" className="me-2 my-1">
                {account.status_description}
              </Badge><span>as of &nbsp;</span>
              <Badge bg="primary" className="me-2 my-1">
                {dateTimeUTC(account.status_change_dt).toLocaleString(DateTime.DATE_SHORT)}
              </Badge>
              <br />
              <span>Signed up on  </span>
              <Badge bg="primary" className="me-2 my-1">
                {dateTimeUTC(account.sale_dt).toLocaleString(DateTime.DATE_SHORT)}
              </Badge>
              <br />
              <span> Current plan </span>
              <Badge bg="primary" className="me-2 my-1">{account.plan_name}</Badge>
              <br />
              {account.confirmed_start_dt &&
                <>
                  <span> Active: </span>
                  <Badge bg="primary" className="me-2 my-1">
                    {dateTimeUTC(account.confirmed_start_dt).toLocaleString(DateTime.DATE_SHORT)}
                  </Badge>
                  {account.confirmed_drop_dt &&
                    <>
                      <span>to &nbsp;</span>
                      <Badge bg="primary" className="me-2 my-1">
                        {dateTimeUTC(account.confirmed_drop_dt).toLocaleString(DateTime.DATE_SHORT)}
                      </Badge>
                    </>
                  }
                  <br />
                </>
              }
            </Card.Body>
            <hr className="my-0" />
            {accountIsCancelledOrDropped &&
              <AccountDropInformation account={account} />
            }
            <hr className="my-0" />
            <Card.Body>
              <ul className="list-unstyled mb-0">
                <li>
                  <b>Utility Account Name</b>
                  <p>{account.utility_full_name || 'N/A'}</p>
                </li>
                <li>
                  <b>Initial Authorizer</b>
                  <p>{account.contact_first_name} {account.contact_last_name}</p>
                </li>
                <li>
                  <b>Contact Relationship</b>
                  <p>{account.contact_relationship || 'Unknown'}</p>
                </li>
                <hr/>
                <Card.Title tag="h5"><b>About</b></Card.Title>
                <li className="d-flex align-middle mb-2">
                  <Home width={17} height={17} className="me-1" />
                  <Link to="" onClick={() => handleAddressModal(account?.service_address_id, "Service")}>
                    <b>Lives at: &nbsp;</b>
                    <span>{account.service_address_line_1} {account.service_address_line_2} {account.service_city} {account.service_state} {account.service_zip_code}</span>
                  </Link>
                </li>
                {account.mailing_address_address_id &&
                  <li className="d-flex align-middle mb-2">
                    <Mail width={17} height={20} className="me-1" />
                    <Link to="" onClick={() => handleAddressModal(account?.mailing_address_address_id, "Mailing")}>
                      <b>Mailing address: &nbsp;</b>
                      <span>{account.mailing_address_street} {account.mailing_address_unit} {account.mailing_address_city} {account.mailing_address_state_code} {account.mailing_address_zip}</span>
                    </Link>
                  </li>
                }
                <li className="d-flex align-middle mb-2">
                  <Phone width={17} height={17} className="mt-slight me-1" />
                  <Link to="" onClick={handleContactPrefs}><b>Phone: &nbsp;</b>{account.primary_phone?.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}</Link>
                </li>
                <li className="d-flex align-middle mb-2">
                  <AtSign width={17} height={17} className="mt-slight me-1" />
                  <Link to="" onClick={handleContactPrefs}><b>Email: &nbsp;</b>{account.email}</Link>
                </li>
                {prospect &&
                  <li className="d-flex align-middle mb-2">
                    <Globe width={17} height={17} className="mt-slight me-1" />
                    <b>Speaks: &nbsp;</b>
                    <p>{prospect?.language_preference}</p>
                  </li>
                }
                <li className="d-flex mb-1">
                  <Plus width={17} height={17} className="mt-slight me-1" /> {" "}
                  <Link to="" onClick={handleContactPrefs}>Contact Preferences</Link>
                </li>
              </ul>
            </Card.Body>
            <hr className="my-0" />
            <Card.Body>
              <Card.Title tag="h5"><b>Utility</b></Card.Title>
              <ul className="list-unstyled mb-0">
                {account.market &&
                  <li className="d-flex mb-3">
                    <MapPin width={17} height={17} className="me-1" />
                    <b>Utility: &nbsp; </b>
                    <a href={`${process.env.REACT_APP_CUST_SITE}/utilities/${account.market_code}/bill-samples/${account.contract_type_code}`} target="_blank" rel="noreferrer" >{account.market_code}</a>
                  </li>
                }
                <li className="d-flex">
                  <Hash width={17} height={17} className="me-1" />
                  {account.market.account_num_synonym ?
                    <b style={{textTransform: "capitalize"}}>{account.market.account_num_synonym}: &nbsp;</b>
                  :
                    <b>Account: &nbsp;</b>
                  }
                  <p className="ml-2 pl-2">{account.account_number}</p>
                </li>
                {ptc.found_rate ?
                  <li className="d-flex">
                    <DollarSign width={17} height={17} className="me-1" />
                    <p><b>PTC: &nbsp;</b></p> {(ptc.rate)*100} per {ptc.rate_units} <br/>
                    Start Date: {DateTime.fromISO(ptc.from_dt).toLocaleString(DateTime.DATE_MED)} <br/>
                    End Date: {DateTime.fromISO(ptc.to_dt).toLocaleString(DateTime.DATE_MED)}
                  </li>
                :
                  <li className="d-flex">
                    <DollarSign width={17} height={17} className="me-1" />
                    <p><b>PTC Rate Not Found</b></p>
                  </li>
                }
                <li className="d-flex">
                  <BarChart width={17} height={17} className="me-1" />
                  <p><b>Monthly Usage: &nbsp;</b> {account.avg_monthly_usage} average kWh/month</p>
                </li>
                <li className="d-flex">
                  <BarChart width={17} height={17} className="me-1" />
                  <p><b>Yearly Usage: &nbsp;</b> {account.avg_annual_usage_kw} average kWh/year</p>
                </li>
                <li className="d-flex">
                  <PauseCircle width={17} height={17} className="me-1" />
                  <b>Meter Reading: &nbsp;</b>
                  <p>TBD</p>
                </li>
              </ul>
            </Card.Body>
            {showContactModal &&
              <ContactPrefsModal
                handleClose={handleCloseContactPrefs}
                member={member}
                account={account}
                show={showContactModal}
              />
            }
            {showAddressModal &&
              <AddressModal
                handleClose={handleCloseAddressModal}
                addressId={addressId}
                addressType={addressType}
                accountId={accountId}
                show={showAddressModal}
              />
            }
            <hr className="my-0" />
            <Card.Body>
              <Card.Title tag="h5"><b>Rewards</b></Card.Title>
              {!isEmpty(allRewards) ?
                <ul className="list-unstyled mb-0">
                  {printRewards()}
                </ul>
                : <p>No rewards exist for this account</p>
              }
            </Card.Body>
          </Card>
        :
          <Card>
            {prospect &&
              <>
                <Card.Body className="mt-2">
                  <Row>
                    <Col md="8">
                      <h1>
                        {prospect.first_name} {prospect.last_name}
                      </h1>
                    </Col>
                    <Col className="fa-layers fa-fw mt-2">
                      <FontAwesomeIcon icon={faCircle} color="#ffb002" size="3x" />
                      <FontAwesomeIcon icon={faFaceViewfinder} color="#ffb002" fixedWidth size="2x" title="Bart" />
                    </Col>
                  </Row>
                  <div className="text-center">
                    <div className="text-muted mb-2">{prospect.contact_relationship}</div>
                      <div>
                        <Button size="lg" variant="outline-primary" className="mb-3" onClick={() => navigate(`/order?prospect_guid=${prospect.prospect_guid}`)}>
                          New Order
                        </Button>
                      </div>
                    <div>
                      {!isEmpty(prospect.accounts[0])&&
                        <Link to={`/profiles/${prospect?.accounts[0]?.membership_profile_id}/accounts/${prospect?.accounts[0]?.account_id}`} >
                          <Button size="sm" variant="primary" className="me-1">
                            <UserCheck width={16} height={16} /> Account
                          </Button>
                        </Link>
                      }
                      <Button size="sm" variant="primary">
                        <MessageSquare width={16} height={16} /> Message
                      </Button>
                    </div>
                  </div>
                </Card.Body>
                <hr className="my-0" />
                <Card.Body>
                  <Card.Title tag="h5">About</Card.Title>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex">
                      <Home width={17} height={17} className="me-1" />
                      <b>Address: &nbsp;</b>
                      <p>{prospect.service_address_line_1} {prospect.service_address_line_2}, {prospect.service_city} {prospect.service_state}</p>
                    </li>
                    <li className="d-flex">
                      <Phone width={17} height={17} className="me-1" />
                      <b>Phone: &nbsp;</b>
                      <p>{prospect.primary_phone?.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') || 'N/A'}</p>
                    </li>
                    <li className="d-flex">
                      <AtSign width={17} height={17} className="me-1" />
                      <b>Email: &nbsp;</b>
                      <p>{prospect.email?.email_address || 'N/A'}</p>
                    </li>
                    <li className="d-flex">
                      <Globe width={17} height={17} className="me-1" />
                      <b>Speaks: &nbsp;</b>
                      <p>{prospect.language_preference || 'N/A'}</p>
                    </li>
                    <li className="mb-1">
                      <Plus width={17} height={17} className="me-1" />
                      <Link to="" onClick={handleContactPrefs}>Contact Preferences</Link>
                    </li>
                  </ul>
                </Card.Body>
                <hr className="my-0" />
                <Card.Body>
                  <Card.Title tag="h5">Prospect Info</Card.Title>
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex mb-1">
                      <Clipboard width={17} height={17} className="me-1" />
                        <b>Account Type: &nbsp;</b>
                        {prospect.partner_account_type ?
                          <span style={{color: "#0E7C9E"}}>{prospect.partner_account_type}</span>
                        :
                          <span>Unavailable</span>
                        }
                    </li>
                    <li className="d-flex mb-1">
                      <Clock width={17} height={17} className="me-1" />
                      { prospect.account_status_code ?
                        <Badge bg="primary" className="me-2 my-1"> {prospect.account_status_code}</Badge>
                      :
                        <Badge bg="primary" className="me-2 my-1">Prospect status unavailable</Badge>
                      }
                      <span>  as of &nbsp;</span>
                      <Badge bg="primary" className="me-2 my-1">
                        {dateTimeUTC(prospect.updated_at).toLocaleString(DateTime.DATE_MED)}
                      </Badge>
                    </li>
                    <li className="d-flex mb-1">
                      <Edit3 width={17} height={17} className="me-1" />
                      <b>Created on: &nbsp;</b>
                      <Badge bg="primary" className="me-2 my-1">
                        {dateTimeUTC(prospect.created_at).toLocaleString(DateTime.DATE_MED)}
                      </Badge>
                    </li>
                    <li className="d-flex">
                      <MapPin width={17} height={17} className="me-1" />
                      <b>Utility: &nbsp;</b>
                      <p>{prospect.market_code || 'N/A'}</p>
                    </li>
                    <li className="d-flex">
                      <Truck width={17} height={17} className="me-1" />
                      <b>Source: &nbsp;</b>
                      <p>{prospect.source_code}</p>
                    </li>
                    <li className="d-flex">
                      <Tv width={17} height={17} className="me-1" />
                      <b>Channel: &nbsp;</b>
                      <p>{prospect.channel_partner_code || 'N/A'}</p>
                    </li>
                  </ul>
                  <br />
                </Card.Body>
                <hr className="my-0" />
                {showContactModal &&
                  <ContactPrefsModal
                    handleClose={handleCloseContactPrefs}
                    prospect={prospect}
                    show={showContactModal}
                  />
                }
              </>
            }
          </Card>
        }
      </Container>
    </React.Fragment>
  )
}

export default MemberProfile
