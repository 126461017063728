export const getOffersParams = (userInfo, shopper) => {
  const params = {
    channel_code: shopper.channel_code,
    // for DM shoppers the userInfo.source_code was overriding the shopper.source_code and consequentially not returning any offers
    source_code: shopper.source_code || userInfo.source_code,
    revenue_class_code: shopper.revenue_class_code,
    agent_id:  userInfo.email,
    service_address_line_1: shopper.service_address?.street || shopper.service_address_line_1,
    service_address_line_2: shopper.service_address?.unit || shopper.service_address_line_2,
    service_city: shopper.service_address?.city || shopper.service_city,
    service_state: shopper.service_address?.state_code || shopper.service_state,
    service_zip_code: shopper.service_address?.zip || shopper.service_zip_code,
    market_code: shopper.market_code,
    account_number: shopper.account_number || null, // (OPTIONAL)
    first_name: shopper.first_name,
    last_name: shopper.last_name,
    prospect_guid: shopper.prospect_guid || null, // (OPTIONAL)
    campaign_id: shopper.campaign_id || null, // (OPTIONAL)
    enrollment_type: shopper.enrollment_type || null,
    // we need to pass in the shopper guid so a new shopper isn't created
    shopper_guid: shopper.shopper_guid || null
  }
  return params
}
