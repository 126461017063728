import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/pro-light-svg-icons'
import isEmpty from 'lodash/isEmpty'

const CaseAttachments = ({caseDetails, handleUploadAttachment, handleDeleteAttachment}) => {
  const [file, setFile] = useState()

  return (
    <>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p> Attachments: </p>
        </Col>
        <Col>
        {isEmpty(caseDetails.attachments) ?
          <p>No attachments</p>
          :
          caseDetails.attachments?.map((attachment, idx) => (
            <div key={idx}>
              <p>
                <a href={attachment.attachment_loc} rel="noreferrer" target="_blank"><b>{attachment.original_file_name} - {DateTime.fromISO(attachment.file_created_dt).toLocaleString(DateTime.DATE_MED)} by {attachment.email} </b></a>
              <span style={{cursor: 'pointer'}}>
                <FontAwesomeIcon
                data-testid="delete-icon"
                size="sm"
                className="me-1"
                color="#E86371"
                icon={faTrashCan}
                fixedWidth
                onClick={()=> handleDeleteAttachment(attachment.original_file_name)}/>
              </span>
              </p>
            </div>
          ))
        }
        </Col>
      </Row>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p> Add Attachment: </p>
        </Col>
        <Col>
        <Form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])}/>
          </Form.Group>
          <Button onClick={()=> handleUploadAttachment(file)}>Add Attachment</Button>
        </Form>
        </Col>
      </Row>
    </>
  )
}

export default CaseAttachments
