import React from 'react'
import ReactTableBasic from  '../../ReactTableBasic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/pro-light-svg-icons'
import { DateTime } from 'luxon'
import { viewTerms } from '../../../apis/correspondences'
import useToast from '../../../hooks/useToast'

const PlanTerms = ({ offerHistories} ) => {
  const { handleAlert } = useToast()

  const tableColumns = [
    {
      Header: 'Plan',
      accessor: 'plan_name',
    },
    {
      Header: 'Rate',
      accessor: 'rate_friendly',
      width: 100,
    },
    {
      Header: 'From Date',
      accessor: 'from_dt',
      Cell: (e) => (
        e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      )
    },
    {
      Header: 'To Date',
      accessor: 'to_dt',
      Cell: (e) => (
        e.value? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      ),
    },
    {
      Header: 'Reason',
      accessor: 'reason',
    },
    {
      Header: 'Confirmation Code',
      accessor: 'confirmation_code',
      Cell: (e) => (
        e.value ? e.value : 'Not Available'
      )
    },
    { Header: 'View Terms',
      accessor: 'terms_archive_loc',
      headerClassName: 'text-center',
      width: 80,
      Cell: (cell) => (
      <div className="text-center">
        {cell.value ?
          <span
          className="primary-anchor"
          variant="primary"
          target="_blank"
          rel="noreferrer"
          onClick={() => viewTerms(cell.value, handleAlert)} >
          <FontAwesomeIcon size="2x" className="faux-button" icon={faUpRightFromSquare} />
          </span>
          : <p>N/A</p> }
      </div>
      ),
    }
  ]

  return (
    <div className="mt-4">
      <div className="mt-3 mb-5">
        <ReactTableBasic
          title="Plan Terms"
          columns={tableColumns}
          data={offerHistories}
          canHover={false}
        />
      </div>
    </div>
  )
}

export default PlanTerms
