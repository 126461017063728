import React, { useEffect, useState } from 'react'
import ReactTableBasic from '../../ReactTableBasic'
import isEmpty from 'lodash/isEmpty'
import Offers from './Offers'
import RejectTool from './RejectTool'
import CreateCaseFormDefault from '../../cases/createCase/CreateCaseFormDefault'
import { getUtility } from '../../../apis/utilities'
import useToast from '../../../hooks/useToast'
import { formatter } from '../../../utils/currencyFormatter'
import { DateTime } from 'luxon'

const InvoiceHistory = ({ account, setReloadMember }) => {
  const utilityCode = account?.market?.utility_code || ''
  const invoices = account.invoices || []
  const [showCaseForm, setShowCaseForm] = useState(false)
  const [utilityInfo, setUtilityInfo] = useState({})
  const { handleAlert } = useToast()

  useEffect(() => {
    getUtility(utilityCode, setUtilityInfo, handleAlert)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilityCode])

  const tableColumns = [
    {
      Header: 'Invoice Date',
      accessor: invoices => `${DateTime.fromISO(invoices.charge_dt).toLocaleString(DateTime.DATE_MED)}`,
      width: 105,
    }, { Header: 'Type',
      accessor: 'type_desc',
      width: 70
    }, {
      Header: 'Rate',
      accessor: invoices => invoices.raw_invoiced_rate !== null ? `$${invoices.raw_invoiced_rate}` : `${invoices.raw_invoiced_rate}`,
      width: 65,
    }, {
      Header: 'Usage',
      accessor: invoices =>  `${invoices.usage_total_num} ${utilityInfo.native_unit_of_measure}`,
      width: 75,
    }, {
      Header: 'vs Prior',
      accessor: invoices => Number.isInteger(invoices.usage_prev_month_percent_diff) ? `${invoices.usage_prev_month_percent_diff}%`: invoices.usage_prev_month_percent_diff,
      width: 70,
    }, {
      Header: 'Supply Charge',
      accessor: invoices => `${formatter.format(invoices.calc_price_amt)}`,
      width: 115,
    }, {
      Header: 'Supply Tax Amount',
      accessor: invoices => `${formatter.format(invoices.price_tax_amt)}`,
      width: 140,
    }, {
      Header: 'Usage Days',
      accessor: invoices => invoices.usage_days_cnt && `${invoices.usage_days_cnt} days`,
      width: 95,
    }, {
      Header: 'PTC',
      accessor: invoices => invoices.price_to_compare && `$${(invoices.price_to_compare?.rate)}`,
      width: 70,
    }, {
      Header: 'Price Difference',
      accessor: invoices => invoices.price_to_compare && `$${(invoices.calc_total_amt - (invoices.usage_total_num * invoices.price_to_compare?.rate)).toFixed(2)}`,
      width: 125,
    }, {
      Header: 'Final Usage',
      accessor: invoices => invoices.final_usage_ind ? `${invoices.final_usage_ind}` : `No`,
      width: 95,
    }, {
      Header: 'Estimate',
      accessor: invoices => invoices.estimate_ind ? `${invoices.estimate_ind}` : `No`,
      width: 80,
    }
  ]

  return (
    <>
      { account.status_group === 'Rejected' &&
        <RejectTool account={account} />
      }
      <Offers account={account} showCaseForm={showCaseForm} setShowCaseForm={setShowCaseForm} />
      {
        isEmpty(invoices) ?
        <p className="text-center">The Account Has No Invoice History</p> :
        <div style={{ maxHeight: '40vh', overflow: 'scroll' }}>
          <ReactTableBasic
            title="Invoices"
            className="px-4"
            columns={tableColumns}
            data={invoices}
            canHover={false}
          />
        </div>
      }
      {showCaseForm &&
        <CreateCaseFormDefault showCaseForm={showCaseForm} setShowCaseForm={setShowCaseForm} member={account} setReloadMember={setReloadMember} />
      }
    </>
  )
};

export default InvoiceHistory;
