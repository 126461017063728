import React, { useState } from 'react'
import { Modal, Button, Row, Form } from 'react-bootstrap'
import { addMemberNote } from '../../../apis/prospects'
import useUser from '../../../hooks/useUser'

const AddNoteModal = ({ handleClose, member, show, refreshEvents }) => {
  const [note, setNote] = useState('')
  const { userInfo } = useUser()

  const handleSubmitNote = async () => {
    const newNote = {
      prospect_guid: member.membership_profile.prospect_guid,
      note: note,
      agent_id: userInfo.email,
      agent_name: userInfo.email,
    }
    const response = await addMemberNote(newNote)
    if (response) {
      refreshEvents()
      handleClose()
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Add A Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Add a note about this member:</Form.Label>
              <Form.Control as="textarea" rows={3}  value={note} onChange={(e) => setNote(e.target.value)}/>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="col-12" aria-label="Submit note" variant="success" onClick={handleSubmitNote}>
            Add Note
          </Button>
          <Button className="col-12" aria-label="Close modal" variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddNoteModal
