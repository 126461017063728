/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ReactTableBasic from  '../../ReactTableBasic'
import { getCorrespondenceLetters, getWelcomeKit } from '../../../apis/correspondences'
import { Container, Row, ProgressBar } from 'react-bootstrap'
import { sendCorrespondence, emailCorrespondence, viewCorrespondence } from '../../../apis/correspondences'
import { sendProspectEvent } from '../../../apis/prospects'
import { renderTermsUrl, renderSendCorrespondence } from './correspondenceTableElements'
import useUser from '../../../hooks/useUser'
import useToast from '../../../hooks/useToast'
import { DateTime } from 'luxon'
import isEmpty from 'lodash/isEmpty'

const AccountCorrespondence = ({ account }) => {
  const accountId = account.account_id
  const { userInfo } = useUser()
  const { handleAlert } = useToast()
  const [accountCorrespondence, setCorrespondence] = useState(null)
  const [welcomeKit, setWelcomeKit] = useState({})
  const [completeCorrespondence, setCompleteCorrespondence] = useState([])
  const defaultSending = {isSending: false, message: ''}
  const[sending, setSending] = useState(defaultSending)
  const googleReview = {
    correspondence_type_code: 'Rate Us on Google',
    can_resend_via_email: true
  }
  const bbbReview = {
    correspondence_type_code: 'Review Us on Better Business Bureau',
    can_resend_via_email: true
  }

  useEffect(() => {
    getCorrespondenceLetters(accountId, setCorrespondence)
    getWelcomeKit(accountId, setWelcomeKit, handleAlert)
  }, [])

  useEffect(() => {
    if (accountCorrespondence && !isEmpty(accountCorrespondence)) {
      if(isEmpty(welcomeKit)) {
        setCompleteCorrespondence(accountCorrespondence.concat(googleReview).concat(bbbReview))
      } else {
        setCompleteCorrespondence(accountCorrespondence.concat(welcomeKit).concat(googleReview).concat(bbbReview))
      }
    } else if (!isEmpty(welcomeKit)) {
      setCompleteCorrespondence(completeCorrespondence.concat(welcomeKit).concat(googleReview).concat(bbbReview))
    }
  }, [accountCorrespondence, welcomeKit])


  const handleSendCorrespondence = (data, mailType) => {
    setSending({isSending: true, message: 'Sending in progress'}) 
    setTimeout(() => {
      handleSendMode(data, mailType)
    }, 1000)
  }

  const handleSendMode = async (data, mailType) => {
    const prospectEvents = ['WELCOME_LETTER', 'Rate Us on Google', 'Review Us on Better Business Bureau']
    let apiResponse
    if (mailType !== 'Email') {
      apiResponse = await sendCorrespondence(data.row.original, userInfo, handleAlert)
    } else if (prospectEvents.includes(data.row.original.correspondence_type_code)){
      apiResponse =  await sendProspectEvent(data.row.original.correspondence_type_code, userInfo, account, handleAlert)
    } else {
      apiResponse = await emailCorrespondence(data.row.original, userInfo, handleAlert)
    }
    if (apiResponse.status === 200) {
      setSending(defaultSending)
      handleAlert('Success! Correspondence re-sent!', true)
    }
  }

  const handleViewCorrespondence = async (correspondence) => {
    viewCorrespondence(accountId, correspondence, handleAlert)
  }

  const tableColumns = [
    {
      Header: 'Type',
      accessor: 'correspondence_type_code',
    },
    { Header: 'View',
      accessor: 'archive_loc',
      width: 70 ,
      Cell: (e) => (
      <div className="text-center">
        {renderTermsUrl(e, handleViewCorrespondence)}
      </div>
      )
    },
    { Header: 'Send',
      accessor: 'can_resend_via_email',
      width: 90,
      Cell: (e) => (
        <div className="text-center">
        <Container>
          <Row sm={2}>
            {renderSendCorrespondence(e, handleSendCorrespondence, sending)}
          </Row>
        </Container>
        </div>
      )
    },
    {
      Header: 'Last Resent',
      accessor: 'last_resent_dt',
      Cell: (e) => (
        <div>
        {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
        </div>
      )
    },
    {
      Header: 'Initially Sent',
      accessor: 'queued_dt',
      Cell: (e) => (
        <div >
        {e.value ? <p> {e.row.original.batch_status_code} {DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED)} </p> : '' }
        </div>
      ),
    },
  ]

  return (
    <div className="mt-5">
      <div className="mt-3" data-testid="accountCorrespondence">
        {!isEmpty(completeCorrespondence) ?
          <>
            <ReactTableBasic
              title="Account Correspondences"
              data-testid="correspondence-table"
              columns={tableColumns}
              data={completeCorrespondence}
              canHover={false}
            />
            <div className="mt-3 text-center">
              <h4>{sending.message}</h4>
              {sending.isSending &&
                <ProgressBar striped data-testid="progress-bar" variant="lightElectric" now={60} />
              }
            </div>
          </>
          :
          <h3> No Account Correspondence found for Account: {accountId} </h3>
        }
      </div>
    </div>
  )
}

export default AccountCorrespondence
