import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { getRewardsByUnitForAccount } from '../../../apis/rewards'
import { isEmpty } from 'lodash'
import RewardsTable from './RewardsTable'
import RewardModal from './RewardModal'


const RewardsContainer = ({ account }) => {
  const [rewards, setRewards] = useState([])
  const [reward, setReward] = useState({})
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getRewardsByUnitForAccount(account.account_id, setRewards)
  }, [account.account_id])

  const rowOnClick = (e) => {
    if (e.description){
      setReward(e)
      setShowModal(true)
    }
  }

  return (
    <>
      <Card>
          <Card.Body >
          <Card.Title tag="h2" className="mb-2">
            Inspire Rewards Program - {account.plan_name}
          </Card.Title>
          <hr />
          <p className="mb-2">{account.plan_rewards_description}</p>
          {isEmpty(rewards) ?
          <p className="mb-2">
            No rewards activity yet. Rewards will start to accrue once the account becomes Active
          </p>
          :
          <RewardsTable  rewards={rewards} rowOnClick={(e) => rowOnClick(e)}/>
          }
          {showModal &&
            <RewardModal
              account={account}
              reward={reward}
              showModal={showModal}
              handleClose={() => setShowModal(false)}
            />
          }
        </Card.Body>
      </Card>
    </>
  )
}

export default RewardsContainer
