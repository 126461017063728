import React from "react";
import { Dropdown } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const NavbarUser = () => {
  const { user, logout } = useAuth0()

  const clear = () => {
    logout({ returnTo: window.location.origin })
  }

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            referrerPolicy="no-referrer"
            src={user.picture}
            className="avatar img-fluid rounded-circle me-1"
            alt=""
          />
          <span className="text-dark">{user.name}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={clear}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
