import axios from 'axios'

export const getUser = async (email, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/users?email=${email}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getUsersWithEmail = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/users`)
    const internalUsers = response.data.filter(user => (user.email !== null && user.email.includes('inspirecleanenergy.com')))
    callback(internalUsers)
  } catch (error) {
    throw error
  }
}

export const getAllUsers = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/users`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}
