import React, {useState, useEffect, useRef} from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import RefundCalculationRow from './RefundCalculationRow'
import { v4 as uuidv4 } from 'uuid'
import { isJsonStructure } from '../../../utils/isJsonStructure'
import findCaseAttribute from '../helpers/findCaseAttribute'

const RefundCalculationContainer = ({ caseTypeAttributes, setCaseTypeAttributes, handleUpdateCaseAttribute }) => {
  const defaultInputObject = {
    month: '',
    usage: '',
    inspireRate: '',
    quotedRate: ''
  }
  const [refundArray, setRefundArray] = useState([])
  const [refundInputObject, setRefundInputObject] = useState(defaultInputObject)
  const showPage = useRef(false)
  const refundAmountAttribute = findCaseAttribute('refund_amount', caseTypeAttributes)
  const refundCalculationAttribute = findCaseAttribute('refund_calculation', caseTypeAttributes)

 useEffect(() => {
    if(refundCalculationAttribute?.value){
      // on case show page, check if value is json object, if so parse it to display data
      if(isJsonStructure(refundCalculationAttribute.value)){
        showPage.current = true
        setRefundArray(JSON.parse(refundCalculationAttribute.value))
      }
    }
  }, [refundCalculationAttribute])

  // this only runs on the case show page, to update the case attributes
  const updateRefundAttributes = () => {
    refundCalculationAttribute.value = JSON.stringify(refundCalculationAttribute.value)
    handleUpdateCaseAttribute(refundAmountAttribute)
    handleUpdateCaseAttribute(refundCalculationAttribute)
  }
  const calculateRefundForRate = () => {
    const newRefundArray = refundArray.concat(refundInputObject)
    setRefundArray(newRefundArray)
    refundAmountAttribute.value = newRefundArray.reduce((sum, row) => {
      let usage = parseFloat(row.usage)
      let inspireRate = parseFloat(row.inspireRate)
      let quotedRate = parseFloat(row.quotedRate)
      let exact = (usage * inspireRate) - (usage * quotedRate) + sum
      let rounded = Math.round(exact * 100) / 100
      return rounded
    }, 0)

    refundCalculationAttribute.value = newRefundArray
    setRefundInputObject(defaultInputObject)
    updateRefundCaseTypeAttributes()
    if (showPage.current) updateRefundAttributes()
  }

  const updateRefundCaseTypeAttributes = () => {
    const copyAttributesWithNewData = caseTypeAttributes.map(attribute => ({...attribute}))
    setCaseTypeAttributes(copyAttributesWithNewData)
  }

  const calculatePriceDifferenceForInput = (refundObject) => {
    if(!Object.values(refundObject).some(inputValue => inputValue === '')){
      const calculatedPriceDifference = (parseFloat(refundObject.usage) * parseFloat(refundObject.inspireRate)) - (parseFloat(refundObject.usage) * parseFloat(refundObject.quotedRate))
      const roundedPriceDifference = Math.round(calculatedPriceDifference * 100) / 100
      return roundedPriceDifference
    }
  }

  const inputFieldsInvalid = () => {
    return Object.values(refundInputObject).some(inputValue => inputValue === '')
  }

  const removeRowFromRefundCalculation = (refundInput) => {
    const updatedRefundArray = refundArray.filter(object => {
      return object !== refundInput
    })
    const refundInputPriceDifference = calculatePriceDifferenceForInput(refundInput)
    refundAmountAttribute.value = refundAmountAttribute.value - refundInputPriceDifference
    refundCalculationAttribute.value = updatedRefundArray
    updateRefundCaseTypeAttributes()
    setRefundArray(updatedRefundArray)
    if (showPage.current) updateRefundAttributes()
  }

  return (
    <>
      <Row className="my-3">
        <Form.Group controlId="refund-address">
            <Form.Label><b>Refund Calculation</b></Form.Label>
        </Form.Group>
      </Row>
      <Row className="border my-1">
        <Row className="my-1">
          <Col>
            <Form.Group controlId="month">
            <Form.Label>Month</Form.Label>
            <Form.Control
                  type="text"
                  name="month"
                  onChange={(e) => setRefundInputObject(refundInputObject => ({...refundInputObject, [e.target.name]: e.target.value}))}
                  value={refundInputObject.month}
                />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="usage">
            <Form.Label>Usage</Form.Label>
            <Form.Control
                  type="text"
                  name="usage"
                  onChange={(e) => setRefundInputObject(refundInputObject => ({...refundInputObject, [e.target.name]: e.target.value}))}
                  value={refundInputObject.usage}
                />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="inspireRate">
            <Form.Label>Inspire Rate</Form.Label>
            <Form.Control
                  type="text"
                  name="inspireRate"
                  onChange={(e) => setRefundInputObject(refundInputObject => ({...refundInputObject, [e.target.name]: e.target.value}))}
                  value={refundInputObject.inspireRate}
                />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="quotedRate">
            <Form.Label>Quoted Rate</Form.Label>
            <Form.Control
                  type="text"
                  name="quotedRate"
                  onChange={(e) => setRefundInputObject(refundInputObject => ({...refundInputObject, [e.target.name]: e.target.value}))}
                  value={refundInputObject.quotedRate}
                />
            </Form.Group>
          </Col>
          <Col>
            <p><b>Difference</b></p>
            {calculatePriceDifferenceForInput(refundInputObject)}
          </Col>
          <Col>
            <Button className="mt-3" disabled={inputFieldsInvalid()} onClick={() => calculateRefundForRate()}><b>+</b></Button>
          </Col>
        </Row>
        {refundArray.map(row => (
          <RefundCalculationRow
            key={uuidv4()}
            refundCalculationObject={row}
            removeRowFromRefundCalculation={removeRowFromRefundCalculation}
          />
        ))}
      </Row>
    </>
  )
}

export default RefundCalculationContainer
