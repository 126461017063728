import React, { useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
const ToastContext = React.createContext({});

function ToastProvider({ children }) {
  const [toast, setToast] = useState(false)
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [position, setPosition] = useState('middle-center')
  const toastBgColor = success ? 'lightElectric' : 'danger'

  const handleAlert = (message, success, toastPosition = null) => {
    setSuccess(success)
    setMessage(message)
    if (toastPosition) {
      setPosition(toastPosition)
    }
    setToast(true)
  }

  return (
    <ToastContext.Provider value={{handleAlert}}>
      {children}
      <ToastContainer className="p-3 position-fixed" position={position}>
        <Toast bg={toastBgColor} onClose={() => setToast(false)} show={toast} delay={5000} autohide>
          <Toast.Body className="text-center">{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </ToastContext.Provider>
  );
}

export { ToastProvider, ToastContext };