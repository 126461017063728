import axios from 'axios'
import { getUser } from './users'

export const getToken = async(accessToken, email, callback) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/tokens`, {}, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    const token = response.data.token
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    localStorage.setItem('idToken', token)
    getUser(email, callback)
  } catch (error) {
    throw error
  }
}
