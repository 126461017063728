/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactTableBasic from '../../ReactTableBasic'
import useToast from '../../../hooks/useToast'
import { getReferralProgram } from '../../../apis/rewards'
import { DateTime } from 'luxon'
import { formatter } from '../../../utils/currencyFormatter'

const ReferAFriendModal = ({ account, reward }) => {
  const { handleAlert } = useToast()
  const [referralProgram, setReferralProgram] = useState({})

  useEffect(() => {
    getReferralProgram(account.account_id, setReferralProgram, handleAlert)
  }, [])

  const referralColumns = [
    {
      Header: 'Name',
      accessor: 'full_name',
    }, {
      Header: 'Reward',
      accessor: 'reward_amount',
      Cell: (e) => (
        <div>
          {e.value ? formatter.format(e.value) : ''}
        </div>
      )
    }, {
      Header: 'Accrued',
      accessor: 'accrued_dt',
      Cell: (e) => (
        <div>
          {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
        </div>
      )
    }, {
      Header: 'Vested',
      accessor: 'vest_dt',
      Cell: (e) => (
        <div>
          {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
        </div>
      )
    }, {
      Header: 'Fulfilled',
      accessor: 'fulfillment_channel'
    }
  ]

  return (
    <>
      <h3> Refer A Friend</h3>
      <h4>{referralProgram.description}</h4>
      {reward.fulfillment_batch_id &&
        <p>Fulfillment Batch ID: {reward.fulfillment_batch_id}</p>
      }
      <h4 className="ms-2">Details</h4>
      <Container className="px-5">
        <Row>
          <Col md={4}>
            Referral ID:
          </Col>
          <Col>
            {account.referrer_guid}
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col md={4}>
            Shareable Signup Landing Page:
          </Col>
          <Col>
          <a href={referralProgram.referral_landing_page_url}  rel="noreferrer" target="_blank">{referralProgram.referral_landing_page_url}</a>
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col md={4}>
          Refer a Friend Portal:
          </Col>
          <Col>
          <a href={referralProgram.referral_program_url} rel="noreferrer" target="_blank">{referralProgram.referral_program_url}</a>
          </Col>
          <hr/>
        </Row>
      </Container>
      {referralProgram.referrals &&
        <>
          <div className="my-3 px-2">
            <ReactTableBasic
              title="Pending"
              columns={referralColumns}
              data={referralProgram.referrals?.accrued.details}
              canHover={false}
            />
          </div>
          <div className="my-3 px-2">
            <ReactTableBasic
              title="In Process"
              columns={referralColumns}
              data={referralProgram.referrals?.earned.details}
              canHover={false}
            />
          </div>
          <div className="my-3 px-2">
          <ReactTableBasic
            title="Completed"
            columns={referralColumns}
            data={referralProgram.referrals?.settled.details}
            canHover={false}
          />
          </div>
        </>
      }
    </>
  )
}

export default ReferAFriendModal
