import axios from 'axios'
import { DateTime } from 'luxon'

export const getAccount = async(accountId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getAccountEnergyImpact = async(account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/energy_impact`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getSurveyResponses = async(agent_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/contacts/survey/responses/${agent_id}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const searchAccounts = async(query, callback) => {
  try {
    const params = {
      query: query,
      page: 1,
      per_page: 100,
    }
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/accounts/search`, {params})
    if(response.data){
      callback(response.data.accounts)
      return response.data
    }
  } catch (error) {
    throw error
  }
}

export const getUsageHistory = async(account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/energy`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getContractHistory = async(account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/contract_histories`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getTPV = async(account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/tpvs`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getDropReasons = async(callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/drop_reasons`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const requestAccountDrop = async(account_id, payload, handleAlert, setConfirmationCode) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/drops`, payload)
    if(response.data.status === 'ERROR'){
      handleAlert(response.data.message, false)
    } else {
      setConfirmationCode(response.data.confirmation_code)
      handleAlert(`Success! Confirmation Code: ${response.data.confirmation_code}`, true)
    }
  } catch (error) {
    handleAlert(error)
    throw error
  }
}

export const getLinkedAccounts = async(accountId, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/linked_accounts`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting linked accounts: ${error}`, false)
    throw error
  }
}

export const updateContactName = async(accountId, params, userInfo) => {
  const payload = {
    membership_profile_info: {
      first_name: params.first_name,
      last_name: params.last_name,
      business_name: "",
      contact_first_name: params.first_name,
      contact_last_name: params.last_name
    },
    audit: {
      user_name: userInfo.email,
      source: 'CRM',
      date: DateTime.now().toISO()
    }
  }
  try {
    return await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/membership_profile/info`, payload)
  } catch (e) {
    throw e
  }
}

export const updateContactEmail = async(accountId, email, userInfo, handleAlert) => {
  const payload = {
    membership_profile_email: {
      email: email
    },
    audit: {
      user_name: userInfo.email,
      source: 'CRM',
      date: DateTime.now().toISO()
    }
  }
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/membership_profile/email`, payload)
    handleAlert('Email updated!', true)
  } catch (e) {
    handleAlert(`Email address was not updated ${e}`, false)
    throw e
  }
}

export const updatePrimaryPhone = async(accountId, params, userInfo, handleAlert) => {
  handleAlert('Updating primary phone...', true)
  const payload = {
    membership_profile_phone: {
      primary_phone: params.phone
    },
    audit: {
      user_name: userInfo.email,
      source: 'CRM',
      date: DateTime.now().toISO()
    },
    membership_profile_id: params.membership_profile_id,
    is_primary: true
  }
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/membership_profile/phone`, payload)
    handleAlert('Primary Phone updated', true)
  } catch (e) {
    handleAlert('There was an error updating the primary phone', false)
    throw e
  }
}

export const saveAccountAddress = async(accountId, address, addressType, userInfo, callback, handleAlert) => {
  const address_type = addressType === 'Mailing' ? 'billing' : 'service'
  const payload = {
    address: {
      street: address.street,
      unit: address.unit,
      city: address.city,
      state_code: address.state,
      zip: address.zip,
      vendor_code: 'INSPIRE'
    },
    audit: {
      user_name: userInfo.email,
      user_id: userInfo.user_id,
      source: 'CRM',
      date: DateTime.now().toISO()
    }
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/address/${address_type}`, payload)
    if (response.data.address_guid) {
      handleAlert('Success! Address Updated', true)
      callback(response.data.address_guid)
    } else {
      handleAlert(response.status, false)
    }
  } catch (error) {
    handleAlert(`Something went wrong saving address ${error}`, false)
    throw error
  }
}

export const getAccountTransactions = async(account_id, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/accounts/${account_id}/transactions`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting account transactions ${error}`, false)
    throw error
  }
}

export const getPossibleTransactions = async(account_id, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/accounts/${account_id}/possible_transactions`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting possible account transactions ${error}`, false)
    throw error
  }
}

export const addTransaction = async(account_id, transaction, handleAlert, resetTransactions) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_GARCON_API}/internal/account_transactions/${transaction.account_transaction_id}`, {account_id: account_id} )
    if (response.status === 200) {
      handleAlert('Transaction added to account!', true)
      setTimeout(() => {
        resetTransactions()
      }, 3000)
    }
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const checkForExistingAccount = async (rejectedAccount, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/account_exists/${rejectedAccount.account_number}/${rejectedAccount.market_code}`)
    return response.data
  } catch (error) {
    handleAlert(error, false)
    throw error
  }
}
