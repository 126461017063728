import { useState } from 'react'
import { Form, Modal, Button, Row, Col } from 'react-bootstrap'
import useToast from '../../../hooks/useToast'
import { findECLMatch } from '../../../apis/ecl'

const ECLModal = ({ show, close, reject, account, setFormInfo, formInfo }) => {
  const [eclData, setECLData] = useState({
    market_code: reject.market_code || '',
    last_name: reject.last_name || '',
    service_address_line_1: account.service_address_line_1 || '',
    service_city: account.service_city || '',
    service_state: account.service_state || '',
    service_zip_code: account.service_zip_code || '',
  })
  const [eclMatchText, setECLMatchText] = useState('')
  const [eclResults, setECLResults] = useState([])
  const { handleAlert } = useToast()

  const updateECLData = (e) => {
    setECLData({...eclData, [e.target.name]: e.target.value})
  }

  const cancelECLLookup = () => {
    close()
  }

  const lookUpECLMatch = (e) => {
    e.preventDefault()
    return findECLMatch(eclData, handleAlert)
    .then((response) => {
      if (response?.length > 0) {
        setECLResults(response)
        setECLMatchText(`${response.length} matches found in ECL`)
      } else {
        setECLMatchText('No matches found in ECL')
      }
    })
  }

  const selectECLAccountNumber = (e) => {
    const index = e.target.id
    setFormInfo({...formInfo, account_number: eclResults[index].account_number})
    close()
  }

  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>ECL Account Number Lookup</Modal.Header>
      <Modal.Body data-testid="ecl-modal">
        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
              className="mb-3"
              required
              type="text"
              name="last_name"
              size="md"
              value={eclData.last_name}
              onChange={(e)=> updateECLData(e)}
            />
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            className="mb-3"
            required
            type="text"
            name="service_address_line_1"
            size="md"
            value={eclData.service_address_line_1}
            onChange={(e)=> updateECLData(e)}
          />
          <Form.Label>Zip Code</Form.Label>
          <Form.Control
            className="mb-3"
            required
            type="text"
            name="service_zip_code"
            size="md"
            value={eclData.service_zip_code}
            onChange={(e)=> updateECLData(e)}
          />
          <Button variant="success" type="submit" onClick={(e) => lookUpECLMatch(e)}>
            Submit
          </Button>
          <Button  className="ms-2" variant="danger" onClick={(e) => cancelECLLookup(e)}>
            Cancel
          </Button>
          <div className="mt-2">
             <b>{eclMatchText}</b>
          </div>
          {eclResults?.length > 0 && eclResults.map((result, idx) => (
            <Row key={idx} className="mt-3">
              <Col md={8}>
                <b>{result.customer_name_1}</b><br/>
                <small>
                {result.postal_service_address_1} {result.postal_service_address_2}<br/>
                {result.postal_service_city} {result.postal_service_state} {result.postal_service_zip}<br/>
                {result.account_number}
                </small>
              </Col>
              <Col md={4}>
                <Button id={idx} onClick={(e) => selectECLAccountNumber(e)}>
                  Select Account Number
                </Button>
              </Col>
            </Row>
          ))}
        </Form.Group>
      </Modal.Body>
    </Modal>
  )
}

export default ECLModal;
