import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const OrderSuccess = ({ order, prospectGuid, shopperFullName }) => {
  const navigate = useNavigate()

  return (
    <>
      <div>
        <h1 className="d-inline">Congratulations!</h1>
        {order &&
          <>
            <p>I'd like to provide you with your order confirmation number, which is <strong>{order.confirmation_code}</strong></p>
            <p>[MR./MRS.] {order.last_name}, thank you so much for choosing to become an Inspire member! Shortly, you’ll receive a welcome package from us.
              If you have questions about your membership in the future, please reach out to our friendly Member Support Team at 1 (866) 403-2620 Monday through Friday 9am-6pm Eastern Standard Time.
              Thank you so much for taking the time to speak with me today and I hope you have a great rest of your day!</p>
          </>
        }
      </div>
      <div className="d-flex align-items-center">
        <Button
          variant="primary"
          className="mt-3 btn-lg"
          onClick={() => navigate(`/prospect/${prospectGuid}`)}
        >
          Back to {shopperFullName}
        </Button>
      </div>
    </ >
  )
}

export default OrderSuccess
