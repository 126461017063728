import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"

const containerStyle = {
  width: '100%',
  height: '300px'
}

const MapMarker = ({ lat, long }) => {
  const position = {
    lat: lat,
    lng: long
  }

  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GMAPS}>
        <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={10} style={{ overflow: 'visible' }}>
          <Marker position={position} />
        </GoogleMap>
      </LoadScript>
    </>
  )
}

export default MapMarker
