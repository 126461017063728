import React from "react"
import { Button, Modal } from "react-bootstrap"
import ContactPreferences from "./ContactPreferences"
import ProspectPreferences from "../prospect/ProspectPreferences"

const ContactPrefsModal = ({ member, account, prospect, handleClose, show}) => {

  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton />
          <Modal.Body>
            {member ?
            <ContactPreferences member={member} memberAccount={account}/>
            :
            <ProspectPreferences prospect={prospect}/>
            }
          </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )

}

export default ContactPrefsModal
