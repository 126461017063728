import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import Loader from '../Loader'

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0()
  let location = useLocation()

  useEffect(() => {
    if (!user && !isLoading) {
      loginWithRedirect()
    }
  }, [user, isLoading, loginWithRedirect])

  if (isAuthenticated && !isLoading) {
    // for some reason Auth0's isAuthenticated function is true
    // even when a user's token has expired
    // this conditional double checks that they still have an unexpired token
    let token = localStorage.getItem('idToken')
    if (!token){
      // store pathname to use for redirect upon re-login
      const path = location.pathname
      localStorage.setItem('location', path)
      loginWithRedirect()
    }
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return <Loader message={''}/>
  }
}

export default AuthGuard;
