import React, {useState, useEffect} from 'react'
import { Modal, Badge, Button, Form } from 'react-bootstrap'
import ReactTableBasic from '../../ReactTableBasic'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
import useUser from '../../../hooks/useUser'
import useToast from '../../../hooks/useToast'
import { rewardModalTableColumns } from './helpers/rewardModalTableColumns'
import { resendReward, redeemReward } from '../../../apis/rewards'
import ReferAFriendModal from './ReferAFriendModal'
import { isEmpty } from 'lodash'

const RewardModal = ({ account, reward, showModal, handleClose }) => {
  const { userInfo } = useUser()
  const { handleAlert } = useToast()
  const [rewardStatus, setRewardStatus] = useState(reward.reward_staus)
  const [resendOrRedeem, setResendOrRedeem] = useState('')
  const [rewardFulfillmentChannel, setRewardFulfillmentChannel] = useState(reward.fulfillment_channel)

  useEffect(() => {
    if (reward.can_resend) {
      setResendOrRedeem('RESEND')
    }
    if (reward.can_redeem) {
      setResendOrRedeem('REDEEM')
    }
  }, [reward])

  const resendOrRedeemPastTense = () => {
    return resendOrRedeem === 'RESEND' ? 'resent' : 'redeem'
  }

  const updateFulfillmentChannel = (e) => {
    setRewardFulfillmentChannel(e.target.value)
  }

  const resendOrRedeemReward = (e) => {
    const payload = {
      reward_guid: reward.reward_guid,
      service_zip_code: account.service_zip_code,
      fulfillment_channel: rewardFulfillmentChannel,
      audit: {
        user_name: userInfo.email,
        source: 'CRM',
        date: DateTime.now().toISO()
      }
    }
    resendOrRedeem === 'RESEND'
    ? resendReward(payload, setRewardStatus, handleAlert)
    : redeemReward(payload, setRewardStatus, handleAlert)
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {reward.title}  - {reward.reward_status_code} <br />
            {reward.description} <br />
            <Badge bg="lightElectric" text="dark">{reward.status_summary}</Badge>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {reward.template !== 'refer_a_friend' &&
          <>
            {reward.fulfillment_batch_id &&
              <p>Fulfillment Batch ID: {reward.fulfillment_batch_id}</p>
            }
            {(resendOrRedeem !== '' && rewardStatus !== 'RESENT' && rewardStatus !== 'REDEEMED') &&
              <>
                <p>This reward is eligible to be {resendOrRedeemPastTense()}. Click the button below to {resendOrRedeem.toLowerCase()} the reward.</p>
                <Button className="btn btn-primary mb-2" onClick={() => setRewardStatus(`${resendOrRedeem}ING`)}>
                  {resendOrRedeem} {reward.reward_amount}
                </Button>
                {(rewardStatus === 'REDEEMING' || rewardStatus === 'RESENDING') &&
                <>
                <p>Select how the customer prefers to receive the reward, then click 'Submit' to {resendOrRedeem.toLowerCase()}.</p>
                <Form.Select
                  aria-label="fulfillment channel"
                  data-testid="fulfillment dropdown"
                  value={rewardFulfillmentChannel}
                  onChange={(e) => {updateFulfillmentChannel(e)}}>
                  <option>Choose an option from the dropdown</option>
                  {reward.fulfillment_channels.map(fulfillmentChannel =>
                    <option key={uuidv4()} value={fulfillmentChannel.code}>{fulfillmentChannel.name}</option>
                  )}
                </Form.Select>
                <Button className="my-2" onClick={(e) => resendOrRedeemReward(e)}>Submit</Button>
                <Button className="my-2" onClick={() => setRewardStatus(reward.reward_status_code)}>Cancel</Button>
                </>
                }
              </>
            }
            <h4>History</h4>
            {!isEmpty(reward.accruals) ?
              (
                <ReactTableBasic
                  title="Accruals Table"
                  columns={rewardModalTableColumns(reward)}
                  data={reward.accruals}
                  canHover={false}
                />
              )
            :
              (<p>No accruals exist for this reward</p>)
            }
          </>
        }
        {reward.template === 'refer_a_friend' &&
          <ReferAFriendModal
          account={account}
          reward={reward}
          />
        }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RewardModal
