/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, InputGroup, Row, Form, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { TOS } from '../../utils/text'
import { validateField } from '../../utils/formValidation'
import { savePartial } from '../../apis/shoppers'
import useToast from '../../hooks/useToast'
import { getMarketInfo } from '../../apis/offers'

const OrderVerificationForm = ({ shopper }) => {
  const [formInfo, setFormInfo] = useState({
    shopper_guid: '',
    account_number: '',
    email: '',
    first_name: '',
    last_name: '',
    offer_code: '',
    billing_address_line_1: '',
    billing_address_line_2: '',
    billing_city: '',
    billing_state: '',
    billing_zip_code: '',
    primary_phone: '',
    revenue_class_code: '',
    service_address_line_1: '',
    service_address_line_2: '',
    service_city: '',
    service_state: '',
    service_zip_code: '',
    tpv_type: '',
    ack_can_text: false,
    enrollment_type: null
  })
  const [formErrors, setFormErrors] = useState({})
  const [submitClicked, setSubmitCicked] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [showMailing, setShowMailing] = useState(false)
  const [marketInfo, setMarketInfo] = useState({})
  const { handleAlert } = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (shopper) {
      setFormInfo({
        shopper_guid: shopper.shopper_guid || '',
        account_number: shopper.account_number || '',
        email: shopper.email || '',
        first_name: shopper.first_name || '',
        last_name: shopper.last_name || '',
        offer_code: shopper.offer_code || '',
        billing_address_line_1: shopper.billing_address_line_1 || '',
        billing_address_line_2: shopper.billing_address_line_2 || '',
        billing_city: shopper.billing_city || '',
        billing_state: shopper.billing_state || '',
        billing_zip_code: shopper.billing_zip_code || '',
        primary_phone: shopper.primary_phone || '',
        revenue_class_code: shopper.revenue_class_code || '',
        service_address_line_1: shopper.service_address_line_1 || shopper.service_address.street || '',
        service_address_line_2: shopper.service_address_line_2 || shopper.service_address.unit || '',
        service_city: shopper.service_city || shopper.service_address.city || '',
        service_state: shopper.service_state || shopper.service_address.state_code || '',
        service_zip_code: shopper.service_zip_code || shopper.service_address.zip || '',
        verification_method: '',
        ack_can_text: shopper.ack_can_text || false,
        short_key: shopper.short_key,
        tpv_type: shopper.tpv_type || '',
        enrollment_type: shopper.enrollment_type || null
      })
      if (formInfo.mailing_address) {
        setShowMailing(true)
      }
    }
  }, [])

  useEffect(() => {
    if (shopper?.market_code) getMarketInfo(shopper, setMarketInfo)
  }, [shopper])

  const handleFormInfo = (e) => {
    if (e.target.name === 'ack_can_text') {
      setFormInfo({ ...formInfo, [e.target.name]: e.target.checked })
    } else {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value })
    }
  }

  const validateForm = useCallback(() => {
    const checkFields = () => {
      const requiredFields = ['first_name', 'last_name', 'revenue_class_code']
      const fields = Object.keys(formInfo)
      return fields.reduce((errors, field) => {
        const required = requiredFields.find(fieldName => fieldName === String(field))
        const error = validateField(marketInfo, field, formInfo[field], required)
        if (error) errors[field] = error
        return errors
      }, {})
    }

    setFormErrors({})
    const errors = checkFields()
    setFormErrors(errors)
    return errors
  }, [formInfo, marketInfo])

  useEffect(() => {
    if (submitClicked) validateForm()
  }, [formInfo, submitClicked, validateForm])

  const cancelOrder = () => {
    navigate(`/prospect/${shopper.prospect_guid}`)
  }

  const saveOrder = async() => {
    const response = await savePartial(formInfo, handleAlert)
    if (response.status === 200) {
      handleAlert(`Partial saved with short key: ${response.data.short_key}`, true)
      setTimeout(() => {
        navigate(`/prospect/${shopper.prospect_guid}`)
      }, 3000)
    }
  }

  const goBack = () => {
    navigate(`/order/${shopper.short_key}/offers`)
  }

  const confirmOrder = () => {
    setSubmitting(true)
    setSubmitCicked(true)
    const errors = validateForm()

    if (Object.keys(errors).length === 0) {
      savePartial(formInfo, handleAlert)
      if (formInfo.tpv_type === 'Internal') {
        navigate(`/order/${shopper.short_key}/verify`)
      } else {
        navigate(`/order/${shopper.short_key}/final`)
      }
    }
    setSubmitting(false)
  }

  const confirmOrderButtonDisabled = !!Object.keys(formErrors).length
  const inputClassName = (inputName) => inputName ? '' : 'mb-3'
  const errorText = (inputName) => inputName ? <Form.Text style={{ color: '#E86371' }}>{inputName}</Form.Text> : ''

  return (
    <Container fluid className="p-0">
      <Form>
        <Row>
          <Col md="6">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              size="md"
              data-testid="email-input"
              className={inputClassName(formErrors.email)}
              onChange={handleFormInfo}
              value={formInfo.email}
              isInvalid={formErrors.email}
            />
            {errorText(formErrors.email)}
          </Col>
          <Col md="6">
            <Form.Label>Primary Phone</Form.Label>
            <Form.Control
              required
              type="text"
              name="primary_phone"
              size="md"
              data-testid="phone-input"
              className={inputClassName(formErrors.primary_phone)}
              value={formInfo.primary_phone}
              onChange={handleFormInfo}
              isInvalid={formErrors.primary_phone}
            />
            {errorText(formErrors.primary_phone)}
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Label>Account First Name</Form.Label>
            <Form.Control
              required
              type="text"
              name="first_name"
              size="md"
              data-testid="first_name"
              className={inputClassName(formErrors.first_name)}
              onChange={handleFormInfo}
              value={formInfo.first_name}
              isInvalid={formErrors.first_name}
            />
            {errorText(formErrors.first_name)}
          </Col>
          <Col md="6">
            <Form.Label>Account Last Name</Form.Label>
            <Form.Control
              required
              type="text"
              name="last_name"
              size="md"
              data-testid="last_name"
              className={inputClassName(formErrors.last_name)}
              value={formInfo.last_name}
              onChange={handleFormInfo}
              isInvalid={formErrors.last_name}
            />
            {errorText(formErrors.last_name)}
          </Col>
        </Row>
        <hr className="my-3" />
        <Row>
          <Col md="6">
            <Form.Label>Service Address</Form.Label>
            <Form.Control
              disabled
              className="mb-3"
              type="text"
              name="service_address_line_1"
              size="md"
              data-testid="service_address"
              value={formInfo.service_address_line_1}
            />
          </Col>
          <Col md="4">
            <Form.Label>Service Address 2</Form.Label>
            <Form.Control
              disabled
              className="mb-3"
              type="text"
              name="service_address_line_2"
              size="md"
              value={formInfo.service_address_line_2}
            />
            </Col>
        </Row>
        <Row>
        <Col md="3">
          <Form.Label>Service City</Form.Label>
          <Form.Control
            disabled
            className="mb-3"
            type="text"
            name="service_city"
            size="md"
            value={formInfo.service_city}
          />
        </Col>
        <Col md="3">
          <Form.Label> Service State</Form.Label>
          <Form.Control
            disabled
            className="mb-3"
            type="text"
            name="service_state"
            size="md"
            value={formInfo.service_state}
          />
        </Col>
        <Col md="3">
          <Form.Label>Service Zip Code</Form.Label>
          <Form.Control
            disabled
            className="mb-3"
            type="text"
            name="service_zip_code"
            size="md"
            value={formInfo.service_zip_code}
          />
        </Col>
        </Row>
        <br />
        <Row>
          <Col md="12">
            <Form.Label>Revenue Class</Form.Label>
            <Form.Control
              required
              type="text"
              name="revenue_class_code"
              size="md"
              data-testid="revenue_class"
              className={inputClassName(formErrors.revenue_class_code)}
              value={formInfo.revenue_class_code}
              onChange={handleFormInfo}
              isInvalid={formErrors.revenue_class_code}
            />
            {errorText(formErrors.revenue_class_code)}
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="6">
          <Form.Check
            label="Mailing address differs from Service Address?"
            name="showMailing"
            defaultChecked={showMailing}
            onClick={e => setShowMailing(e.target.checked)}
            className="mb-2"
          />
        </Col>
        </Row>
        <br />
        {showMailing &&
          <span>
              <Row>
                <Col md="6">
                  <Form.Label>Mailing Address 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_address_line_1"
                    size="md"
                    className="mb-3"
                    value={formInfo.billing_address_line_1}
                    onChange={handleFormInfo}
                  />
                </Col>
                <Col md="6">
                  <Form.Label>Mailing Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_address_line_2"
                    size="md"
                    value={formInfo.billing_address_line_2}
                    onChange={handleFormInfo}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Label htmlFor="city">Mailing City</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_city"
                    size="md"
                    className="mb-3"
                    value={formInfo.billing_city}
                    onChange={handleFormInfo}
                  />
                </Col>
                <Col md="4">
                  <Form.Label htmlFor="state">Mailing State</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_state_code"
                    size="md"
                    className="mb-3"
                    value={formInfo.billing_state}
                    onChange={handleFormInfo}
                  />
                </Col>
                <Col md="4">
                  <Form.Label htmlFor="zip_code">Mailing Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_zip_code"
                    size="md"
                    className="mb-3"
                    value={formInfo.billing_zip_code}
                    onChange={handleFormInfo}
                  />
                </Col>
              </Row>
            </span>
        }
        <hr className="my-3" />
        <Row>
          <Col md="12">
            <Form.Label>Account Identifier</Form.Label>
            <InputGroup className={inputClassName(formErrors.account_number)}>
              <Form.Control
                type="text"
                name="account_number"
                size="md"
                data-testid="account_number"
                value={formInfo.account_number}
                onChange={handleFormInfo}
                isInvalid={formErrors.account_number}
              />
            </InputGroup>
            {errorText(formErrors.account_number)}
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Form.Label>Offer Code</Form.Label>
            <Form.Control
              disabled
              type="text"
              name="offer_code"
              size="md"
              data-testid="offer_code"
              className="mb-3"
              value={shopper.offer_code}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Label htmlFor="tpv_type">Choose Verification Method</Form.Label>
            <Form.Select className="mb-3" name="tpv_type" onChange={handleFormInfo}>
              <option>Choose...</option>
              <option value="Web">Web</option>
              <option value="Phone">Phone</option>
              <option value="Live">Live</option>
              <option value="Internal">Internal</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-3">
          <Form.Check
            label={TOS}
            type="checkbox"
            id="TOS"
            name="ack_can_text"
            style={{ marginRight: '5px' }}
            onChange={(e) => handleFormInfo(e)}
          />
        </Row>
        <Row className='no-gutters'>
          <Col md="5">
            <Button variant="secondary" className="mr-3" type="submit" onClick={cancelOrder}>Cancel Order</Button>
            <Button variant="info" className="m-3" type="submit" onClick={goBack}>Back to Offers</Button>
          </Col>
          <Col></Col>
          <Col md="5">
            <Button variant="warning" className="mr-3"  onClick={saveOrder}>Save as Partial</Button>
            {submitting && !confirmOrderButtonDisabled ? <>
              <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2 ml-4"
                />
                <span>Confirming order...</span></div>
            </> : <Button variant="primary" className="m-3" disabled={confirmOrderButtonDisabled} onClick={confirmOrder}>Confirm Order</Button>}
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default OrderVerificationForm
