import React, {useState} from 'react'
import { Button, Col, Row} from 'react-bootstrap'
import { DateTime, Interval } from 'luxon'
import * as DOMPurify from 'dompurify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationIcons from './NotificationIcons'
import PendingCancellationModal from './PendingCancellationModal'
import { useNavigate } from 'react-router-dom'

const MemberNotification = ({notification, isCase, member}) => {
  const title = isCase ? notification.to_case_status_code : notification.title
  const createdDate = isCase ? notification.case_created_dt : notification.date
  const description = isCase ? notification.case_description : notification.description
  const isViewableCase = isCase || notification.template === 'Case'
  const isPendingCancellation = notification.template === 'PendingCancellation'
  const [showPendingCancellationModal, setShowPendingCancellationModal] = useState(false)
  const navigate = useNavigate()

  const iconColor = () => {
    switch(notification.level){
      case 'info':
        return '#0E7C9E'
      case 'danger':
        return '#E86371'
      default:
        return '#0E7C9E'
    }
  }

  const parseCaseDate = () => {
    const duration = Interval.fromDateTimes(DateTime.fromISO(createdDate), DateTime.local()).toDuration('days').toObject()
    return Math.round(duration.days)
  }

  const handleViewCase = () => {
    isCase ? navigate(`/cases/${notification.case_guid}`) : navigate(`/cases/${notification.params.case_guid}`)
  }

  const handleClose = () => {
    setShowPendingCancellationModal(false)
  }

  return (
    <>
      <div className="d-flex">
        <Col md={1}>
          <Row className="me-5">
            <FontAwesomeIcon
              className="me-3"
              size="2x"
              color={iconColor()}
              icon={NotificationIcons(notification.icon)}>
            </FontAwesomeIcon>
          </Row>
          <Row className="align-start">
            <small className="text-muted">{parseCaseDate()} days ago </small>
          </Row>
        </Col>
        <Col md="10">
          <small className="float-end text-navy">{createdDate ? DateTime.fromISO(createdDate).toLocaleString(DateTime.DATETIME_FULL) : 'n/a'}</small>
          <h5 className='mb-0'>{title}
            {isViewableCase &&
              <Button aria-label="View Case" className="square bg-primary rounded-pill ms-2" size="sm" onClick={()=> handleViewCase()}>View</Button>
            }
            {isPendingCancellation &&
              <Button aria-label="View Case" className="square bg-primary rounded-pill ms-2" size="sm" onClick={()=> setShowPendingCancellationModal(true)}>View</Button>
            }
          </h5>
          <p className='mb-0' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}}></p>
        </Col>
      </div>
      {showPendingCancellationModal &&
        <>
        <PendingCancellationModal
        notification={notification}
        account={member}
        show={showPendingCancellationModal}
        handleClose={handleClose}
        />
        </>
      }
      <hr />
    </>
  )
}

export default MemberNotification
