import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Card, Col, Container, Row, Tabs, Tab } from "react-bootstrap"
import ServiceSummary from "../../components/account/serviceSummary/ServiceSummary"
import MemberProfile from "../../components/member/MemberProfile"
import PlanContainer from "../../components/account/plan/PlanContainer"
import EnergyImpact from "../../components/dashboard/EnergyImpact"
import InvoiceHistory from "../../components/account/InvoiceHistory/index"
import ChecksContainer from "../../components/account/checks/ChecksContainer"
import RewardsContainer from "../../components/account/rewards/RewardsContainer"
import AccountCasesContainer from "../../components/account/accountCases/AccountCasesContainer"
import MemberNotificationsContainer from "../../components/member/memberNotifications/MemberNotificationsContainer"
import { getAccount } from "../../apis/accounts"
import { useParams } from "react-router-dom"
import { getAccountEnergyImpact } from "../../apis/accounts"
import LinkedAccounts from "../../components/member/LinkedAccounts"
import { getAccountPayableBills } from '../../apis/rewards'
import { isEmpty } from "lodash"
import { getProspect } from "../../apis/prospects"

const Account = () => {
  const [account, setAccount] = useState({})
  const [impact, setImpact] = useState([])
  const { account_id } = useParams()
  const [key, setKey] = useState('invoice history')
  const [accountPayableBills, setAccountPayableBills] = useState([])
  const [reloadMember, setReloadMember] = useState(false)
  const [prospect, setProspect] = useState({})


  useEffect(() => {
    getAccount(account_id, setAccount)
    getAccountEnergyImpact(account_id, setImpact)
    getAccountPayableBills(account_id, setAccountPayableBills)
  }, [reloadMember, account_id])

  useEffect(() => {
    if (!isEmpty(account)) {
      getProspect(account.prospect_guid, setProspect)
    }
  }, [account])

  return (
    <React.Fragment>
      <Helmet title="Account" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Account</h1>
        {account.account_id &&
          <Row>
            <Col md="4" xl="3">
              <MemberProfile account={account} prospect={prospect} setReloadMember={setReloadMember} reloadMember={reloadMember}/>
            </Col>
            <Col md="8" xl="9">
              <EnergyImpact impact={impact} />
                <Card>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    variant="tabs"
                    bg="info"
                  >
                    <Tab eventKey="invoice history" title="Invoice History">
                      <InvoiceHistory account={account} setReloadMember={setReloadMember} />
                    </Tab>
                    <Tab eventKey="plan details" title="Plan Details">
                      <PlanContainer account={account}/>
                    </Tab>
                    <Tab eventKey="service summary" title="Service Summary" data-testid="serviceSummary">
                      <ServiceSummary account={account} />
                    </Tab>
                    <Tab eventKey="account events" title="Account Events">
                      <MemberNotificationsContainer member={account} />
                    </Tab>
                    {!isEmpty(accountPayableBills) &&
                      <Tab eventKey="checks" title="Checks">
                        <ChecksContainer account={account} accountPayableBills={accountPayableBills} />
                      </Tab>
                    }
                    <Tab eventKey="rewards" title="Rewards">
                      <RewardsContainer account={account}  />
                    </Tab>
                    <Tab eventKey="accountCases" title="Cases">
                      <AccountCasesContainer account={account} setReloadMember={setReloadMember}  />
                    </Tab>
                    <Tab eventKey="linked accounts" title="Linked Accounts">
                      <LinkedAccounts accountId={account?.account_id} />
                    </Tab>
                  </Tabs>
                </Card>
            </Col>
          </Row>
        }
      </Container>
    </React.Fragment>
  )
}

export default Account
