/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Card, Spinner, Button } from 'react-bootstrap'
import Select from 'react-select'
import { DateTime } from 'luxon'
import { getCases } from '../../apis/cases'
import { caseFilterOptions } from '../../components/cases/helpers/caseFilterDropDownOptions'
import { caseStatuses } from '../../utils/caseStatuses'
import isEmpty from 'lodash/isEmpty'
import CasesTable from '../../components/cases/CasesTable'
import useUser from '../../hooks/useUser'
import useLocalStorage from '../../hooks/useLocalStorage'

const Cases = () => {
  const { userInfo } = useUser()
  const thirtyDaysAgo = DateTime.fromISO(DateTime.now().minus({ days: 30 })).toFormat('yyyy-MM-dd')
  const [cases, setCases] = useState([])
  const [caseTypeFilter, setCaseTypeFilter] = useLocalStorage('case_type', { value: 'roles', label: "My Team's Cases" })
  const [caseStatus, setCaseStatus] = useLocalStorage('case_status', caseStatuses.filter(status => status.label !== 'CANCELLED' && status.label !== 'CLOSED'))
  const [fromDate, setFromDate] = useLocalStorage('from_date', thirtyDaysAgo)
  const [customDateCheck, setCustomDateCheck] = useState(false)
  const [customStartDate, setCustomStartDate] = useLocalStorage('custom_start_date', '')
  const [customEndDate, setCustomEndDate] = useLocalStorage('custom_end_date', '')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
      loadCases(false)
  }, [caseStatus, caseTypeFilter, customEndDate, customStartDate, fromDate])

  useEffect(() => {
    const interval = setInterval(() => {
      loadCases(true)
    }, 5 * 60000)

    return () => clearInterval(interval)
  })

  const loadCases = (casesReload) => {
    const params = {
      caseStatus: caseStatus,
      caseType: caseTypeFilter,
      userId: userInfo.user_id,
      fromDate: fromDate,
      customStartDate: customStartDate,
      customEndDate: customEndDate,
    }
    if ((customDateCheck && !customStartDate) || (customDateCheck && !customEndDate)) {
      return
    } else {
      getCases(params, setCases, setLoading, casesReload)
    }
  }

  const resetCaseQueueFilters = () => {
    setCaseTypeFilter({ value: 'roles', label: "My Team's Cases" })
    setCaseStatus(caseStatuses.filter(status => status.label !== 'CANCELLED' && status.label !== 'CLOSED'))
    setFromDate(thirtyDaysAgo)
    setCustomDateCheck(false)
    setCustomStartDate('')
    setCustomEndDate('')
  }

  return (
    <>
      <Card>
        <Row className="p-4">
          <Col md={2}>
            <Form.Label>Show Cases:</Form.Label>
            <Select
              aria-label="Filter Cases By"
              data-testid="team-filter"
              size="sm"
              options={caseFilterOptions}
              onChange={(e) => e ? setCaseTypeFilter(e) : setCaseTypeFilter({ value: "roles", label: "My Team's Cases" })}
              value={caseTypeFilter}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#B0DBE9',
                  primary: '#0E7C9E',
                },
              })}
            />
          </Col>
          <Col md={3}>
          <Form.Label>From Date:</Form.Label>
          <Form.Control
            name="from_dt"
            placeholder="Start Date"
            type="date"
            value={fromDate}
            onChange={e => setFromDate(DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd'))}
          />
          </Col>
          <Col md={1}>
          <Form.Label>Set Custom Date?</Form.Label>
          <Form.Check
            name="custom date check"
            data-testid="custom-checkbox"
            type="checkbox"
            value={customDateCheck}
            onChange={() => setCustomDateCheck(!customDateCheck)} 
          />
          </Col>
          {customDateCheck &&
            <>
              <Col md={3}>
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  name="custom start date"
                  data-testid="custom-start-date"
                  type="date"
                  value={customStartDate}
                  onChange={(e) => setCustomStartDate(DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd'))}
                />
              </Col>
              <Col md={3}>
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  name="custom end date"
                  type="date"
                  value={customEndDate}
                  onChange={(e) => setCustomEndDate(DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd'))}
                />
              </Col>
            </>
          }
          <Col md={5}>
          <Form.Label>Status:</Form.Label>
          <Select
              aria-label="Case Status"
              data-testid="status-filter"
              size="sm"
              isMulti
              options={caseStatuses}
              onChange={(e) => setCaseStatus(e)}
              value={caseStatus}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#B0DBE9',
                  primary: '#0E7C9E',
                },
              })}
            />
          </Col>
          <Col md={1}>
            <Button className="btn btn-primariy ms-1 mt-4" onClick={resetCaseQueueFilters}>Reset Filters</Button>
          </Col>
        </Row>
        { loading &&
          <Row className="p-4">
            <>
              <span className="h2">Loading Cases </span>
              <Spinner
                data-testid="spinner-loading"
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                variant="primary"
              >
              <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          </Row>
        }
        { isEmpty(cases) && !loading &&
          <Row className="p-4">
            <h2>No cases found for the specified filters</h2>
          </Row>
        }
        { !isEmpty(cases) && !loading &&
          <CasesTable
            cases={cases}
            loadCases={loadCases}
          />
        }
      </Card>
    </>
  )
}

export default Cases
