import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Container } from 'react-bootstrap'
import { getMemberProspects } from '../../apis/member'
import { DateTime } from 'luxon'
import ReactTableBasic from '../ReactTableBasic'

const LinkedProspects = () => {
  const [memberProspects, setMemberProspects] = useState({})
  const { membership_profile_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (membership_profile_id) {
      getMemberProspects(membership_profile_id, setMemberProspects)
    }
  }, [membership_profile_id])

  const rowOnClick = (e) => {
    navigate(`/prospect/${e.prospect_guid}`)
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: memberProspects => `${memberProspects.first_name} ${memberProspects.last_name}`,
    },
    {
      Header: 'Date Created',
      accessor: 'created_at',
      Cell: (e) => (
        <div>
        {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
        </div>
      )
    },
    {
      Header: 'Channel',
      accessor: 'channel_code',
    },
    {
      Header: 'Source Code',
      accessor: 'source_code',
    },
    {
      Header: 'Utility',
      accessor: 'market_code',
    },
    {
      Header: 'Prospect GUID',
      accessor: 'prospect_guid'
    }

  ]

  return (
    <React.Fragment>
      <Container>
        { !isEmpty(memberProspects) ?
          <div>
          <h4>Other Prospects for Member</h4>
            <div className="mt-3 mb-5">
              <ReactTableBasic
                columns={tableColumns}
                data={memberProspects}
                handleClick={rowOnClick}
                canHover={true}
              />
            </div>
          </div>
        :
          <h4>No Linked Prospects for Member</h4>
        }
      </Container>
    </React.Fragment>
  )
}

export default LinkedProspects
