import { DateTime } from 'luxon'
import { Badge } from 'react-bootstrap'
import { SelectColumnFilter } from '../../../utils/filters'

const parseDate = (data) => {
  return data?.value ? DateTime.fromISO(data.value).toLocaleString(DateTime.DATE_MED) : ''
}

const parseTimeDifference = (data) => {
  if (!data.value) {
    return 'Cannot detect time updated'
  }
  const diffFromNowObj = DateTime.fromISO(data.value).diffNow(['years', 'months', 'days', 'hours', 'minutes', 'seconds']).values
  let timeDifferenceString = ''
  // create an array of arrays where the elements within the nested arrays are the time units and time values
  // every() cycles through the array of arrays and breaks out of the loop when it returns false
  // it finds the first value that is not zero and adds it to the timeDifferenceString
  Object.entries(diffFromNowObj).every(([unit, value]) => {
    let timeDifference = Math.round(Math.abs(value))
    if (timeDifference > 0) {
      const pluralOrSingleUnit = timeDifference > 1 ? unit : unit.slice(0, -1)
      timeDifferenceString = `${timeDifference} ${pluralOrSingleUnit}`
      return false
    } else {
      return true
    }
  }, {})
  timeDifferenceString +=  ' ago'
  return timeDifferenceString
}

const pastDue = (data) => {
  return data < DateTime.now().toISO() ? true : false
}

export const allTableColumns = [
  {
    Header: 'Created',
    accessor: 'case_created_dt',
    width: 125,
    Cell: (e) => (
      <>
      {parseDate(e)}
      </>
    )
  }, {
    Header: 'Tier',
    accessor: 'case_type_description',
    width: 125,
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Reason',
    accessor: 'category_name',
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Channel',
    accessor: 'channel_name',
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Status',
    accessor: 'case_status_name',
    width: 100,
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Disposition',
    accessor: 'case_status_change_reason_desc',
    Filter: SelectColumnFilter,
    filter: "multiple"
  },{
    Header: 'Last Changed',
    accessor: 'updated_at',
    width: 125,
    Cell: (e) => (
      <>
      {parseTimeDifference(e)}
      </>
    ),
  }, {
    Header: 'Created By',
    accessor: 'email',
  }, {
    Header: 'Assigned To',
    accessor: 'assigned_to_user_name',
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Sales Agent Name',
    accessor: 'agent_name',
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Office',
    accessor: 'office_code',
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Description',
    accessor: 'case_description',
    width: 350,
    Cell: (e) => (
      <>
      {e.value.substring(0, 100)}
      </>
    ),
  }, {
    Header: 'Due',
    accessor: 'requested_close_dt',
    width: 125,
    Cell: (props) => (
      <Badge bg={pastDue(props.value) ? "danger" : "dark"} text="white" className="fs-md p-2">
        {parseDate(props)}
      </Badge>
    ),
  }, {
    Header: 'Market Code',
    accessor: 'market_code',
    Filter: SelectColumnFilter,
    filter: "multiple"
  }, {
    Header: 'Last Note',
    accessor: 'last_note',
  }, {
    Header: 'Work Began',
    accessor: 'work_begins_dt',
    width: 125,
    Cell: (e) => (
      <>
      {parseDate(e)}
      </>
    ),
  }, {
    Header: 'Closed',
    accessor: 'closed_dt',
    width: 125,
    Cell: (e) => (
      <>
      {parseDate(e)}
      </>
    ),
  }, {
    Header: 'Reported By',
    accessor: 'reported_name',
    Filter: SelectColumnFilter,
    filter: "multiple"
  },
]
