import { useNavigate } from 'react-router-dom'
import { Button, Spinner } from 'react-bootstrap'

const OrderPending = ({ placeOrder, shopperFullName, shopperShortKey, submittingOrder }) => {
  const navigate = useNavigate()

  return (
    <>
      <h1>{shopperFullName}</h1>
      <div className='list-group list-group-flush'>
        <div className='list-group-item'><h3 className="d-inline">Order Created:</h3> Pending</div>
      </div>
      <div className="d-flex align-items-center">
        {submittingOrder ? <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-4"
          />
          <span>Submitting order...</span>
        </>
          : <>
            <Button
              variant="primary"
              className="mt-3 btn-lg"
              onClick={() => placeOrder()}
            >
              Place order
            </Button>
            <Button className="mt-3" variant="link" onClick={() => navigate(`/order/${shopperShortKey}/join`)}>
              Back
            </Button>
          </>
        }
      </div>
    </>
  )
}

export default OrderPending
