import axios from 'axios'
import { DateTime } from 'luxon'

export const searchProspects = async (query, callback) => {
  try {
    const params = {
      query: query,
      page: 1,
      per_page: 100,
    }
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/search`, {params})
    if(response.data){
      callback(response.data.prospects)
      return response.data
    }
  } catch (e) {
    throw e
  }
}

export const sendProspectEvent = async (correspondenceType, user, account, handleAlert) => {
  try {
    let payload = { order_id: account.order_id }
    let eventName
        switch (correspondenceType) {
        case 'WELCOME_LETTER':
          eventName = 'email_welcome_letter'
          break
        case 'Rate Us on Google':
          eventName = 'email_google_review'
          break
        case 'Review Us on Better Business Bureau':
          eventName = 'email_bbb_review'
          break
        case 'Send Cancellation Email':
          payload = { account_id: account.account_id }
          eventName = 'send_cancellation_email'
          break
        default:
          eventName = 'email_welcome_letter'
        }
    const params = {
      event_name: eventName,
      payload,
      audit: {
        user_name: user.email,
        source: 'CRM',
        date: DateTime.now().toISO()
      }
    }
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/${account.membership_profile.prospect.prospect_guid}/event`, params)
    return response
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const createProspect = async (formInfo, userInfo) => {
  const params = {
    agent_id: userInfo.agent_id,
    source_code: userInfo.source_code,
    first_name: formInfo.first_name,
    last_name: formInfo.last_name,
    email: formInfo.email,
    primary_phone: formInfo.primary_phone,
    account_number: formInfo.account_number,
    revenue_class_code: formInfo.revenue_class_code,
    market_code: formInfo.market_code,
    service_zip_code: formInfo.zip_code,
    service_state: formInfo.service_state,
    service_city: formInfo.service_city,
    service_address_line_1: formInfo.service_address_line_1,
    service_address_line_2: formInfo.service_address_line_2,
    business_name: formInfo.business_name,
    mailing_address_line_1: formInfo.billing_street,
    mailing_address_line_2: formInfo.billing_suite,
    mailing_city: formInfo.billing_city,
    mailing_state: formInfo.billing_state_code,
    mailing_zip_code: formInfo.billing_zip,
    contact_first_name: formInfo.contact_first_name,
    contact_last_name: formInfo.contact_last_name,
    contact_email: formInfo.contact_email,
    contact_relationship: formInfo.contact_relationship,
  }
  try {
    return await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/create`, params)
  } catch (e) {
    throw e
  }
}

export const createProspectAddress = async (prospect_guid, params) => {
  try {
    axios.post(`${process.env.REACT_APP_GARCON_API}/prospect/${prospect_guid}/address`, params)
  } catch (e) {
    throw e
  }
}

export const getMemberNotifications = async (member, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${member.membership_profile.prospect.prospect_guid}/account/${member.account_id}/notifications`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getMemberEvents = async (member, callback) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${member.membership_profile.prospect.prospect_guid}/account/${member.account_id}/timeline?load_all=y`)
    callback(response.data.sort((a, b) => {
      return (b.date < a.date) ? -1 : ((b.date > a.date) ? 1 : 0);
    }))
  } catch (error) {
    throw error
  }
}


export const addMemberNote = async (note) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${note.prospect_guid}/notes`, note)
    return response
  } catch (error) {
    throw error
  }
}

export const getProspect = async (prospect_guid, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospect_guid}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getProspectEvents = async(prospect, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospect.prospect_guid}/activities`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const updateContactPrefs = async(prospect_guid, params, handleAlert) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospect_guid}/contact_preferences`, params)
    handleAlert('Contact Preferences Updated', true)
  } catch (e) {
    handleAlert(`An error occured updating contact preferences ${e}`, false)
    throw e
  }
}

export const getProspectShoppers = async(prospect, callback, setLoading) => {
  setLoading(true)
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospect.prospect_guid}/shoppers?isArray=true&method=GET`)
    setLoading(false)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getPhones = async(prospectGuid, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospectGuid}/phones`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting phone numbers: ${error}`, false)
    throw error
  }
}

export const addPhone = async(prospect_guid, payload, handleAlert) => {
  handleAlert(`Adding new phone number ${payload.phone}`, true)
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospect_guid}/phones/new`, payload)
    handleAlert(`New phone number added`, true)
  } catch (error) {
    handleAlert(`Something went wrong adding phone number: ${error}`, false)
    throw error
  }
}

export const deletePhone = async(prospectGuid, prospect_phone_id, handleAlert) => {
  handleAlert('Deleting phone number....', true)
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospectGuid}/phones/delete`, {prospect_phone_id: prospect_phone_id})
    handleAlert('Phone number deleted!', true)
  } catch (error) {
    handleAlert(`Something went wrong deleting phone number: ${error}`, false)
    throw error
  }
}
