import React, {useState, useEffect} from 'react'
import { getMemberTraits } from '../../../apis/member'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPen } from '@fortawesome/pro-light-svg-icons'
import { traitsIconParser } from '../helpers/traitsIconParser'
import TraitsModal from './TraitsModal'
import { v4 as uuidv4 } from 'uuid'

const Traits = ({ member }) => {
  const [memberTraits, setMemberTraits] = useState([])
  const [showTraitsModal, setShowTraitsModal] = useState(false)

  useEffect(() => {
    if (member.prospect_guid) getMemberTraits(member.prospect_guid, setMemberTraits)
  }, [member])

  const renderTraitIcons = () => {
    return memberTraits.reduce((prevValue, currentValue) => {
      if (currentValue.trait_icon){
        prevValue.push(currentValue)
      }

      return prevValue
    }, []).map(trait => {
        return (
          <OverlayTrigger
          key={uuidv4()}
          placement="bottom"
          overlay={<Tooltip>{trait.trait_name} - {trait.reason}</Tooltip>}
          >
          <FontAwesomeIcon
            className="me-2"
            key={trait.trait_code}
            data-testid={trait.trait_code}
            size="2x"
            color="#FF88A4"
            icon={traitsIconParser(trait.trait_icon)}
          />
          </OverlayTrigger>
        )
      })
  }


  return (
    <>
      {renderTraitIcons()}
      <OverlayTrigger
        key={uuidv4()}
        placement="bottom"
        overlay={<Tooltip>Edit Traits</Tooltip>}
      >
        <FontAwesomeIcon
          className="faux-button"
          size="2x"
          data-testid="edit-traits"
          color="#FF88A4"
          icon={faUserPen}
          onClick={() => setShowTraitsModal(true)}
        />
      </OverlayTrigger>
      <TraitsModal
        member={member}
        memberTraits={memberTraits}
        show={showTraitsModal}
        handleClose={() => setShowTraitsModal(false)}
        setMemberTraits={setMemberTraits}
      />
   </>
  )
}

export default Traits