import axios from 'axios'

export const getPTC = async (shopper, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/pricing/rates/ptc/market/${shopper.market_code}/${shopper.revenue_class_code}`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting PTC info: ${error}`, false)
    throw error
  }
}
