/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { getProspectCases } from '../../../apis/cases'
import Case from '../../../pages/case/index'
import ReactTable from '../../ReactTable'
import { DateTime } from 'luxon'
import useToast from '../../../hooks/useToast'
import { isEmpty } from 'lodash'

const AccountCasesTable = ({ prospect, accountId }) => {
  const { handleAlert } = useToast()
  const [prospectCases, setProspectCases] = useState([])
  const [showCaseModal, setShowCaseModal] = useState(false)
  const [caseGuid, setCaseGuid] = useState('')

  useEffect(() => {
    getProspectCases(prospect.prospect_guid, accountId, setProspectCases, handleAlert)
  }, [prospect])

  const handleCaseModal = (e) => {
    setCaseGuid(e.case_guid)
    setShowCaseModal(true)
  }

  const handleCloseCaseModal = () => {
    setShowCaseModal(false)
  }

  const tableColumns = [
    {
      Header: 'Created',
      accessor: 'case_created_dt',
      Cell: (e) => (
        e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      ),
      width: 200
    },
    {
      Header: 'Department',
      accessor: 'department_code',
    },
    {
      Header: 'Status',
      accessor: 'to_case_status_code',
    },
    {
      Header: 'Category',
      accessor: 'category_name',
      width: 200
    },
    {
      Header: 'Description',
      accessor: 'case_description',
      width: 400
    }
  ]

  return (
    <>
      {!isEmpty(prospectCases) ?
        <>
          <ReactTable
            title="Cases"
            data={prospectCases}
            columns={tableColumns}
            canHover={true}
            handleClick={handleCaseModal}
          />

          {showCaseModal &&
            <>
              <Modal show={showCaseModal} onHide={handleCloseCaseModal} size="lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body data-testid="case-modal">
                  {caseGuid &&
                    <Case caseGuid={caseGuid} />
                  }
                  </Modal.Body>
              </Modal>
            </>
          }
        </>
      :
        <p>No cases found for connected prospect with GUID {prospect.prospect_guid}</p>
      }
    </>
  )
}

export default AccountCasesTable
