import { isEmpty } from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'

const AccountNumber = ({ handleFormInfo, marketInfo, formInfo, inputClassName, errorText, formErrors}) => (
  <>
    {!isEmpty(marketInfo) &&
      <span>
        <Form.Label style={{textTransform: "capitalize"}}>{marketInfo?.account_num_synonym}</Form.Label>
        <Form.Control
          type="text"
          name="account_number"
          placeholder={marketInfo?.validation_rules.account_number_example}
          size="md"
          value={formInfo.account_number}
          onChange={handleFormInfo}
          className={inputClassName(formErrors.account_number)}
        />
        {errorText(formErrors.account_number)}
      </span>
    }
  </>
)

export default AccountNumber
