import {
  List,
  Image,
  PlusSquare,
  Briefcase,
  ShoppingCart,
  Umbrella

} from "react-feather";

const pagesSection = [
  {
    href: "/search",
    icon: List,
    title: "Member Search",
  },
  {
    href: "/order",
    icon: PlusSquare,
    title: "New Order",
  },
  {
    href: "/cases",
    icon: Briefcase,
    title: "Cases",
  },
  {
    href: "/ops",
    icon: Umbrella,
    title: "Account Operations",
    children: [
      {
        href: "/ops/failed-orders",
        title: "Failed Orders",
      },
      {
        href: "/ops/market-geos",
        title: "Market Geos",
      },
      {
        href: "/ops/mass-notate",
        title: "Mass Notate",
      },
      {
        href: "/ops/partials",
        title: "Partial Orders",
      },
    ]
  },
  {
    href: "/pricing",
    icon: ShoppingCart,
    title: "Pricing",
    children: [
      {
        href: "/pricing/market-rates",
        title: "Market Rates",
      },
      {
        href: "/pricing/ptc",
        title: "Price to Compare",
      },
    ]
  },
  {
    href: "/styles",
    icon: Image,
    title: "Style Template",
  }
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
