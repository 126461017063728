import axios from 'axios'

export const findECLMatch = async (eclData, handleAlert) => {
  try {
    const params = {
      n_l: eclData.last_name,
      s_l1: eclData.service_address_line_1,
      s_c: eclData.service_city,
      s_s: eclData.service_state,
      s_z: eclData.service_zip_code,
    }
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/shoppers/ecl/${eclData.market_code}`, { params } )
    return(response.data)
  } catch (error) {
    handleAlert(`Something went wrong looking for an ecl match ${error}`, false)
    throw error
  }
}
