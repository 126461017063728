import { isEmpty } from 'lodash'
import { allTableColumns } from './casesTableAllColumns'

const casesTableColumnConfig = (roles, columnViewChange) => {
  const roleNames = roles ? roles.map(role => role?.name) : ['CustomerService']

  const adminDefaultColumns = [
    'case_created_dt',
    'case_type_description',
    'category_name',
    'channel_name',
    'case_status_name',
    'case_description',
    'last_note',
    'updated_at',
    'work_begins_dt',
    'closed_dt',
    'email',
    'reported_name',
    'agent_name',
    'office_code',
    'assigned_to_user_name',
    'case_status_change_reason_desc',
    'requested_close_dt'
  ]

  const opsDefaultColumns = [
    'case_created_dt',
    'case_type_description',
    'category_name',
    'channel_name',
    'case_status_name',
    'case_description',
    'last_note',
    'updated_at',
    'work_begins_dt',
    'closed_dt',
    'email',
    'reported_name',
    'agent_name',
    'office_code',
    'assigned_to_user_name',
    'case_status_change_reason_desc',
    'requested_close_dt',
    'market_code'
  ]

  const salesQualityDefaultColumns = [
    'case_created_dt',
    'category_name',
    'agent_name',
    'channel_name',
    'office_code',
    'case_status_name',
    'case_description',
    'requested_close_dt'
  ]

  const mxDefaultColumns = [
    'case_created_dt',
    'category_name',
    'case_status_name',
    'updated_at',
    'email',
    'assigned_to_user_name',
    'case_type_description',
    'case_status_change_reason_desc'
  ]

  const matchColumns = (columnsForRole) => {
    if(!isEmpty(columnViewChange)) {
      columnsForRole = columnViewChange.map(columnChange => columnChange.value)
    }
    return allTableColumns.reduce((previousValue, currentValue) => {
      if(columnsForRole.includes(currentValue.accessor)){
            previousValue.push(currentValue)
          }
          return previousValue
    }, [])
  }

  let roleColumns = []
  if(roleNames.includes('Admin')){
    roleColumns = adminDefaultColumns
  } else if (roleNames.includes('CasesMX')){
    roleColumns = mxDefaultColumns
  } else if (roleNames.includes('CasesOps')){
    roleColumns = opsDefaultColumns
  } else if (roleNames.includes('SalesQA')){
    roleColumns = salesQualityDefaultColumns
  } else {
    roleColumns = mxDefaultColumns
  }
  return matchColumns(roleColumns)
}

export default casesTableColumnConfig
