import React, { useState, useEffect } from 'react'
import { Col, Button, Row, Form } from 'react-bootstrap'
import CaseAttachments from './case/CaseAttachments'
import CaseNotes from './case/CaseNotes'
import CaseTransition from './case/CaseTransition'
import { isEmpty, groupBy } from 'lodash'
import Select from 'react-select'
import { default as FormData } from 'form-data'
import { getCaseAttributes, getAddressFromAttribute } from '../../apis/case'
import {
  getAllCaseTypeCategories,
  getCaseInteractionChannels,
  getCaseChannels
} from '../../apis/cases'
import {
  updateCaseDescription,
  updateCaseTypeCategory,
  updateCaseAssignment,
  updateCaseSalesAgents,
  updateCaseInteractionChannel,
  addCaseChannel,
  removeCaseChannel,
  saveCaseNote,
  uploadAttachment,
  deleteAttachment,
  updateCaseAttribute
} from '../../apis/caseEdits'
import { getUsersWithEmail } from '../../apis/users'
import { searchSalesAgents } from '../../apis/agents'
import useToast from '../../hooks/useToast'
import useUser from '../../hooks/useUser'
import CaseRefundContainer from './createCase/CaseRefundContainer'

const CaseDetailsContainer = ({caseDetails, reloadCase}) => {
  const caseGuid = caseDetails.case_guid
  const [caseDescription, setCaseDescription] = useState(caseDetails.case_description)
  const [caseTypeCategories, setCaseTypeCategories] = useState([])
  const [selectedCaseTypeCategory, setSelectedCaseTypeCategory] = useState({value: caseDetails.category_type_category_id, label: caseDetails.category_name})
  const [users, setUsers] = useState([])
  const [caseAssignment, setCaseAssignment] = useState(caseDetails.assigned_to_user_name)
  const [assignNewUser, setAssignNewUser] = useState({})
  const [agents, setAgents] = useState([])
  const [interactionChannels, setInteractionChannels] = useState([])
  const [caseInteractionChannel, setCaseInteractionChannel] = useState({label: caseDetails.case_interaction_channel_code, value: caseDetails.case_interaction_channel_code})
  const [allCaseChannels, setAllCaseChannels] = useState([])
  const caseChannels = caseDetails.channels?.map(channel => ({label: channel.case_channel_name, value: channel.case_case_channel_id}))
  const [caseNote, setCaseNote] = useState('')
  const { handleAlert } = useToast()
  const { userInfo } = useUser()
  const userId  = userInfo.user_id
  const caseClosed = caseDetails.case_status_code === 'CLOSED' || caseDetails.case_status_code === 'CANCELLED'
  const [address, setAddress] = useState(null)
  const [caseTypeAttributes, setCaseTypeAttributes] = useState([])

  const agentFullName = (agent) => (`${agent.first_name || ''} ${agent.last_name || ''}`)

  const agentOptions = (salesAgents) => {
    return salesAgents?.map(agent => ({label:`${agentFullName(agent)} ${agent.agent_id}`, value: agent.agent_id}))
  }

  const [caseSalesAgents, setCaseSalesAgents] = useState(agentOptions(caseDetails.sales_agents))

  useEffect(() => {
    getAllCaseTypeCategories(setCaseTypeCategories)
    getUsersWithEmail(setUsers)
    getCaseInteractionChannels(setInteractionChannels)
    getCaseChannels(setAllCaseChannels)

    return () => {
      setCaseTypeCategories([])
      setAllCaseChannels([])
    }
  }, [])

  useEffect(() => {
    const getAttributes = async () => {
    if(caseDetails.case_type_category.case_type_code === 'REFUND') {
      await getCaseAttributes(caseDetails.case_guid)
      .then((attributeResponse) => {
        const refundAddress = attributeResponse.find(attr => attr.attribute.data_name === 'refund_address_guid')
        getAddressFromAttribute(refundAddress.attribute_value, setAddress)
        // move the attribute_value key,value pair to the value key in order to re-use refund components
        const attributesWithValues = attributeResponse.map(attr => {
          return {
            ...attr.attribute,
            case_attribute_id: attr.case_attribute_id,
            value: attr.attribute_value,
            original_value: attr.attribute_value
          }
        })
        setCaseTypeAttributes(attributesWithValues)
      })
      .catch((error) => {
        handleAlert(`Something went wrong getting case attributes ${error}`,false)
      })
    }}

    getAttributes()
  }, [caseDetails.case_type_category, caseDetails.case_guid, handleAlert])

  const handleUpdateCaseDescription = async () => {
    const payload = {
      case_description: caseDescription,
      case_guid: caseGuid,
      user_id: userId
    }
    await updateCaseDescription(payload, setCaseDescription, handleAlert)
    reloadCase()
  }

  const caseTypeCategoryOptions = () => {
    const groupedCategories = groupBy(caseTypeCategories, 'case_type_description')
    return Object.keys(groupedCategories).reduce((previousValue, currentValue) => {
      previousValue.push({
        label: currentValue,
        options: groupedCategories[currentValue].map(caseType => ({label: caseType.category_name, value: caseType.case_type_category_id, case_type_code: caseType.case_type_code}))
      })
      return previousValue
    }, [])
  }

  const handleUpdateCaseTypeCategory = (category) => {
    if (category.case_type_code === 'REFUND' && caseDetails.case_type_code !== 'REFUND') {
      handleAlert('Sorry! You cannot change a non-refund case to have a REFUND category', false)
    } else {
    const categoryPayload = {
      to_case_type_category_id: category.value,
      case_guid: caseGuid,
      userId: userId
    }
    setSelectedCaseTypeCategory({label: category.label, value: category.value})
    updateCaseTypeCategory(categoryPayload, handleAlert, reloadCase)
    }
  }

  const handleUpdateAssignedUser = async (assignToSelf) => {
    if(!assignToSelf && isEmpty(assignNewUser)) {
      handleAlert('You must assign a new user', false)
    } else {
      const params = {caseGuid, assignToSelf}
      await assignToSelf ? updateCaseAssignment(params, userInfo, setCaseAssignment, handleAlert) : updateCaseAssignment(params, assignNewUser, setCaseAssignment, handleAlert)
      setAssignNewUser({})
    }
  }

  const handleUpdateCaseSalesAgents = (agents) => {
    updateCaseSalesAgents(agents, caseGuid, setCaseSalesAgents, handleAlert)
  }

  const handleAgentInputChange = (input) => {
    searchSalesAgents(input, setAgents)
  }

  const handleUpdateInteractionChannel = (interactionChannel) => {
    const interactionPayload = {
      to_case_interaction_channel: interactionChannel.value,
      case_guid: caseGuid,
      userId: userId
    }
    updateCaseInteractionChannel(interactionPayload, setCaseInteractionChannel, handleAlert)
  }

  const handleUpdateCaseChannels = (newCaseChannels) => {
    const caseChannelToRemove = caseChannels.reduce((previousValue, currentValue) => {
      if(!newCaseChannels.includes(currentValue)){
        previousValue.push(currentValue)
      }
      return previousValue
    }, [])

    const caseChannelToAdd = newCaseChannels.reduce((previousValue, currentValue) => {
      if(!caseChannels.includes(currentValue)){
        previousValue.push(currentValue)
      }
      return previousValue
    }, [])

    isEmpty(caseChannelToRemove)
    ? addCaseChannel(caseChannelToAdd[0], caseGuid, handleAlert, reloadCase)
    : removeCaseChannel(caseChannelToRemove[0], caseGuid, handleAlert, reloadCase)
  }

  const userOptions = users?.map(user => ({label: user.email, value: user.user_id}))

  const handleSaveCaseNote = () => {
    const notePayload = {
      case_notes: caseNote,
      case_guid: caseGuid,
      note_user_id: userId,
    }
    saveCaseNote(notePayload, setCaseNote, handleAlert, reloadCase)
  }

  const handleUploadAttachment = (file) => {
    if (!file) {
      handleAlert('Please choose a file to upload first', false)
    } else {
      let formData = new FormData()
      formData.append('file', file)
      formData.append('user_id', userId)
      uploadAttachment(formData, caseGuid, handleAlert, false, reloadCase)
    }
  }

  const handleDeleteAttachment = (fileName) => {
    deleteAttachment(fileName, caseGuid, handleAlert, reloadCase)
  }

  const handleUpdateCaseAttribute = (caseAttribute) => {
    updateCaseAttribute(caseGuid, caseAttribute, handleAlert, reloadCase)
  }

  return (
    <>
      <Row className="m-3">
        <Col>
          <h2> Case Details:</h2>
        </Col>
      </Row>
      <Row className="mx-3">
        <Col md={2}>
          <p>Description:</p>
        </Col>
        <Col>
          <Form>
            <Form.Control as="textarea" data-testid="description-input" rows={2} onChange={(e) => setCaseDescription(e.target.value)} value={caseDescription} className="mb-2" />
            <Button data-testid="description-button" disabled={!caseDescription || caseDescription === caseDetails.case_description} onClick={handleUpdateCaseDescription} className="mb-2">Save Case Description</Button>
          </Form>
        </Col>
      </Row>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p className="my-2">Category:</p>
        </Col>
        <Col>
          <Select
            className="react-select-category"
            aria-label="Case Category Name"
            data-testid="case-category-select"
            isDisabled={caseClosed}
            size="sm"
            options={caseTypeCategoryOptions()}
            onChange={(e) => handleUpdateCaseTypeCategory(e)}
            value={selectedCaseTypeCategory}
          />
        </Col>
      </Row>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <label className="my-2">Opened By:</label>
        </Col>
        <Col>
          <p className="my-2">{caseDetails.created_by_user_name}</p>
        </Col>
      </Row>
      {(caseDetails.case_type_code === 'REFUND') &&
        <Row className="mx-3 mt-3">
          <Col className="ms-2">
            <CaseRefundContainer
              caseTypeAttributes={caseTypeAttributes}
              setCaseTypeAttributes={setCaseTypeAttributes}
              address={address}
              handleUpdateCaseAttribute={handleUpdateCaseAttribute}
              accountId={caseDetails.account_id}
              prospectGuid={caseDetails.reported_by_prospect_guid}
              caseCreatedByAgentId={caseDetails.created_by_user_id}
            />
          </Col>
        </Row>
      }
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <label className="my-2">Assigned To:</label>
        </Col>
        <Col>
          <p className="my-2">{caseAssignment}</p>
          <Select
            className="react-select-assignment mb-2"
            aria-label="Assign to User"
            data-testid="case-assignment-select"
            size="sm"
            options={userOptions}
            onChange={(e) => setAssignNewUser(e)}
            value={{value: assignNewUser.value, label: assignNewUser.label}}
          />
          <Button className="btn btn-primary me-1" data-testid="assign-new-user" onClick={() => handleUpdateAssignedUser(false)}>Assign User</Button>
          <Button className="btn btn-warning" data-testid="assign-self" onClick={() => handleUpdateAssignedUser(true)}>Assign To Me</Button>
        </Col>
      </Row>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p className="my-2"> Sales Agents:</p>
        </Col>
        <Col>
          <Select
            className="react-select-sales-agents"
            aria-label="Sales Agents"
            data-testid="case-sales-agents-select"
            size="sm"
            isMulti
            placeholder="Search for sales agents using first or last name"
            options={agentOptions(agents)}
            value={caseSalesAgents}
            onInputChange={(e) => handleAgentInputChange(e)}
            onChange={(e) => handleUpdateCaseSalesAgents(e)}
          />
        </Col>
      </Row>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p> Interaction Channel:</p>
        </Col>
        <Col>
          <Select
            className="react-select-interaction-channel"
            aria-label="Interaction Channel Code"
            data-testid="case-interaction-channel-select"
            size="sm"
            options={interactionChannels.map(channel => ({label: channel.channel_code, value: channel.channel_code}))}
            onChange={(e) => handleUpdateInteractionChannel(e)}
            value={caseInteractionChannel}
          />
        </Col>
      </Row>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p className="my-1">Channels:</p>
        </Col>
        <Col>
          <Select
            className="react-select-channel-code"
            aria-label="Case Channel Code"
            data-testid="case-channel-code-select"
            size="sm"
            isMulti
            value={caseChannels}
            options={allCaseChannels.map(channel => ({label: channel.case_channel_name, value: channel.case_channel_code}))}
            onChange={(e) => handleUpdateCaseChannels(e)}
          />
        </Col>
      </Row>
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p> Notes: </p>
        </Col>
        <CaseNotes caseDetails={caseDetails} caseNote={caseNote} setCaseNote={setCaseNote} handleSaveCaseNote={handleSaveCaseNote} />
      </Row>
      <CaseAttachments caseDetails={caseDetails} handleUploadAttachment={handleUploadAttachment} handleDeleteAttachment={handleDeleteAttachment} />
      <Row className="mx-3 mt-3">
        <Col md={2}>
          <p> Change Offer: </p>
        </Col>
        <Col>
          <Button>See Offers</Button>
        </Col>
      </Row>
      <Row className="mx-3 my-3">
        <Col md={2}/>
        <Col>
          <CaseTransition caseDetails={caseDetails} reloadCase={reloadCase} positionTop={false} key={caseDetails.case_guid}/>
        </Col>
      </Row>
    </>
  )
}

export default CaseDetailsContainer
