import axios from 'axios'

export const getAccountReject = async (accountId, setLoading, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/rejects/account/${accountId}`)
    if (response.data.reject_id) {
      getReject(response.data.reject_id, setLoading, callback, handleAlert)
    }
  } catch (error) {
    setLoading(false)
    handleAlert('Something went wrong getting the reject id for rejected account', false)
    throw error
  }
}


export const getReject = async (rejectId, setLoading, callback) => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/rejects/${rejectId}`)
      setLoading(false)
      callback(response.data)
  } catch (error) {
    setLoading(false)
    throw error
  }
}

export const getRejectStatuses = async (callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/reject_statuses`)
    callback(response.data)
  } catch (error) {
    handleAlert('Something went wrong getting the reject id for rejected account', false)
    throw error
  }
}

export const saveReject = async (updatedReject, callback, handleAlert) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/rejects/${updatedReject.reject_id}`, updatedReject)
    callback('Reject Saved!')
    handleAlert('Reject Saved', true)
  } catch (error) {
    handleAlert(error, false)
    throw error
  }
}
