import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import Select from 'react-select'
import { getAllCaseTypeCategories, getCaseTypeAttributes } from '../../../apis/cases'
import { getAddressFromAttribute } from '../../../apis/case'
import CaseRefundContainer from './CaseRefundContainer'
import { groupBy } from 'lodash'
import useUser from '../../../hooks/useUser'
import { DateTime } from 'luxon'
import { uploadAttachment } from '../../../apis/caseEdits'
import { createCase } from '../../../apis/case'
import useToast from '../../../hooks/useToast'
import CreateCaseFormSalesOps from './CreateCaseFormSalesOps'

const CreateCaseFormDefault = ({ showCaseForm, setShowCaseForm, member, setReloadMember }) => {
  const emptyDropDown = {label: '', value: ''}
  const [selectedCaseTypeCategory, setSelectedCaseTypeCategory] = useState(emptyDropDown)
  const [selectedDepartment, setSelectedDepartment] = useState(emptyDropDown)
  const [caseTypeCategories, setCaseTypeCategories] = useState([])
  const [caseTypeAttributes, setCaseTypeAttributes] = useState([])
  const [attachment, setAttachment] = useState(null)
  const [requestResolution, setRequestResolution] = useState(false)
  const [address, setAddress] = useState(null)
  const { userInfo } = useUser()
  const { handleAlert } = useToast()

  const defaultCaseForm = {
    prospect_guid: member.prospect_guid || null,
    case_description: '',
    case_channel_code: null,
    case_type_category_id: null,
    created_by_user_id: userInfo.user_id,
    interaction_channel: null,
    sales_agents: null,
    requested_close_dt: null,
    order_id: member.order_id || null,
    attributes: null,
    account_id: member.account_id || null
  }

  const [caseForm, setCaseForm] = useState(defaultCaseForm)
  let formData = new FormData()
  const contactName = member.membership_profile ? member.membership_profile.first_name + ' ' + member.membership_profile.last_name : member.first_name + ' ' + member.last_name

  const departmentOptions = [
    Object.freeze({ label: 'MX', value: 'MX' }),
    Object.freeze({ label: 'Sales', value: 'SALES' }),
    Object.freeze({ label: 'Ops', value: 'OPS' })
  ]

  const resetCaseForm = () => {
    setCaseForm(defaultCaseForm)
    setSelectedDepartment(emptyDropDown)
    setSelectedCaseTypeCategory(emptyDropDown)
    setRequestResolution(false)
    setShowCaseForm(false)
    setCaseTypeAttributes(null)
    setReloadMember(true)
  }

  const handleSelectDepartment = (e) => {
    setSelectedDepartment(e)
    setSelectedCaseTypeCategory(emptyDropDown)
    getAllCaseTypeCategories(setCaseTypeCategories, e.value)
  }

  const caseTypeCategoryOptions = () => {
    const groupedCategories = groupBy(caseTypeCategories, 'case_type_description')
    return Object.keys(groupedCategories).reduce((previousValue, currentValue) => {
      previousValue.push({
        label: currentValue,
        options: groupedCategories[currentValue].map(caseType => (
          {label: caseType.category_name,
            value: caseType.case_type_category_id,
            code: caseType.case_type_code,
            definition: caseType.definition,
            example: caseType.example
          }
        ))
      })
      return previousValue
    }, [])
  }

  const defaultAttributeData = (attribute)=> {
    if (attribute.data_name === 'refund_amount') return ''
    if (!attribute.query_response_options) return
    // ex. From ['account.first_name'] gets member.first_name
    const data = attribute.query_response_options.map(query => {
      let keys = query.split('.')
      //  keys will look something like ['account', 'first_name']
      return member[keys[1]] || member[keys[3]]
    }).join(' ')

    const attributeDefaultData = data.trim()
    if(attribute.data_name === 'refund_address_guid') {
      getAddressFromAttribute(attributeDefaultData, setAddress)
    }
    return attributeDefaultData
  }

  const handleSelectCaseTypeCategory = async (e) => {
    setCaseForm(caseForm => {
      return {...caseForm,
      case_type_category_id: e.value,
    }})
    setSelectedCaseTypeCategory(e)
    if(e.code === 'REFUND'){
      await getCaseTypeAttributes(e.value)
      .then((attributeResponse) => {
        setCaseTypeAttributes(attributeResponse.map(attr => ({...attr, value: defaultAttributeData(attr)})))
      })
      .catch((error) => {
        handleAlert(`Something went wrong getting case attributes ${error}`,false)
      })
    }
  }

  const handleRequestResolution = () => {
    if (!requestResolution) {
      setCaseForm(caseForm =>  {
        return {...caseForm,
        requested_close_dt: DateTime.local().endOf('day').toISO()}
      })
    }
    setRequestResolution(!requestResolution)
  }

  const handleCreateCase = (e) => {
    e.preventDefault()
    let attributesWithData = []
    if (caseTypeAttributes?.length > 0) {
      attributesWithData = caseTypeAttributes.filter(attr => !!attr.value)
      let refundCalculation = attributesWithData.find(attr => attr.data_name === 'refund_calculation')
      if (refundCalculation) refundCalculation.value = JSON.stringify(refundCalculation.value)
    }
    if (requestResolution) {
      setCaseForm(caseForm => {
        return {...caseForm, requested_close_dt: DateTime.local().endOf('day').toISO()}
      })
    }
    createCase({...caseForm, attributes: attributesWithData}, handleAlert)
      .then((newCase) => {
        if (attachment) {
          formData.append('file', attachment)
          formData.append('user_id', userInfo.user_id)
          setTimeout(() => {
            uploadAttachment(formData, newCase.case_guid, handleAlert, true)
          }, 3000)
        }
      })
      .finally(() => {
        resetCaseForm()
      })
  }

  const handleRemoveForm = () => {
    resetCaseForm()
    setShowCaseForm(!showCaseForm)
  }

  return(
    <>
      {showCaseForm &&
        <>
          <Form>
            <Col className="m-4">
            <Row>
              <Col md={9}>
                <h1> Create a New Case</h1>
              </Col>
              <Col className="d-flex">
                <Button
                  className="btn-lg ms-auto"
                  variant="primary"
                  onClick={() => handleRemoveForm()}>
                  Remove Case Form
                </Button>
              </Col>
            </Row>
              <Row className="mt-3">
                <Form.Group controlId="department-select">
                  <Form.Label>Select Department:</Form.Label>
                  <Select
                    className="react-select-department"
                    aria-label="Department Select"
                    size="sm"
                    options={departmentOptions}
                    onChange={(e) => handleSelectDepartment(e)}
                    value={selectedDepartment}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#B0DBE9',
                        primary: '#0E7C9E',
                      },
                    })}
                  />
                </Form.Group>
                </Row>
                {(selectedDepartment.value === 'SALES' || selectedDepartment.value === 'OPS') &&
                  <CreateCaseFormSalesOps
                    member={member}
                    department={selectedDepartment}
                    setCaseForm={setCaseForm}
                    caseForm={caseForm}
                  />
                }
                <Row className="mt-3">
                <Form.Group controlId="case-type-category-select">
                  <Form.Label>Case Type:</Form.Label>
                  <Select
                    className="react-select-category"
                    aria-label="Case Category Name"
                    data-testid="case-type-select"
                    size="sm"
                    options={caseTypeCategoryOptions()}
                    onChange={(e) => handleSelectCaseTypeCategory(e)}
                    value={selectedCaseTypeCategory}
                  />
                  {selectedCaseTypeCategory.definition && <p><b>Definition: </b>{selectedCaseTypeCategory.definition}</p>}
                  {selectedCaseTypeCategory.example && <p><b>Example: </b>{selectedCaseTypeCategory.example}</p>}
                </Form.Group>
              </Row>
                {selectedCaseTypeCategory.code === 'REFUND' && caseTypeAttributes?.length > 0 &&
                  <CaseRefundContainer
                    caseTypeAttributes={caseTypeAttributes}
                    setCaseTypeAttributes={setCaseTypeAttributes}
                    address={address}
                    accountId={member.account_id}
                  />
                }
              <Row className="mt-3">
                <Form.Group controlId="contact-name">
                  <Form.Label>Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="member_name"
                    disabled={true}
                    placeholder={contactName}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group controlId="phone-number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="member_phone"
                    disabled={true}
                    placeholder={member.primary_phone}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group controlId="case-description">
                  <Form.Label>Case Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="case_description"
                    rows={3}
                    onChange={(e) => setCaseForm(caseForm => ({...caseForm, [e.target.name]: e.target.value}))}
                    value={caseForm.case_description}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Add Attachment</Form.Label>
                  <Form.Control type="file" onChange={(e) => setAttachment(e.target.files[0])}/>
                </Form.Group>
              </Row>
              <Row className="mt-2">
                <Form.Check
                  type="checkbox"
                  id="request_resolution_today"
                  label="Request a resolution today"
                  onChange={() => handleRequestResolution()}
                  checked={requestResolution}
                />
                <Button size='lg' disabled={caseForm.case_description === ''} onClick={(e) => handleCreateCase(e)}>Submit Case</Button>
              </Row>
            </Col>
          </Form>
        </>
      }
    </>
  )
}

export default CreateCaseFormDefault;
