import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { updatePrimaryPhone } from '../../apis/accounts'
import { deletePhone } from '../../apis/prospects'
import ReactTableBasic from '../ReactTableBasic'
import useToast from '../../hooks/useToast'
import useUser from '../../hooks/useUser'
import { faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'

const Phones = ({ account, reloadAccount, setReloadAccount }) => {
  const { handleAlert } = useToast()
  const { userInfo } = useUser()

  const handleDelete = async (e) => {
    const selectedPhoneNumberIsNotPrimaryPhone = account?.phones?.length > 1 && e.row.original !== account?.primary_phone
    if (selectedPhoneNumberIsNotPrimaryPhone) {
      await deletePhone(e.row.original.prospect_guid, e.row.original.prospect_phone_id, handleAlert)
      setReloadAccount(!reloadAccount)
    } else {
      handleAlert(`Cannot delete primary phone number. Please set another phone as primary first."`, false)
    }
  }

  const handlePrimary = async (e) => {
    const payload = {
      phone: e.row.original.phone,
      membership_profile_id: account.membership_profile_id
    }
    await updatePrimaryPhone(account.account_id, payload, userInfo, handleAlert)
    setReloadAccount(!reloadAccount)
  }

  const tableColumns = [
    {
      Header: 'Phone Number',
      accessor: e =>  {return e.phone.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')},
      width: 75,
    },
    {
      Header: 'Type',
      accessor: 'phone_type',
      width: 55,
    },
    {
      Header: 'Primary',
      id: 'isPrimary',
      width: 65,
      Cell: e => e.row.original.phone === account.primary_phone ?
        (<span className='text-center'>Primary</span>)
        :
        (
          <div className='text-center'>
            <Button className="btn-sm" variant="danger" onClick={() => handlePrimary(e)}>
              Set as Primary
            </Button>
          </div>
        )

    },
    {
      Header: 'Remove',
      accessor: 'remove',
      width: 45,
      Cell: e => (
        <div className='text-center'>
          <FontAwesomeIcon size='2x' color="#FF8676" icon={faTrashCan} onClick={() => handleDelete(e)} />
        </div>
      )
    },
  ]

  return (
    <Container className='ms-0'>
      {!isEmpty(account.phones) ?
        <div>
          <h4>Phone Numbers</h4>
          <div>
            <ReactTableBasic
              columns={tableColumns}
              data={account.phones}
              canHover={true}
            />
          </div>
        </div>
      :
        <h4>No phone numbers for this account</h4>
      }
    </Container>
  )
}

export default Phones
