import React from 'react'
import { Card } from 'react-bootstrap'
import ReactTableBasic from '../../ReactTableBasic'
import { DateTime } from 'luxon'
import { isEmpty } from 'lodash'

const AccountTransactions = ({ acctTransactions }) => {

  const tableColumns = [
    {
      Header: 'Internal ID',
      accessor: 'account_transaction_id',
      width: 85,
    },
    {
      Header: 'Raw Transaction ID',
      accessor: 'transaction_id',
      width: 100,
    },
    {
      Header: 'Transaction Code',
      accessor: 'transaction_action_code',
      width: 100,
    },
    {
      Header: 'Transaction Qualifier',
      accessor: 'transaction_qualifier',
      width: 100,
    },
    {
      Header: 'Transaction Response Qualifier',
      accessor: 'transaction_response_qualifier',
      width: 100,
    },
    {
      Header: 'Reason Code',
      accessor: 'reason_code',
      width: 95,
    },
    {
      Header: 'Reason Description',
      accessor: 'reason_description',
      width: 100,
    },
    {
      Header: 'Transaction Date',
      accessor: 'transaction_date',
      width: 115,
      Cell: (e) => (
        e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      )
    },
    {
      Header: 'Utility',
      accessor: 'sender_utility.utility_name',
      width: 80,
    },
    {
      Header: 'Account Number',
      accessor: 'account_number',
      width: 100,
    },
  ]
  return (
    <div className='mt-3'>
      <hr />
      <>
      {!isEmpty(acctTransactions) ?
        <div>
          <ReactTableBasic
            title="Account Transactions"
            columns={tableColumns}
            data={acctTransactions}
            canHover={true}
          />
        </div>
        :
        <Card.Title>
          No transactions available for this account
        </Card.Title>
      }
      </>
    </div>
  )
}

export default AccountTransactions
