import React, { useState, useEffect, useRef } from "react";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";
import { Search } from "react-feather";
import useSidebar from "../../hooks/useSidebar";
import NavbarUser from "./NavbarUser";
import { useNavigate, createSearchParams } from "react-router-dom";

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar()
  const[query, setQuery] = useState('')
  const navigate = useNavigate()
  const [params, setParams] = useState({})
  const isFirstRender = useRef(true)

  useEffect(() => {
    if(isFirstRender.current){
      isFirstRender.current = false
      return
    }
    setParams({ q: query})
  }, [query])

  const handleSearch = (e) => {
    e.preventDefault()
    navigate({
      pathname: '/search',
      search: `?${createSearchParams(params)}`
    })
    setQuery('')
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      handleSearch(e)
    }
  }


  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Form inline="true" className="d-none d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control
            placeholder="Search Members"
            aria-label="Search"
            value={query || ''}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          <Button variant="" onClick={(e) => {handleSearch(e)}}>
            <Search className="feather" />
          </Button>
        </InputGroup>
      </Form>

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
