import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";

const Loader = ({message}) => (
  <Container fluid className="vh-50 d-flex">
    <Row className="justify-content-center align-self-center w-100 text-center">
      <Spinner
        variant="primary"
        data-testid="spinner-loading"
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />
    </Row>
    <Row className="justify-content-center align-self-center w-100 text-center">
      <h4>{message}</h4>
    </Row>
  </Container>
);

export default Loader;
