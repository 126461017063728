import React, {useState, useEffect} from 'react'
import { Row, Col, Form, Badge } from 'react-bootstrap'
import { getEligibleRewardsForAccount } from '../../../apis/rewards'
import { DateTime } from 'luxon'
import { formatter } from '../../../utils/currencyFormatter'
import { isJsonStructure } from '../../../utils/isJsonStructure'
import findCaseAttribute from '../helpers/findCaseAttribute'

const RewardsRefundContainer = ({caseTypeAttributes, setCaseTypeAttributes, accountId, handleUpdateCaseAttribute}) => {
  const refundAmountAttribute = findCaseAttribute('refund_amount', caseTypeAttributes)
  const refundRewardIdsAttribute = findCaseAttribute('refund_reward_ids', caseTypeAttributes)
  const [rewards, setRewards] = useState([])
  const [calculatedTotal, setCalculatedTotal] = useState(formatter.format(refundAmountAttribute.value) || formatter.format(0))
  const [selectedRewardIds, setSelectedRewardIds] = useState([])

  useEffect(() => {
    getEligibleRewardsForAccount(accountId, setRewards)
  }, [accountId])

  useEffect(() => {
    if (!refundRewardIdsAttribute.value) {
      refundRewardIdsAttribute.value = []
    } else {
      if (isJsonStructure(refundRewardIdsAttribute.value)) {
        const parsedRewardIds = JSON.parse(refundRewardIdsAttribute.value)
        setSelectedRewardIds(parsedRewardIds)
        refundRewardIdsAttribute.value = parsedRewardIds
      } else {
        setSelectedRewardIds(refundRewardIdsAttribute.value)
      }
    }
  }, [refundRewardIdsAttribute])


  const updateRefundCaseTypeAttributes = () => {
    const copyAttributesWithNewData = caseTypeAttributes.map(attribute => ({...attribute}))
    setCaseTypeAttributes(copyAttributesWithNewData)
    if (handleUpdateCaseAttribute) {
      handleUpdateCaseAttribute(refundRewardIdsAttribute)
      handleUpdateCaseAttribute(refundAmountAttribute)
    }
  }

  const checkForSelectedReward = (reward) => {
    return selectedRewardIds.some(id => id === reward.reward_id)
  }

  const handleSelectReward = (e) => {
    const selectedReward = rewards.find(reward => reward.reward_id === parseInt(e.target.id))
    const currentTotal = Number(calculatedTotal.replace(/[^0-9.-]+/g,""))
    let updatedTotal = ''
    if (e.target.checked) {
      updatedTotal = currentTotal + parseFloat(selectedReward.reward_amount)
      refundRewardIdsAttribute.value = refundRewardIdsAttribute.value.concat(selectedReward.reward_id)
    } else {
      updatedTotal = currentTotal - parseFloat(selectedReward.reward_amount)
      const index = refundRewardIdsAttribute.value.indexOf(selectedReward.reward_id)
      refundRewardIdsAttribute.value.splice(index, 1)
    }
    setCalculatedTotal(formatter.format(updatedTotal))
    refundAmountAttribute.value = updatedTotal
    updateRefundCaseTypeAttributes()
  }

  const printRewards = () => {
    return rewards.map(reward => {
      return(
        <div key={reward.reward_id} className={checkForSelectedReward(reward) && !reward.replaceable ? "warn-bg" : null}>
          <Row>
            <Col md={2}>
              <p>{formatter.format(reward.reward_amount)}</p>
            </Col>
            <Col md={3}>
              <p>{reward.reward_description}</p>
            </Col>
            <Col md={3}>
              <p>{reward.reward_status_code}</p>
            </Col>
            <Col md={2}>
              <p>{reward.vest_dt ? DateTime.fromISO(reward.vest_dt).toLocaleString(DateTime.DATE_MED) : ''}</p>
            </Col>
            <Col md={2}>
              <Form.Check
              size="lg"
              type="checkbox"
              label="Include Reward"
              id={reward.reward_id}
              onChange={(e) => handleSelectReward(e)}
              checked={checkForSelectedReward(reward)}
              />
            </Col>
          </Row>
          {checkForSelectedReward(reward) && !reward.replaceable &&
            <Row>
              <small><b>WARNING: This reward was already manually sent!<br />To update the existing check's recipient name or address please visit the checks screen</b></small>
            </Row>
          }
          <hr />
        </div>
      )
    })
  }

  return (
    <>
      <Row className="my-2">
        <Form.Group controlId="refund-rewards">
            <Form.Label><b>Refund Rewards</b></Form.Label>
        </Form.Group>
      </Row>
      <Row className="border my-1">
        <Col md={2}>
          <p>Amount</p>
        </Col>
        <Col md={3}>
          <p>Description</p>
        </Col>
        <Col md={3}>
          <p>Status</p>
        </Col>
        <Col md={2}>
          <p>Vest Date</p>
        </Col>
        <hr />
        {rewards.length > 0 &&
          printRewards()
        }
        <Row className="mb-2">
          <Col md={10}>
            <Badge bg="lightElectric" text="black" className="fs-md pt-3">
              <p>Calculated Total: {calculatedTotal}</p>
            </Badge>
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default RewardsRefundContainer
