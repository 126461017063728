import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col, Spinner, Form } from "react-bootstrap"
import { getWinbackOffers, getRetentionOffers, getWinback, getOfferDetails, getTpvOptions } from '../../../apis/offers'
import { sendProspectEvent } from '../../../apis/prospects'
import isEmpty from 'lodash/isEmpty'
import ReactTableBasic from '../../ReactTableBasic'
import CancelButton from './CancelButton'
import DispositionButton from './DispositionButton'
import VerificationForm from './VerificationForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDollar, faTag, faTreasureChest } from '@fortawesome/pro-light-svg-icons'
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons'
import CreateCaseButton from '../../cases/createCase/CreateCaseButton'
import dino from '../../../assets/img/dino.gif'
import useUser from '../../../hooks/useUser'
import useToast from '../../../hooks/useToast'
import { DateTime } from 'luxon'

const Offers = ({ account, showCaseForm, setShowCaseForm }) => {
  const { userInfo } = useUser()
  const { handleAlert } = useToast()
  const [offers, setOffers] = useState(null)
  const [offer, selectOffer] = useState({})
  const [requestId, setRequestId] = useState('')
  const [loading, setLoading] = useState(false)
  const [showDisposition, updateSetDisposition] = useState(false)
  const [winback, setWinback] = useState(null)
  const [showVerification, setShowVerification] = useState(false)
  const [details, setDetails] = useState(null)
  const [tpvTypes, setTpvTypes] = useState([])
  const [tpv_type, setTpvType] = useState(null)
  const [channel, setChannel] = useState(null)
  const [reasons, setDropReasons] = useState(null)
  const [confirmationCode, setConfirmationCode] = useState(null)

  const tableColumns = [
    {
      Header: 'Description',
      accessor: 'offer_desc',
    },
    {
      Header: 'Rate',
      accessor: 'rate_friendly',
      width: 100,
    },
  ]

  const offerColumns = [
    {
      Header: 'From Date',
      accessor: 'cycle_from_dt',
      Cell: (e) => (
        e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      ),
    },
    {
      Header: 'To Date',
      accessor: 'cycle_to_dt',
      Cell: (e) => (
        e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      ),
    },
    {
      Header: 'Rate',
      accessor: 'rate',
    },
    {
      Header: 'Rate Change Available',
      accessor: 'is_retention_rate',
      Cell: (e) => (
        <div className="text-center">
          {e.value ?
            <FontAwesomeIcon size="2x" className="me-1" color="#7BE0FF" icon={faCheck} fixedWidth />
            :
            <FontAwesomeIcon size="2x" className="me-1" color="#FF8676" icon={faXmark} fixedWidth />
          }
        </div>
      ),
    },
  ]

  useEffect(() => {
    const { account_id, state_code, account_status_code } = account
    const isRetention = showRetention(account_status_code)
    let channel = isRetention ? 'RETENTION' : 'INSIDE_SALES'
    setChannel(channel)
    getOfferDetails(account_id, setDetails)
    getTpvOptions(channel, state_code, setTpvTypes)
  }, [account])

  const setOfferData = (offers) => {
    setOffers(offers?.results)
    setRequestId(offers['x-request-id'])
  }

  const getOffersForWinback = () => {
    setLoading(true)
    getWinback(account?.account_id, setWinback)
    getWinbackOffers(account?.account_id, setOfferData)
  }

  const getOffersForRetention = async () => {
    setLoading(true)
    const offers = await getRetentionOffers(account?.account_id)
    setOfferData(offers)
  }

  const next = () => {
    if (setTpvTypes.length <= 0 || tpv_type !== null) {
      setShowVerification(true)
    } else {
      alert('Select TPV Type to continue')
    }
  }

  const showRetention = (account_status_code) => {
    switch (account_status_code) {
      case 'ACTIVE':
        return true
      case 'PENDING_PROVIDER_SUBMIT':
        return true
      case 'PENDING_PROVIDER_RESUBMIT':
        return true
      case 'PROVIDER_ACCEPT':
        return true
      case 'PROVIDER_SUBMIT':
        return true
      case 'MARKET_ENROLL_ACCEPT':
        return true
      case 'MOVE_PENDING':
        return true
      case 'INITIAL':
        return true
      default:
        return false
    }
  }

  return (
    <Container className="px-4 py-2">
      {
        confirmationCode ?
          <>
            <h1>
              Success! Confirmation Code: {confirmationCode}
              <img className="px-2 py-1" style={{ maxWidth: 300 }} src={dino} alt="Tim Party Dino" />
            </h1>
            <Button
              variant="primary"
              className="m-3 btn-lg"
              onClick={() => sendProspectEvent('Send Cancellation Email', userInfo, account, handleAlert)} >
              Send Cancellation Email
            </Button>
          </>
          :
          <Row>
            {offers === null && !reasons ?
              <Col md="auto">
                <Button variant="primary" className="m-3 btn-lg">
                  {loading ?
                    <>
                      <Spinner
                        as="span"
                        data-testid="loading-spinner"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      <span>Getting Offers...</span>
                    </>
                    :
                    <>
                      {
                        showRetention(account?.account_status_code) ?
                          <span onClick={getOffersForRetention}>View Retention Offers</span>
                          :
                          <span onClick={getOffersForWinback}>View Winback Offers</span>
                      }
                    </>
                  }
                </Button>
              </Col> :
              <>
                {isEmpty(offers) && !reasons && <div>No offers for this Account <h4>Request ID: {requestId}</h4></div>}
              </>
            }
            {!isEmpty(offers) && isEmpty(offer) && !reasons &&
              <div>
                <ReactTableBasic
                  title="Offers"
                  columns={tableColumns}
                  data={offers}
                  canHover={true}
                  handleClick={selectOffer}
                />
                <h4>Request ID: {requestId}</h4>
              </div>
            }
            {!isEmpty(offer) && !showDisposition && !showVerification &&
              <div className="m-3">
                <p className='font-weight-bold card-title'>{offer.long_script}</p>
                <br />
                {/* we do not need to show this table for winback offers */}
                {offer.retention_details?.cycles &&
                  <Row>
                    <Col md="8">
                      <ReactTableBasic
                        title="Retention Offer Details"
                        columns={offerColumns}
                        canHover={false}
                        data={offer.retention_details?.cycles}
                      />
                    </Col>
                  </Row>
                }
                <br />
                <Row>
                  <Col md="4" >
                    <ul className="list-unstyled">
                      <li className="mb-2 h3"><span className='font-weight-bold'>Old Offer</span> {account.offer_code}</li>
                      <li className="mb-2">
                        <FontAwesomeIcon size="lg" className="me-1" color="#E86371" icon={faTag} fixedWidth /><span className='font-weight-bold'>Old Plan: </span>{account.plan_name}
                      </li>
                      <li className="mb-1">
                        <FontAwesomeIcon size="lg" className="me-1" color="#E86371" icon={faCircleDollar} fixedWidth /><span className='font-weight-bold'>Current Rate: </span>{details?.intro_rate_status?.intro_rate_message}
                      </li>
                      <li className="mb-1">
                        <span className='font-weight-bold'>Forfeited Bonus: </span>
                        {details && details.forfeitable_rewards.length > 0 ? details.forfeitable_rewards.map((reward, key) =>
                          <ul key={key} style={{ marginBottom: "auto" }} className="list-unstyled">
                            <li>
                              <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faTreasureChest} fixedWidth />
                              {reward.reward_description} vests on {new Date(reward.vest_dt).toLocaleDateString()}
                            </li>
                          </ul>
                        ) : 'N/A'}
                      </li>
                      <li className="mb-1">
                        <span className='font-weight-bold'>Earned Rewards: </span>
                        {details && details.earned_rewards.length > 0 ? details.earned_rewards.map((reward, key) =>
                          <ul key={key} style={{ marginBottom: "auto" }} className="list-unstyled">
                            <li>
                              <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faTreasureChest} fixedWidth />
                              {reward.reward_description} vests on {new Date(reward.vest_dt).toLocaleDateString()}
                            </li>
                          </ul>
                        ) : 'N/A'}
                      </li>
                    </ul>
                  </Col>
                  <Col md="4" >
                    <ul className="list-unstyled" aria-label="new offer list">
                      <li className="mb-2 h3"><span className='font-weight-bold'>New Offer</span> {offer.offer_code}</li>
                      <li className="mb-2">
                        <FontAwesomeIcon size="lg" className="me-1" color="#E86371" icon={faTag} fixedWidth /><span className='font-weight-bold'>New Plan:</span> {offer.plan_name}
                      </li>
                      <li className="mb-1">
                        <FontAwesomeIcon size="lg" className="me-1" color="#E86371" icon={faCircleDollar} fixedWidth /><span className='font-weight-bold'>{offer.rate_friendly}</span> effective from <span className='font-weight-bold'>{DateTime.fromISO(offer.retention_details?.lock_from_dt).toLocaleString(DateTime.DATE_MED)}</span> until <span className='font-weight-bold'>{DateTime.fromISO(offer.retention_details?.lock_to_dt).toLocaleString(DateTime.DATE_MED)}</span>
                      </li>
                      <li className="mb-2">
                        <span className='font-weight-bold'>New Plan Bonus: </span>
                        {offer.plan_promos?.length > 0 ? offer.plan_promos.map((feature, key) =>
                          <ul key={key} style={{ marginBottom: "auto" }} className="list-unstyled">
                            <li>
                              <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faTreasureChest} fixedWidth />{feature?.promo_desc}
                            </li>
                          </ul>
                        ) : 'N/A'}
                      </li>
                      <li className="mb-2">
                        <span className='font-weight-bold'>New Plan Loyalty Program: </span>
                        {offer.plan_loyalty_programs?.length > 0 ? offer.plan_loyalty_programs.map((feature, key) =>
                          <ul key={key} style={{ marginBottom: "auto" }} className="list-unstyled">
                            <li>
                              <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faTreasureChest} fixedWidth />{feature?.loyalty_program_desc}
                            </li>
                          </ul>
                        ) : 'N/A'}
                      </li>
                    </ul>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={{ span: 3 }} className="mx-auto pt-3">
                    <Form.Group>
                      <Form.Label className="font-weight-bold card-title">Choose Verification Method</Form.Label>
                      <Form.Select className="mb-3" onChange={e => setTpvType(e.target.value)}>
                        <option>Choose...</option>
                        {tpvTypes && tpvTypes.map((type, key) =>
                          <option key={key} value={type}>{type}</option>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="9"></Col>
                </Row>
                <br />
                <Row>
                  <Col md={{ offset: 8 }}>
                    <Button variant="secondary" className="m-3" onClick={() => selectOffer({})}>Go Back</Button>
                    <Button variant="primary" className="m-3" onClick={next}>Continue With Offer</Button>
                  </Col>
                  <br />
                </Row>
              </div>
            }
            {showDisposition && <DispositionButton account_id={account?.account_id} winback_id={winback?.winback_id} updateSetDisposition={updateSetDisposition} />}
            {showVerification && <VerificationForm channel={channel} tpv_type={tpv_type} account={account} offer={offer} winback={winback} setShowVerification={setShowVerification} />}
            <br />
            {
              <>
                <Col md="auto">
                  {account?.account_status_code !== 'CANCELLED' &&
                    <CancelButton
                      account={account}
                      setDropReasons={setDropReasons}
                      setOffers={setOffers}
                      offers={offers}
                      reasons={reasons}
                      setConfirmationCode={setConfirmationCode}
                    />
                  }
                </Col>
                {!showCaseForm && !reasons &&
                  <Col md="auto">
                    <CreateCaseButton showCaseForm={showCaseForm} setShowCaseForm={setShowCaseForm} />
                  </Col>
                }
              </>
            }
          </Row>
      }
    </Container>
  )
};

export default Offers;
