import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useSearchParams } from "react-router-dom"
import { searchProspects } from "../apis/prospects"
import { searchAccounts } from "../apis/accounts"
import ReactTable from '../components/ReactTable'
import { Container, InputGroup, Form, Button, Spinner, ToggleButton, ToggleButtonGroup, Card, Row, Col } from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'

const Search = () => {
  const[searchParams, setSearchParams] = useSearchParams()
  const[query, setQuery] = useState('')
  const[loadingMembers, setLoading] = useState(false)
  const[prospects, setProspects] = useState([])
  const[accounts, setAccounts] = useState([])
  const[toggle, setToggle] = useState('showAll')
  const[message, setMessage] = useState('')
  const navigate = useNavigate()
  let params = searchParams.get('q')

  useEffect(() => {
    setQuery(params)
    handleSearch(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const handleSearch = async (params) => {
    setLoading(true)
    if(params?.length >= 3){
      setSearchParams(`q=${params}`)
      const prospectResponse = await searchProspects(params.toLowerCase(), setProspects)
      const accountResponse = await searchAccounts(params.toLowerCase(), setAccounts)
      if (prospectResponse && accountResponse) {
        setMessage(`${prospectResponse.total_count} prospects and ${accountResponse.total_count} accounts found for the query "${params}"`)
      }
    }
    setLoading(false)
  }

  const rowOnClick = (e) => {
    if (e.type === 'prospect'){
      window.open(`/prospect/${e.prospect_guid}`, '_blank', 'noopener,noreferrer')
    }
    if (e.type === 'account'){
      window.open(`/profiles/${e.membership_profile_id}/accounts/${e.account_id}`, '_blank', 'noopener,noreferrer')
    }
  }

  const setTableData = () => {
    switch (toggle) {
      case 'showAccounts':
        return accounts
      case 'showProspects':
        return prospects
      default:
        return accounts.concat(prospects)
    }
  }

  const handleToggle = (e) => {
    setToggle(e)
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      handleSearch(query)
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: 400,
      minWidth: 150,
      width: 250
    }, { Header: 'Type',
      accessor: members => members.type[0].toUpperCase() + members.type.slice(1),
      maxWidth: 200,
      minWidth: 100,
      width: 100
    }, {
      Header: 'Status',
      accessor: 'status',
      maxWidth: 400,
      minWidth: 150,
      width: 200
    }, {
      Header: 'Primary Phone',
      accessor: 'primary_phone',
      Cell: (e) => (
        <div className="row justify-content-md-center mt-2">
        {e.value ? <p> ({e.value.substring(0,3)}) {e.value.substring(3,6)}-{e.value.substring(6,11)} </p> : <p>None Available</p> }
        </div>
      ),
      maxWidth: 400,
      minWidth: 150,
      width: 200
    }, {
      Header: 'Service Address',
      accessor: 'service_address',
      maxWidth: 400,
      minWidth: 150,
      width: 380
    }, {
      Header: 'Account Id',
      accessor: 'account_id',
      maxWidth: 400,
      minWidth: 150,
      width: 200
    }, {
      Header: 'Account Identifier',
      accessor: 'account_number',
      maxWidth: 400,
      minWidth: 150,
      width: 250
    },
  ]

  return (
    <>
      <Helmet title="Member Search" />
      <Container fluid className="p-0">
        <Row className="mb-3">
          <Col xs={10}><h1 className="h3 mb-3">Member Search</h1></Col>
          <Col>
            <Button variant="primary" onClick={() => navigate('/order')}>Create New Prospect</Button>
          </Col>
        </Row>
        <InputGroup className="mb-3 input-group-lg">
          <Form.Control
            type="text"
            placeholder="Type in a Name, Address, Phone, Email, Member #, Utility #, Business, Referrer Guid, Confirmation Code, Shopper short key, etc."
            aria-label="Member Search"
            value={query || ''}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          <Button variant="primary" onClick={() => handleSearch(query)}>
          { loadingMembers ?
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              <span>  Searching...</span>
            </>
          :
            <span>Search</span>
          }
          </Button>
        </InputGroup>

        <p className="mb-3 mt-3">{message}</p>

        {(!isEmpty(prospects) || !isEmpty(accounts)) &&
          <div>
            <ToggleButtonGroup className="mb-3" type="radio" name="radio" value={toggle} onChange={(e) => handleToggle(e)}>
              <ToggleButton id="tbg-btn-1" name="radio" value={'showAll'}>
                All
              </ToggleButton>
              <ToggleButton id="tbg-btn-2" name="radio" value={'showAccounts'}>
                Accounts
              </ToggleButton>
              <ToggleButton id="tbg-btn-3" name="radio" value={'showProspects'}>
                Prospects
              </ToggleButton>
            </ToggleButtonGroup>
            <Card>
              <ReactTable
                columns={tableColumns}
                data={setTableData()}
                handleClick={rowOnClick}
                canHover={true}
              />
            </Card>
          </div>
        }
      </Container>
    </>
  )
}

export default Search;
