import React from 'react'
import { Card } from 'react-bootstrap'

const OfferCardBody = ({ offer }) => {
  const {
    contract_type_code,
    intro_period_friendly,
    marketing_highlights,
    offer_code,
    plan_description,
    rate_friendly
  } = offer
  return (
    <>
      <Card.Text className="ms-2">
        {contract_type_code.toUpperCase()}
        <br />
        {intro_period_friendly}
        <br />
        <span className="h3 py-3"><b>{rate_friendly}</b></span>
        <br />
        {plan_description}
        <br />
        {marketing_highlights.map((highlight, index) =>
          <li key={`${highlight}-${index}`}>{highlight}</li>
        )}
        <span className="pt-3">Offer Code: {offer_code}</span>
      </Card.Text>
    </>
  )
}

export default OfferCardBody
