import {
  faFolderOpen,
  faUser,
  faCartShopping,
  faEnvelope,
  faCommentSms,
  faEnvelopeCircleCheck,
  faDollarSign,
  faBriefcaseBlank,
  faGlobe,
  faPencil,
  faPhoneArrowUpRight,
  faPhoneArrowDownLeft,
  faShare,
  faExclamationTriangle,
  faStopCircle,
  faUserPlus,
  faBullhorn,
  faCirclePlus,
  faGift,
  faCalendarDay,
  faCalendarCircleMinus,
  faCalendarCirclePlus,
  faCalendarDays,
  faCalendarCheck,
  faCalendarExclamation,
  faExclamationCircle
} from '@fortawesome/pro-light-svg-icons'

const NotificationIcons = (icon) => {
  switch(icon) {
    case 'fa-folder-open-o':
      return faFolderOpen
    case 'fa-exclamation':
      return faExclamationCircle
    case 'fa-user':
      return faUser
    case 'fa-shopping-cart':
      return faCartShopping
    case 'fa-globe':
      return faGlobe
    case 'fa-pencil':
      return faPencil
    case 'fa-phone':
      return faPhoneArrowUpRight
    case 'fa-phone-fa-flip-horizontal':
      return faPhoneArrowDownLeft
    case 'fa-share':
      return faShare
    case 'fa-exclamation-triangle':
      return faExclamationTriangle
    case 'fa-stop-circle':
      return faStopCircle
    case 'fa-user-plus':
      return faUserPlus
    case 'fa-bullhorn':
      return faBullhorn
    case 'fa-mobile':
      return faCommentSms
    case 'fa-suitcase':
      return faBriefcaseBlank
    case 'fa-usd':
      return faDollarSign
    case 'fa-envelope':
      return faEnvelope
    case 'fa-envelope-o':
      return faEnvelopeCircleCheck
    case 'fa-plus-circle':
      return faCirclePlus
    case 'fa-gift':
      return faGift
    case 'fal fa-phone':
      return faCalendarDay
    case 'fal fa-calendar-minus':
      return faCalendarCircleMinus
    case 'fal fa-calendar-plus':
      return faCalendarCirclePlus
    case 'fal fa-calendar-times':
      return faCalendarDays
    case 'fal fa-calendar-check':
      return faCalendarCheck
    case 'fal fa-calendar-exclamation':
      return faCalendarExclamation
    default:
      return faBriefcaseBlank
  }
}

export default NotificationIcons
