import React from "react";
import { FileText } from "react-feather";
import { Card, Col, Badge, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faBadgeCheck } from "@fortawesome/pro-light-svg-icons";

const EnrollmentDocs = ({ order }) => {
  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">Enrollment Documents</h3>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <FileText className="align-middle text-success" />                  </div>
                </div>
              </div>
            <div className="mb-0">
              <ul className="list-unstyled">
                <li className="mb-1">
                  <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faFileContract} fixedWidth/>
                  {order.contract_url ?
                    <Badge bg="salmonLight" text="black" className="me-2">
                      <a href={order.contract_url} target="_blank" rel="noreferrer" >Order Contract</a>
                    </Badge>
                  :
                    <Badge bg="" className="badge-soft-success me-2"> No Contract Available</Badge>
                  }
                </li>
                <li className="mb-1">
                  <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faBadgeCheck} fixedWidth/>
                  {order.verification_letter_loc ?
                    <Badge bg="salmonLight" text="black" className="me-2">
                      <a href={order.verification_letter_loc} target="_blank" rel="noreferrer" >Verification Letter</a>
                    </Badge>
                  :
                    <Badge bg="" className="badge-soft-success me-2"> No Verification Letter Available</Badge>
                  }
                </li>
              </ul>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default EnrollmentDocs
