import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { getConversationItems } from '../../../apis/conversations'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'

const ConversationModal = ({ conversationId, show, handleClose }) => {
  const [conversationItems, setConversationItems] = useState([])

  useEffect(() => {
    getConversationItems(conversationId, setConversationItems)
  }, [conversationId, show])

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Gladly Conversation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { conversationItems.map(conversationItem => (
            <div key={uuidv4()}>
              <Row>
                <Col md={3} className="text-center">
                  <h4 className="mb-3">{conversationItem.type} from:</h4>
                  <h5 className="mb-3">{conversationItem.initiator}</h5>
                  <h5> {DateTime.fromISO(conversationItem.updated_at).toLocaleString(DateTime.DATETIME_MED)} </h5>
                </Col>
              <Col md={9}>
                <h4>{conversationItem.content.type}</h4>
                <p>{conversationItem.message_content}</p>
              </Col>
              </Row>
              <hr />
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button className="col-12" aria-label="Close modal" variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConversationModal
