import React, { useState, useEffect } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import AccountInfoCard from '../../components/cases/case/AccountInfoCard'
import CaseHighlightsCard from '../../components/cases/case/CaseHighlightsCard'
import CaseDetailsContainer from '../../components/cases/CaseDetailsContainer'
import CaseTransition from '../../components/cases/case/CaseTransition'
import FollowUpDate from '../../components/cases/case/FollowUpDate'
import { getCase } from '../../apis/case'

const Case = ({ caseGuid }) => {
  const [loadCase, setLoadCase] = useState(true)
  const [caseDetails, setCase] = useState({})

  const reloadCase = () => {
    setLoadCase(true)
  }

  useEffect(() => {
    if(loadCase){
      getCase(caseGuid, setCase)
      setLoadCase(false)
    }
  }, [caseGuid, loadCase])

  return (
    <>
      <Card>
          <Row className="mt-3 mx-2">
            <Col>
              <h1>Case Information</h1>
            </Col>
            <Col className="d-flex">
              <div className="ms-auto">
                { caseDetails?.case_guid &&
                  <CaseTransition caseDetails={caseDetails} reloadCase={reloadCase} positionTop={true}/>
                }
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <FollowUpDate caseDetails={caseDetails} reloadCase={reloadCase}/>
          </Row>
        <Row>
          <Col md={6} className="d-flex">
            <AccountInfoCard caseDetails={caseDetails} reloadCase={reloadCase}/>
          </Col>
          <Col md={6} className="d-flex">
            <CaseHighlightsCard caseDetails={caseDetails} />
          </Col>
        </Row>
        { caseDetails?.case_guid &&
          <CaseDetailsContainer caseDetails={caseDetails} reloadCase={reloadCase} key={caseDetails.case_guid} />
        }
      </Card>
    </>
  )
}

export default Case
