import axios from 'axios'
import { DateTime } from 'luxon'

export const getCases = async (params, setCases, setLoading, caseReload) => {
  const { caseStatus, caseType, userId, fromDate, customStartDate, customEndDate } = params
  if (!caseReload) { setLoading(true) }
  const customDateQuery = customStartDate && customEndDate
  ? `&custom_date_from=${DateTime.fromFormat(customStartDate, 'yyyy-MM-dd').toISO()}&custom_date_to=${DateTime.fromFormat(customEndDate, 'yyyy-MM-dd').toISO()}`
  : ''
  const caseQueryString = `case_type=${caseType.value}&user_id=${userId}&from_date=${JSON.stringify({ date: fromDate })}${customDateQuery}`
  let queryCaseStatus = ''
  for (let i = 0; i < caseStatus.length; i++) {
    queryCaseStatus += `show_${caseStatus[i].value.toLowerCase()}=true&`
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/list?filterObj={}&page=1&per_page=100&${queryCaseStatus}${caseQueryString}`)
    setLoading(false)
    setCases(response.data.data)
  }
  catch (error) {
    throw error
  }
}

export const getUserCases = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/cases/user_cases`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getAllCaseTypeCategories = async (callback, departmentCode) => {
  try{
    const departmentParams = departmentCode ? `&department_code=${departmentCode}` : ''
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/types?user_creatable=true${departmentParams}`)
    callback(response.data)
  }
  catch (error) {
    throw error
  }
}

export const getCaseInteractionChannels = async (callback) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/case_interaction_channel_codes`)
    callback(response.data)
  }
  catch (error) {
    throw error
  }
}

export const getCaseChannels = async (callback) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/channels`)
    callback(response.data)
  }
  catch (error) {
    throw error
  }
}

export const getCaseTypeAttributes = async (caseTypeCategoryId, callback) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/attributes/${caseTypeCategoryId}`)
    return response.data
  }
  catch (error) {
    throw error
  }
}

export const getCaseCloseReasons = async (caseGuid, callback) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/events/CLOSE/reasons?case_guid=${caseGuid}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getProspectCases = async (prospectGuid, accountId, callback, handleAlert) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/prospects/${prospectGuid}?account_id=${accountId}`)
    callback(response.data)
  } catch(error) {
    handleAlert(`Unable to get cases for prospect with guid ${prospectGuid}" ${error}`, false)
    throw error
  }
}
