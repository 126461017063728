import React, { useState } from 'react'
import { Button, Form, Col } from "react-bootstrap"
import { acceptWinbackDisposition } from '../../../apis/offers'
import useUser from "../../../hooks/useUser"
import useToast from '../../../hooks/useToast'

const winbackStatuses = [{
    "winbackStatusId": "DIALED",
    "displayName": "Dialed - No answer/Left message"
  },
  {
    "winbackStatusId": "CALLBACK_LATER",
    "displayName": "Answered - Callback later"
  },
  {
    "winbackStatusId": "APPOINTMENT_SCHEDULED",
    "displayName": "Appointment Scheduled"
  },
  {
    "winbackStatusId": "DECLINED",
    "displayName": "Declined"
  },
  {
    "winbackStatusId": "DEAD_LEAD",
    "displayName": "Dead Lead"
  }
];

const winbackDispositions = [
  {
      "id": "Spoke with decision maker; callback later",
      "displayName": "Spoke with decision maker; callback later",
      "winbackStatusId": "APPOINTMENT_SCHEDULED"
  },
  {
      "id": "Spoke with decision maker; appointment scheduled",
      "displayName": "Spoke with decision maker; appointment scheduled",
      "winbackStatusId": "APPOINTMENT_SCHEDULED"
  },
  {
      "id": "Spoke with non-decision maker; callback later",
      "displayName": "Spoke with non-decision maker; callback later",
      "winbackStatusId": "CALLBACK_LATER"
  },
  {
      "id": "Left Voicemail",
      "displayName": "Left Voicemail",
      "winbackStatusId": "DIALED"
  },
  {
      "id": "No Voicemail",
      "displayName": "No Voicemail",
      "winbackStatusId": "DIALED"
  },
  {
      "id": "Not reachable",
      "displayName": "Not reachable",
      "winbackStatusId": "DEAD_LEAD"
  },
  {
      "id": "Does not qualify",
      "displayName": "Does not qualify (e.g. Out of service area)",
      "winbackStatusId": "DEAD_LEAD"
  },
  {
      "id": "Not qualifed (CAP, etc)",
      "displayName": "Not qualifed (CAP, etc)",
      "winbackStatusId": "DEAD_LEAD"
  },
  {
      "id": "Do Not Call",
      "displayName": "Do Not Call",
      "winbackStatusId": "DEAD_LEAD"
  },
  {
      "id": "Account Already Active",
      "displayName": "Account Already Active",
      "winbackStatusId": "DEAD_LEAD"
  },
  {
      "id": "Declined",
      "displayName": "Declined",
      "winbackStatusId": "DECLINED"
  }
];

const DispositionButton = ({ updateSetDisposition, account_id, winback_id }) => {
  const { handleAlert } = useToast()
  const [status, setStatus] = useState(null)
  const [disposition, setDisposition] = useState(null)
  const { userInfo } = useUser()

  const createDisposition = () => {
    const params = {
      account_id: account_id,
      winback_id: winback_id,
      audit: {
        user_name: userInfo.email,
        user_id: userInfo.user_id
      },
      notes: null,
      reactivation_channel_code: "INSIDE_SALES",
      winback_disposition: disposition,
      winback_followup_dt: "",
      winback_rep: userInfo.email,
      winback_status: status
    }
    acceptWinbackDisposition(params, handleAlert)
  }

  return (
    <Col sm={6}>
      <Form.Group>
        <Form.Label>Status</Form.Label>
        <Form.Select className="mb-3" onChange={e => setStatus(e.target.value)}>
          <option>Choose...</option>
          { winbackStatuses.map(status =>
            <option key={status.winbackStatusId} value={status.winbackStatusId}>{status.displayName}</option>
          )}
        </Form.Select>
        { status &&
          <Form.Select className="mb-3" onChange={e => setDisposition(e.target.value)}>
            <option>Choose...</option>
            { winbackDispositions.map(dispo => {
                if(dispo.winbackStatusId === status){
                  return <option key={dispo.id} value={dispo.id}>{dispo.displayName}</option>
                } else {
                  return null
                }
              }
            )}
          </Form.Select>
        }
      </Form.Group>
      <Button
        variant="primary"
        onClick={createDisposition}>
          Save
      </Button>
      <Button variant="link" onClick={() => updateSetDisposition(false)}>Back</Button>
    </Col>
  )
};

export default DispositionButton;
