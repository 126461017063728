import React from 'react'
import { Col, Form, Button } from 'react-bootstrap'
import {DateTime} from 'luxon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import AutoComplete from './AutoComplete'
import { faEnvelope } from "@fortawesome/pro-light-svg-icons"

const CaseNotes = ({caseDetails, caseNote, setCaseNote, handleSaveCaseNote}) => {

  const displayCaseNotes = () => {
    if(!isEmpty(caseDetails.notes)){
      return caseDetails.notes?.map((note, index) => {
        return (
          <div key={index} className="alert alert-info d-flex justify-content-between">
            <p className='m-2'>
              {note.requestor_notified &&
               <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faEnvelope} fixedWidth/>
              }
              <b>{note.first_name} {note.last_name}: </b> {note.note}
            </p>
            <p className='m-2'>{DateTime.fromISO(note.note_dt).toLocaleString(DateTime.DATETIME_MED)}</p>
          </div>
        )
      })
    } else {
      return (<p>No notes for case</p>)
    }
  }

  return (
    <Col>
      {displayCaseNotes()}
      <Form>
        <AutoComplete data-testid="case_note_input" name="case_note" rows={2} onChange={(e) => setCaseNote(e.target.value)} value={caseNote}/>
        <Button onClick={() => handleSaveCaseNote()}>Save Note</Button>
      </Form>
    </Col>
  )
}

export default CaseNotes
