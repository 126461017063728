import React, { useEffect, useState } from 'react'
import { Button, Card, CardGroup, Col, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getShopper, savePartial } from '../../apis/shoppers'
import { getOffers } from '../../apis/offers'
import OfferCardBody from './OfferCardBody'
import useToast from '../../hooks/useToast'
import useUser from '../../hooks/useUser'
import { getOffersParams } from '../../utils/getOffersParams'

const OfferCards = ({ shopper_short_key }) => {
  const [shopperData, setShopperData] = useState(null)
  const [offers, setOffers] = useState(null)
  const [requestId, setRequestId] = useState('')
  const [currentOffer, setCurrentOffer] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { handleAlert } = useToast()
  const { userInfo } = useUser()

  useEffect(() => {
    getShopper(shopper_short_key, setShopperData)
  }, [shopper_short_key])

  useEffect(() => {
    const setOfferData = (offers) => {
      setOffers(offers?.results)
      setRequestId(offers['x-request-id'])
    }
    if (shopperData && !offers) {
      const params = getOffersParams(userInfo, shopperData[0])
      getOffers(params, setOfferData, handleAlert)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [handleAlert, shopperData, offers, userInfo])

  const selectOffer = (offer) => {
    setCurrentOffer(offer.offer_code)
    setLoading(true)

    let updatedShopper = shopperData[0]
    updatedShopper = { ...updatedShopper, offer_code: offer.offer_code }
    if (offer.product_type_code === 'Electric') {
      updatedShopper = { ...updatedShopper, enrollment_type: 'ELECTRIC' }
    } else if (offer.product_type_code === 'NaturalGas') {
      updatedShopper = { ...updatedShopper, enrollment_type: 'GAS' }
    }

    savePartial(updatedShopper, handleAlert)
      .then(() => navigate(`/order/${shopper_short_key}/join`))
      .catch(e => {
        setCurrentOffer(null)
        setLoading(false)
      })
  }

  return (
    <div>
      <h4>Request ID: {requestId}</h4>
      <Row sm={1} md={2} lg={3}>
        {offers && offers.length ? offers.map((offer, index) => {
          const { offer_code, plan_name, plan_image_url } = offer
          return (

            <CardGroup key={`${plan_name}-${index}`} className="my-3">
              <Card data-testid="card">
                <Card.Img alt={plan_name} variant="top" src={plan_image_url} />
                <Card.Header>
                  <Card.Title><h3>{plan_name}</h3></Card.Title>
                </Card.Header>
                <Card.Body>
                  <OfferCardBody offer={offer} />
                  <span className="d-flex justify-content-end">
                    {
                      loading && currentOffer === offer_code ?
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            data-testid={`spinner-${offer_code}`}
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                          <span>Selecting offer...</span>
                        </>
                        :
                        <Button
                          data-testid={`button-${offer_code}`}
                          onClick={() => selectOffer(offer)}
                          disabled={currentOffer && currentOffer !== offer_code}
                          size="lg"
                          text="Select"
                          variant="primary">
                          Select
                        </Button>
                    }
                  </span>
                </Card.Body>
              </Card>
            </CardGroup>

          )
        })
          : null
        }

        {offers && offers.length === 0 ?
          <Col>
            <p>No offers available.</p>
          </Col>
          : null
        }

        {loading && !offers ?
          <Col>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
          : null
        }
      </Row>
    </div>
  )
}

export default OfferCards
