/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { getMemberNotifications } from '../../../apis/prospects'
import MemberNotification from './MemberNotification'
import MemberEventsContainer from '../memberEvents/MemberEventsContainer'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
import AccountTransactions from '../../account/accountTransactions/AccountTransactions'
import PossibleTransactions from '../../account/accountTransactions/PossibleTransactions'
import { getAccountTransactions, getPossibleTransactions } from '../../../apis/accounts'
import useToast from '../../../hooks/useToast'
import useUser from '../../../hooks/useUser'

const MemberNotificationsContainer = ({ member }) => {
  const[memberNotifications, setMemberNotifications] = useState([])
  const [loadTransactions, setReloadTransactions] = useState(true)
  const [acctTransactions, setAcctTransactions] = useState([])
  const [possTransactions, setPossTransactions] = useState([])
  const { userInfo } = useUser()
  const userAuthorizedToViewTransactions = userInfo.roles?.map(role => role.name).includes('Admin') || userInfo.roles?.map(role => role.name).includes('Operations')

  const { handleAlert } = useToast()

  useEffect(() => {
    getMemberNotifications(member, setMemberNotifications)
  }, [member])

  useEffect(() => {
    if (loadTransactions) {
      setReloadTransactions(false)
      if (userAuthorizedToViewTransactions) {
        getAccountTransactions(member.account_id, setAcctTransactions, handleAlert)
        getPossibleTransactions(member.account_id, setPossTransactions, handleAlert)
      }
    }
  }, [member, loadTransactions])

  const resetTransactions = () => {
    setReloadTransactions(true)
  }

  return (
  <Card>
    <Card.Body>
      <Card.Title tag="h3">
        Member Notifications
      </Card.Title>
      <hr />
      {!isEmpty(memberNotifications) ?
        memberNotifications.map(notification => (
          <MemberNotification key={uuidv4()} notification={notification} isCase={false} member={member} />
        ))
      : <b>No notifications for member</b>
      }
      <br />
        <MemberEventsContainer member={member} />
      <br />
        <AccountTransactions acctTransactions={acctTransactions} />
      <br />
        <PossibleTransactions member={member} resetTransactions={resetTransactions} possTransactions={possTransactions} />
      </Card.Body>
    </Card>
  )
}

export default MemberNotificationsContainer
