import React from 'react'
import ReactTableExpanding from '../../../components/ReactTableExpanding'
import * as DOMPurify from 'dompurify'
import { PlusCircle, MinusCircle } from 'react-feather'
import { DateTime } from 'luxon'

const RewardsTable = ({ rewards, rowOnClick }) => {

  const CurrencyToIntRegex = /^\$|\.\d*$/g

  const parseTitleAndDescription = (props) => {
    return (<><p>{props.row.original.title}</p><b>{props.row.original.description}</b></>)
  }

  const parseTitleAndCap = (props) => {
    return (<><p>{props.row.original.title}</p><b>{props.row.original.description}</b></>)
  }

  const determineSettledSummaryValue = (props) => {
    if (props.value && valueIsNotZero(props.value)) {
      return (
        <div className="reward-summary-settled-cell pb-5 ps-1 pe-3">
          {props.value}
        </div>
      )
    }
  }

  const valueIsNotZero = (amount) => {
    return amount.replaceAll(CurrencyToIntRegex, '') !== '0'
  }

  const statusTitle = (title) => {
    switch (title) {
      case 'Settled':
      case 'Fulfilling':
      case 'Pending Redemption':
        return "reward-cell-pending-fullfilling-settled-cell-with-value pb-3 ps-1 pe-3"
      case 'Projected':
      case 'Accrued':
        return "reward-cell-accrued-projected pb-3 ps-1 pe-3"
      default:
        return "pb-3 ps-1 pe-3"
    }
  }

  const parseStatus = (data) => {
    // cells with color background are dependent on the status and the value not being zero
    if (data?.date && valueIsNotZero(data.total)) {
        return (
          <div className={statusTitle(data.title)}>
            <p className="my-1">{data.total}</p>{DateTime.fromISO(data.date).toLocaleString(DateTime.DATE_MED)}
          </div>
        )
    } else if (data?.date) {
      return (
        <div className="pb-3 ps-1 pe-3">
          <p className="my-1">{data.total}</p>{DateTime.fromISO(data.date).toLocaleString(DateTime.DATE_MED)}
        </div>
      )
    } else {
      return (
         <div className={valueIsNotZero(data.total) ? statusTitle(data.title) : "pb-3 ps-1 pe-3"}>
          <p className="my-1">{data.total}</p>{data.message}
        </div>
      )
    }
  }

  const getStatusObject = (rewardObject, statusTitle) => {
    return rewardObject.statuses.find(status => status.title === statusTitle)
  }

  const fulfillmentVendorMapping = (reward) => {
    return reward.raw.reduce((previousValue, rawReward) => {
      previousValue.push({
        rewardId: rawReward.reward_id,
        vendorCode: rawReward.fulfillment_vendor_code
      })

      return previousValue
    }, [])
  }

  const formatRewardsData = () => {
    return rewards.map(reward => {
      let rewardVendors = fulfillmentVendorMapping(reward)
      return {
        title: reward.summary.title,
        max_reward_amount: '',
        status_summary: reward.summary.status_summary,
        vendor: '',
        accrued: getStatusObject(reward.summary, 'Accrued'),
        projected: getStatusObject(reward.summary,'Projected'),
        pendingRedemption: getStatusObject(reward.summary, 'Pending Redemption'),
        inProcess: getStatusObject(reward.summary, 'In Process'),
        settled: getStatusObject(reward.summary, 'Settled'),
        subRows: reward.rewards.map(subReward => {
          return {
          ...subReward,
          vendor: rewardVendors.find(vendor => vendor.rewardId === subReward.reward_id).vendorCode,
          rewardAmount: subReward.reward_amount,
          accrued: getStatusObject(subReward, 'Accrued'),
          projected: getStatusObject(subReward, 'Projected'),
          pendingRedemption: getStatusObject(subReward, 'Pending Redemption'),
          fulilling: getStatusObject(subReward, 'Fulfilling'),
          settled: getStatusObject(subReward, 'Settled'),
          }
        })
      }
    })
  }

  const tableColumns = [
    {
      // Build expander column
      id: "expander", // must have an ID
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? (
            <MinusCircle className="feather" />
          ) : (
            <PlusCircle className="feather" />
          )}
        </span>
      ),
      Cell: ({ row }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 3}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <MinusCircle className="feather" />
            ) : (
              <PlusCircle className="feather" />
            )}
          </span>
        ) : null,
        maxWidth: 100,
        minWidth: 40,
        width: 40
    },{
      Header: "Title",
      accessor: "title",
      Cell: (props) => (
        <>
        {props.row.canExpand ? parseTitleAndCap(props) : parseTitleAndDescription(props)}
        </>
      )
    },{
      Header: "Reward Cap",
      accessor: "max_reward_amount",
      Cell: (props) => (
        <>
        {props.row.canExpand ? '' : props.value ? props.value : 'N/A'}
        </>
      ),
      width: 80
    },
    {
      Header: "Status Summary",
      accessor: "status_summary",
      Cell: (props) => (
        <>
        {props.value ? <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.value)}}></p> : 'N/A'}
        </>
      ),
      width: 220
    },
     {
      Header: "Accrued",
      accessor: "accrued.total",
      Cell: (props) => (
        <>
        {props.row.canExpand ? props.value :  parseStatus(props.row.original.accrued)}
        </>
      )
    }, {
      Header: "Projected",
      accessor: "projected.total",
      Cell: (props) => (
        <>
        {props.row.canExpand ? props.value : parseStatus(props.row.original.projected)}
        </>
      )
    }, {
      Header: "Pending Redemption",
      accessor: "pendingRedemption.total",
      Cell: (props) => (
        <>
        {props.row.canExpand ? props.value : parseStatus(props.row.original.pendingRedemption)}
        </>
      )
    }, {
      Header: "In Process",
      accessor: "inProcess.total",
      Cell: (props) => {
        return (
        <>
        {props.row.canExpand ? props.value : parseStatus(props.row.original.fulilling)}
        </>
        )
      }
    }, {
      Header: "Settled",
      accessor: "settled.total",
      Cell: (props) => {
        return (
        props.row.canExpand ? determineSettledSummaryValue(props) : parseStatus(props.row.original.settled)
      )}
    }, {
      Header: "Vendor",
      accessor: "vendor",
      Cell: (props) => (
        <>
        {props.value ? props.value : ''}
        </>
      ),
      width: 100
    },
  ]

  return (
    <div className="mt-2">
      <ReactTableExpanding
      title="Rewards Table"
      columns={tableColumns}
      data={formatRewardsData()}
      handleClick={rowOnClick}
      canHover={true}
      />
    </div>
  )
}

export default RewardsTable
