/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Form } from 'react-bootstrap'
import Select from 'react-select'
import { getOrdersList } from '../../../apis/orders'
import { getCaseInteractionChannels, getCaseChannels } from '../../../apis/cases'
import { searchSalesAgents } from '../../../apis/agents'
import { DateTime } from 'luxon'

const CreateCaseFormSalesOps = ({member, department, setCaseForm, caseForm}) => {
  const [orders, setOrders] = useState([])
  const [associatedOrder, setAssociatedOrder] = useState({})
  const [agents, setAgents] = useState([])
  const [selectedSalesAgents, setSelectedSalesAgents] = useState([])
  const [interactionChannels, setInteractionChannels] = useState([])
  const [selectedCaseChannel, setSelectedCaseChannel] = useState({value: 'PHONE', label: 'Member Support (Phone)'})
  const [selectedInteractionChannel, setSelectedInteractionChannel] = useState({label: member.channel_code, value: member.channel_code})
  const [allCaseChannels, setAllCaseChannels] = useState([])

  const agentFullName = (agent) => (`${agent.first_name || ''} ${agent.last_name || ''}`)

  const agentOptions = (salesAgents) => {
    return salesAgents?.map(agent => ({label:`${agentFullName(agent)} ${agent.agent_id}`, value: agent.agent_id}))
  }

  useEffect(() => {
    if (department.value === 'SALES' && member.account_id) getOrdersList(member.account_id, setOrders)
    setCaseForm({...caseForm, case_channel_code: 'PHONE', interaction_channel: member.channel_code})
    getCaseInteractionChannels(setInteractionChannels)
    getCaseChannels(setAllCaseChannels)
  }, [department, member.account_id])

  const orderOptions = () => {
    return orders.map(order => {
      return {
        label: `Order ID: ${order.order_id} sold on ${DateTime.fromISO(order.sale_dt).toLocaleString(DateTime.DATE_MED)}`,
        value: order.order_id
      }
    })
  }

  const handleSelectOrder = (e) => {
    setAssociatedOrder(e)
    setCaseForm(caseForm => ({...caseForm, order_id: e.value}))
    const selectedOrder = orders.find(order => order.order_id === e.value)
    if(selectedOrder.sales_agent){
      selectedSalesAgents.length = 0
      selectedSalesAgents.push({
        label: `${agentFullName(selectedOrder.sales_agent)} ${selectedOrder.sales_agent.agent_id}`,
        value: selectedOrder.sales_agent.agent_id
      })
      setCaseForm(caseForm => ({...caseForm, sales_agents: [selectedOrder.sales_agent.agent_id]}))
    }
    setSelectedInteractionChannel({label: selectedOrder.channel_code, value: selectedOrder.channel_code})
    setCaseForm(caseForm => ({...caseForm, interaction_channel: selectedOrder.channel_code}))
  }

  const handleUpdateSalesAgents = (agents) => {
    setSelectedSalesAgents(agents)
    setCaseForm(caseForm => ({...caseForm, sales_agents: agents.map(agent => agent.value)}))
  }

  const handleAgentInputChange = (input) => {
    searchSalesAgents(input, setAgents)
  }

  const handleUpdateInteractionChannel = (interactionChannel) => {
    setSelectedInteractionChannel(interactionChannel)
    setCaseForm(caseForm => ({...caseForm, interaction_channel: interactionChannel.value}))
  }

  const handleUpdateCaseChannel = (caseChannel) => {
    setSelectedCaseChannel(caseChannel)
    setCaseForm(caseForm => ({...caseForm, case_channel_code: caseChannel.value}))
  }

  return(
    <>
      {department.value === 'SALES' &&
        <>
          <Row className="mt-3">
          <Form.Group controlId="order-select">
              <Form.Label>Associated Order:</Form.Label>
              <Select
                className="react-select-order"
                aria-label="Associated Order"
                size="sm"
                options={orderOptions()}
                onChange={(e) => handleSelectOrder(e)}
                value={associatedOrder}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#B0DBE9',
                    primary: '#0E7C9E',
                  },
                })}
              />
            </Form.Group>
          </Row>
          <Row className="mt-3">
          <Form.Group controlId="agent-select">
              <Form.Label>Sales Agent(s):</Form.Label>
              <Select
                className="react-select-sales-agents"
                aria-label="Sales Agents"
                data-testid="case-sales-agents-select"
                size="sm"
                isMulti
                placeholder="Search for sales agents using first or last name"
                options={agentOptions(agents)}
                value={selectedSalesAgents}
                onInputChange={(e) => handleAgentInputChange(e)}
                onChange={(e) => handleUpdateSalesAgents(e)}
              />
            </Form.Group>
          </Row>
        </>
      }
      <>
        <Row className="mt-3">
          <Form.Group controlId="interaction-channel-select">
            <Form.Label>Interaction Channel:</Form.Label>
            <Select
              className="react-select-interaction-channel"
              aria-label="Interaction Channel Code"
              data-testid="case-interaction-channel-select"
              size="sm"
              options={interactionChannels.map(channel => ({
                label: channel.channel_code,
                value: channel.channel_code
              }))}
              onChange={(e) => handleUpdateInteractionChannel(e)}
              value={selectedInteractionChannel}
            />
          </Form.Group>
        </Row>
        <Row className="mt-3">
          <Form.Group controlId="interaction-channel-select">
            <Form.Label>Case Channel:</Form.Label>
            <Select
              className="react-select-channel-code"
              aria-label="Case Channel Code"
              data-testid="case-channel-code-select"
              size="sm"
              value={selectedCaseChannel}
              options={allCaseChannels.map(channel => ({
                label: channel.case_channel_name,
                value: channel.case_channel_code
              }))}
              onChange={(e) => handleUpdateCaseChannel(e)}
            />
          </Form.Group>
        </Row>
      </>
    </>
  )
}

export default CreateCaseFormSalesOps
