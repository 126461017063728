import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { transitionCase } from '../../../apis/caseEdits'
import { getCaseCloseReasons } from '../../../apis/cases'
import useUser from '../../../hooks/useUser'
import useToast from '../../../hooks/useToast'

const CaseTransition = ({ caseDetails, reloadCase, positionTop }) => {
  const caseGuid = caseDetails.case_guid
  const caseStatus = caseDetails.case_status_code
  const [changeStatusReason, setChangeStatusReason] = useState(null)
  const [caseCloseReasons, setCaseCloseReasons] = useState(null)
  const { userInfo } = useUser()
  const { handleAlert } = useToast()

  const workValid = caseStatus === 'TRIAGE' || caseStatus === 'NEW'
  const caseWorkingPending = caseStatus === 'WORKING' || caseStatus === 'PENDING'
  const caseClosedCancelled = caseStatus === 'CLOSED' || caseStatus === 'CANCELLED'

  useEffect(() => {
    if(caseGuid){
      getCaseCloseReasons(caseGuid, setCaseCloseReasons)
    }
  }, [caseGuid])

  useEffect(() => {
    if (caseCloseReasons?.length > 0) setChangeStatusReason(caseCloseReasons[0].case_status_change_reason_code)
  }, [caseCloseReasons])


  const submitTransitionCase = (eventCode) => {
    const payload = {
      case_status_change_reason_code: changeStatusReason || null,
      event_code: eventCode,
      user_id: userInfo.user_id
    }
    transitionCase(caseGuid, payload, handleAlert, reloadCase)
  }

  return (
    <>
      {positionTop && workValid  &&
        <Button className="btn btn-success ms-2" onClick={() => submitTransitionCase('WORK')}>Work Case</Button>
      }
      {!positionTop && caseWorkingPending && caseCloseReasons?.length > 0 &&
        <Form.Select
          className="react-select-container"
          aria-label="Case Close Reasons"
          data-testid="case-close-reasons"
          onChange={(e) => setChangeStatusReason(e.target.value)}
          >
          {caseCloseReasons.map(reason => (
            <option key={reason.case_status_change_reason_code} value={reason.case_status_change_reason_code}>{reason.case_status_change_reason_desc}</option>
          ))}
        </Form.Select>
      }
      {!positionTop && workValid &&
        <>
          <Button className="btn btn-success my-2 me-1" onClick={() => submitTransitionCase('WORK')}>Work Case</Button>
          <Button className="btn btn-success my-2 me-1" onClick={() => submitTransitionCase('CANCEL')}>Cancel Case</Button>
        </>
      }
      {!positionTop && caseWorkingPending &&
        <>
          {caseStatus === 'WORKING' &&
            <Button className="btn btn-success my-2 me-1" onClick={() => submitTransitionCase('PENDING')}>Waiting for Third Party</Button>
          }
          {caseStatus === 'PENDING' &&
            <Button className="btn btn-success my-2 me-1" onClick={() => submitTransitionCase('CANCEL_PENDING')}>Cancel waiting for Third Party</Button>
          }
          <Button className="btn btn-success my-2 me-1" disabled={!changeStatusReason} onClick={() => submitTransitionCase('CLOSE')}>Close Case</Button>
          <Button className="btn btn-success my-2 me-1" onClick={() => submitTransitionCase('CANCEL')}>Cancel Case</Button>
        </>
      }
      {!positionTop && caseClosedCancelled &&
        <Button className="btn btn-success my-2 me-1" onClick={() => submitTransitionCase('REOPEN')}>Reopen Case</Button>
      }
    </>
  )
}

export default CaseTransition
