import React, { useState } from 'react'
import { Button, Col, Form, Badge } from 'react-bootstrap'
import { updateRequestedCloseDate } from '../../../apis/caseEdits'
import { DateTime } from 'luxon'
import useToast from '../../../hooks/useToast'

const FollowUpDate = ({caseDetails, reloadCase}) => {
  const caseGuid = caseDetails.case_guid
  const [newCloseDate, setNewCloseDate] = useState('')
  const requestedCloseDate =  caseDetails.requested_close_dt ?
    DateTime.fromISO(caseDetails.requested_close_dt, { zone: "UTC"}).toFormat('yyyy-MM-dd') : ''
  const [showDatePicker, setShowDatePicker] = useState(false)
  const { handleAlert } = useToast()

  const buttonText = requestedCloseDate ? 'Change Follow Up Date' : 'Set Follow Up Date'
  const displayRequestedCloseDate = DateTime.fromISO(caseDetails.requested_close_dt, { zone: "UTC"}).toFormat('MM-dd-yyyy')

  const checkPastDue = () => {
    return caseDetails.requested_close_dt < DateTime.now().toISO() ? "danger" : "info"
  }

  const updateCloseDate =(e) => {
    e.target.value ? setNewCloseDate(DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd')) : setNewCloseDate('')
  }

  const setFollowUpDate = () => {
    const payload = {
      case_guid: caseGuid,
      requested_close_dt: newCloseDate
    }
    updateRequestedCloseDate(payload, handleAlert, reloadCase)
    setShowDatePicker(false)
  }

  return (
    <>
      <Col className="ms-4" md={4}>
        {requestedCloseDate &&
          <Badge bg={checkPastDue()} text="white" className="fs-md mb-2">
          Requested Close Date: {displayRequestedCloseDate}
          </Badge>
        }
        <br/>
        {!showDatePicker ?
          <Button
            size="med"
            className="btn btn-dark"
            onClick={() => setShowDatePicker(true)}
          >
            {buttonText}
          </Button>
        :
          <Form.Group controlId="date-picker">
            <Form.Label>Follow Up Date</Form.Label>
                <Form.Control
                  data-testid="follow-up-date"
                  name="from_dt"
                  placeholder="Follow Up Date"
                  type="date"
                  value={newCloseDate}
                  onChange={(e) => updateCloseDate(e)}
                />
            <Button className="btn btn primary" onClick={setFollowUpDate}> Confirm New Follow Up Date </Button>
            <Button className="btn btn primary ms-2" onClick={() => setShowDatePicker(false)}> Cancel </Button>
          </Form.Group>
        }
      </Col>
    </>
  )
}

export default FollowUpDate
