import axios from 'axios'

const interceptor = () => {
  let token = localStorage.getItem('idToken')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  axios.defaults.headers.common['X-Inspire-Client'] = 'crm'
  axios.defaults.headers.common['X-Heroku-App-Name'] = process.env.REACT_APP_HEROKU_APP_NAME || 'unknown-new-crm'
  axios.interceptors.response.use(response => {
    return response
  }, error => {
    if ((error.response && error.response?.status !== 200) || error.response?.status !== 201) {
      return Promise.reject(error.response?.data ?
        `${error.message}: ${error.response?.data.errors || error.response?.data}`

        : `${error.message}: Something Went Wrong, Please Try Again`
      )
    }
  })
}

export default interceptor
