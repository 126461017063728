import { faFireSmoke, faCarOn, faOilWell, faTrees } from '@fortawesome/pro-light-svg-icons'

export const parseIcon = (icon) => {
  switch(icon) {
    case 'fa-square':
      return faFireSmoke
    case 'fa-map-signs':
      return faCarOn
    case 'fa-road':
      return faOilWell
    case 'fa-tree':
      return faTrees
    default:
      return faTrees
  }
}
