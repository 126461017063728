import axios from 'axios'

export const updateCaseDescription = async (params, callback, handleAlert) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${params.case_guid}/description`, params)
    if(response){
    handleAlert(`${response.statusText}! Case description was updated`, true)
    callback(params.case_description)
    }
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const updateCaseTypeCategory = async (params, handleAlert, reloadCase) => {
  try{
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${params.case_guid}/category`, params)
    handleAlert('Case category was updated', true)
    reloadCase()
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const updateCaseAssignment = async (params, userObject, callback, handleAlert) => {
  const user_id = params.assignToSelf ? userObject.user_id : userObject.value
  try{
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${params.caseGuid}/assignment`, {user_id: user_id})
    handleAlert('Case assignment was updated!', true)
    const updatedCaseAssignment = params.assignToSelf ? userObject.email : userObject.label
    callback(updatedCaseAssignment)
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const updateCaseSalesAgents = async (caseAgents, caseGuid, callback, handleAlert) => {
  const agentIds = caseAgents.map(agent => agent.value)
  try{
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/sales_agents`, {sales_agents: agentIds})
    handleAlert(`${response.statusText}! Sales agents were updated!`, true)
    callback(caseAgents)
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const updateCaseInteractionChannel = async (params, callback, handleAlert) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${params.case_guid}/case_interaction_channel_code`, params)
    handleAlert(`${response.statusText}! Interaction channel updated!`, true)
    callback({ label: params.to_case_interaction_channel, value: params.to_case_interaction_channel })
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const addCaseChannel = async (newCaseChannelCode, caseGuid, handleAlert, reloadCase) => {
  try{
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/add_case_channel/${newCaseChannelCode.value}`)
    handleAlert('Case channels updated!', true)
    reloadCase()
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const removeCaseChannel = async (removeCaseChannelCode, caseGuid, handleAlert, reloadCase) => {
  try{
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/remove_case_channel/${removeCaseChannelCode.value}`)
    handleAlert('Case channels updated!', true)
    reloadCase()
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const saveCaseNote = async (params, callback, handleAlert, reloadCase) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${params.case_guid}/note`, params)
    if(response.status === 200) {
      handleAlert('Case notes updated!', true)
      reloadCase()
      callback('')
    }
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

 export const uploadAttachment = async (formData, caseGuid, handleAlert, newCase = false, reloadCase) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/attach`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    handleAlert('Case attachments updated!', true)
    if(!newCase){
      reloadCase()
    }
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const deleteAttachment = async (fileName, caseGuid, handleAlert, reloadCase) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/delete_attach/${fileName}`)
    handleAlert(`${response.statusText}! Case attachments updated!`, true)
    reloadCase()
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const transitionCase = async (caseGuid, params, handleAlert, reloadCase) => {
  try{
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/transition`, params)
    handleAlert('Case status changed!', true)
    reloadCase()
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const updateCaseOrder = async (payload, handleAlert, reloadCase) => {
  try{
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${payload.case_guid}/case_order`, payload)
    handleAlert(`Associated order updated!`, true)
    reloadCase()
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const updateRequestedCloseDate = async (payload, handleAlert, reloadCase) => {
  try{
    await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${payload.case_guid}/edit_close_date`, payload)
    handleAlert(`Requested Close Date Updated!`, true)
    reloadCase()
  }
  catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const updateCaseAttribute = async (caseGuid, caseAttribute, handleAlert, reloadCase) => {
  try{
    const payload = {
      case_attribute_id: caseAttribute.case_attribute_id,
      attribute_value: caseAttribute.value
    }
    const response = await axios.put(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/attributes`, payload)
    if(response.status === 200){
      handleAlert('Case Attributes Updated!', true)
      reloadCase()
    }
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}
