/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import MemberNotification from '../../components/member/memberNotifications/MemberNotification'
import { getProspectCases } from '../../apis/cases'
import CreateCaseButton from '../../components/cases/createCase/CreateCaseButton'
import CreateCaseFormDefault from '../../components/cases/createCase/CreateCaseFormDefault'
import useToast from '../../hooks/useToast'

const ProspectCases = ({ prospect, setReloadMember }) => {
  const [prospectCases, setProspectCases] = useState([])
  const [showCaseForm, setShowCaseForm] = useState(false)
  const { handleAlert } = useToast()

  useEffect(() => {
    getProspectCases(prospect.prospect_guid, null, setProspectCases, handleAlert)
  }, [prospect.prospect_guid])

  return(
    <>
      <Card className="m-3">
        <CreateCaseButton showCaseForm={showCaseForm} setShowCaseForm={setShowCaseForm} />
        <CreateCaseFormDefault showCaseForm={showCaseForm} setShowCaseForm={setShowCaseForm} member={prospect} setReloadMember={setReloadMember}/>
          <Card.Body>
          {!showCaseForm &&
            <>
              { prospectCases.map(kase => (
                <MemberNotification key={kase.case_guid} notification={kase} isCase={true}/>
              ))}
            </>
          }
          </Card.Body>
      </Card>
    </>
  )
}

export default ProspectCases;
