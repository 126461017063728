import { DateTime } from 'luxon'

export const dateTimeUTC = (date) => {
  const newJSDate = new Date(date)
  // if a date has a timestamp of 00:00:00.000 we can assume it's meant to represent the start of the
  // date given
  // when converted to a fromISO string, and local timezone, it changes the date to the previous day
  // so here, we'll specify to keep the utc time and then return the ISO-formatted date to manipulate it however we want
  if (newJSDate.getUTCHours() === 0 && newJSDate.getUTCMinutes() === 0 && newJSDate.getUTCSeconds() === 0 && newJSDate.getUTCMilliseconds() === 0) {
    const utcDate = DateTime.fromISO(date, {setZone: true})
    return utcDate
  } else {
    return DateTime.fromISO(date)
  }
}
