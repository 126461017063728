import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getProspectShoppers } from '../../apis/prospects'
import ReactTableBasic from '../ReactTableBasic'

const PartialOrders = ({ prospect }) => {
  const [prospectShoppers, setProspectShoppers] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getProspectShoppers(prospect, setProspectShoppers, setLoading)
  }, [prospect])

  const rowOnClick = (e) => {
    if(e.short_key && e.offer_code) {
      navigate(`/order/${e.short_key}/join`)
    } else if (e.short_key && !e.offer_code) {
      navigate(`/order/${e.short_key}/offers`)
    } else {
      navigate(`/order?prospect_guid=${e.prospect_guid}`)
    }
  }

  const tableColumns = [
    {
      Header: 'Sale Date',
      accessor: 'initial_sale_dt',
      width: 75,
      Cell: (e) => (
        <div>
        {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
        </div>
      )
    },
    {
      Header: 'Source Code',
      accessor: 'source_code',
    },
    {
      Header: 'Status',
      accessor: 'shopper_status',
    },
    {
      Header: "Offer Code",
      accessor: 'offer_code',
      width: 100,
    },
    {
      Header: 'Shopper',
      accessor: 'short_key',
      width: 100,
    },
  ]

  const inProcessTableColumns = [
    {
      Header: 'Order Date',
      accessor: 'order.order_dt',
      width: 75,
      Cell: (e) => (
        <div>
          {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
        </div>
      )
    },
    {
      Header: 'Source Code',
      accessor: 'source_code',
    },
    {
      Header: 'Order Status',
      id: 'order.order_status',
      accessor: prospectShoppers => `${prospectShoppers.order.order_status} - ${prospectShoppers.order.order_status_reason}`,
    },
    {
      Header: "Confirmation Code",
      accessor: 'order.confirmation_code',
      width: 100,
    },
    {
      Header: 'Shopper',
      accessor: 'short_key',
      width: 100,
    },
  ]

  return (
    <React.Fragment>
      <Container>
        { loading &&
          <div>
            <h3>Loading Partial Orders</h3>
            <Spinner
              data-testid="spinner-loading"
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              variant="primary"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
        { !isEmpty(prospectShoppers) && !loading &&
          <div>
            <h4>Processing Orders</h4>
            <div className="mt-3 mb-5">
              <ReactTableBasic
                columns={inProcessTableColumns}
                data={prospectShoppers.filter(shopper => (shopper.order !== null))}
                handleClick={rowOnClick}
                canHover={true}
              />
            </div>
            <br />
            <h4>Partial Orders</h4>
            <div className="mt-3 mb-5">
              <ReactTableBasic
                columns={tableColumns}
                data={prospectShoppers}
                handleClick={rowOnClick}
                canHover={true}
              />
            </div>
          </div>
        }
        { isEmpty(prospectShoppers) && !loading &&
          <h4>No Partial Orders for Prospect</h4>
        }
      </Container>
    </React.Fragment>
  )
}

export default PartialOrders
