import React, { useEffect, useState } from "react";
import { Music } from "react-feather";
import { Card, Col, Row, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag, faMobileRetro, faVoicemail, faStore } from "@fortawesome/pro-light-svg-icons";
import { getTPV } from "../../../apis/accounts";
import { DateTime } from "luxon"

const TPVinfo = ({ order, account }) => {
  const [tpv, setTPV] = useState([])

  useEffect(() => {
    getTPV(account.account_id, setTPV)
  }, [account.account_id, setTPV])

  const tpvURLMatch = (order) => {
    return tpv?.find(tpv => tpv.order_id === order.order_id)?.tpv_url
  }

  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          {tpv &&
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2">Third Party Verification (TPV)</h3>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat">
                    <Music className="align-middle text-success" />
                  </div>
                </div>
              </div>
              { order.tpv_confirmation_code ?
                <div className="mb-0">
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faHashtag} fixedWidth/>
                      TPV Confirmation Code:&nbsp;{order.tpv_confirmation_code}
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faMobileRetro} fixedWidth/>
                      TPV Type:&nbsp;
                      <Badge bg="salmonLight" text="black" className="me-2">
                        {order.tpv_type}
                      </Badge>
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faStore} fixedWidth/>
                      TPV Vendor Code:&nbsp;{order.tpv_vendor}
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faVoicemail} fixedWidth/>
                      TPV Receipt: {order.tpv_confirmation_code}
                      {order.tpv_url && !order.tpv_url.includes('wav' || 'mp3') ?
                        <Badge bg="salmonLight" text="black" className="me-2 ms-1" style={{whiteSpace: 'normal'}}>
                          <a href={tpvURLMatch(order)} target="_blank" rel="noreferrer">Download</a>
                        </Badge>
                      :
                        Object.keys(tpv).map((key, value) =>
                          <div key={key}>
                            <audio controls className="mt-2">
                              <source src={tpv[key].tpv_url} type="audio/wav"></source>
                            </audio>
                            <p className="ms-4">Recorded at {DateTime.fromISO(tpv[key].recorded_dt).toLocaleString(DateTime.DATETIME_FULL)}</p>
                          </div>
                        )
                      }
                    </li>
                  </ul>
                </div>
              :
                <div>
                  <h3><Badge bg="" className="badge-soft-success me-2"> No TPV Information Available</Badge></h3>
                </div>
              }
            </Card.Body>
          }
        </Card>
      </Col>
    </Row>
  )
  }

export default TPVinfo
