import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
  Badge
} from "react-bootstrap";
import { DollarSign, MapPin } from "react-feather";
import GoogleMapReact from "google-map-react";


const colors = [
  {
    name: "Primary",
    value: "primary",
  },
  {
    name: "Secondary",
    value: "secondary",
  },
  {
    name: "Success",
    value: "success",
  },
  {
    name: "Danger",
    value: "danger",
  },
  {
    name: "Warning",
    value: "warning",
  },
  {
    name: "Info",
    value: "info",
  },
];

export const TabCard = () => {
  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">Info</h3>
                <p className="mb-2">Total Earnings</p>
                <div className="mb-0">
                  <Badge bg="" className="badge-soft-success me-2">
                    +5.35%
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <DollarSign className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}


export const Markers = () => {
    const getMapOptions = (maps) => {
      return {
        fullscreenControl: true,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        scaleControl: true,
        scrollwheel: false,
        streetViewControl: true,
      };
    };

    const renderMarkers = (map, maps) => {
      new maps.Marker({
        position: {
          lat: 34.02735936443522,
          lng: -118.45357556441787,
        },
        map,
        title: "Hello World!",
      });
    };

    return (
      <div>
          <h6 className="card-subtitle text-muted my-2">Location unavailable</h6>
          <div style={{ height: 300, width: "100%" }}>
            <GoogleMapReact
              options={getMapOptions}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GMAPS,
              }}
              defaultCenter={{
                lat: 34.02735936443522,
                lng: -118.45357556441787,
              }}
              defaultZoom={10}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              yesIWantToUseGoogleMapApiInternals={true}
            />
          </div>
      </div>
    )
  }

export const MapCard = () => {
  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">Location</h3>
                <p className="mb-2">Location</p>
                <div className="mb-0">
                  <Badge bg="" className="badge-soft-success me-2">
                    Date
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <MapPin className="align-middle text-success" />
                </div>
              </div>
            </div>
            <div>
              <Markers />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

const StyleTemplate = () => {
  const [eventKey, setKey] = useState('first')

  return (
    <React.Fragment>
    <Helmet title="Style Template" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Blank Page</h1>
      <Row>
      <Card>
        <Tabs
          id="controlled-tab-example"
          activeKey={eventKey}
          onSelect={(k) => setKey(k)}
          className="mb-3"
          variant="tabs"
          bg="info"
        >
          <Tab eventKey="first" title="Tab 1">
            <TabCard />
          </Tab>
          <Tab eventKey="second" title="Tab 2">
            <MapCard />
          </Tab>
          <Tab eventKey="third" title="Tab 3">
            empty third tab
          </Tab>
        </Tabs>
          </Card>
      </Row>
      <Row>
        <Col>
          <Card>
          <Card.Header>
      <Card.Title tag="h5">Basic Buttons</Card.Title>
      <h6 className="card-subtitle text-muted">
        Default Bootstrap buttons style.
      </h6>
    </Card.Header>
    <Card.Body className="text-center">
      <div className="mb-3">
        {colors.map((color, index) => (
          <Button key={index} variant={color.value} className="me-1 mb-1">
            {color.name}
          </Button>
        ))}
      </div>
      <div>
        {colors.map((color, index) => (
          <Button
            key={index}
            variant={color.value}
            className="me-1 mb-1"
            disabled
          >
            {color.name}
          </Button>
        ))}
      </div>
    </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
          <Col>
          <Card>
    <Card.Header>
      <Card.Title tag="h5">Outline Buttons</Card.Title>
      <h6 className="card-subtitle text-muted">
        Buttons without background color.
      </h6>
    </Card.Header>
    <Card.Body className="text-center">
      <div className="mb-3">
        {colors.map((color, index) => (
          <Button
            key={index}
            variant={`outline-${color.value}`}
            className="me-1 mb-1"
          >
            {color.name}
          </Button>
        ))}
      </div>
      <div>
        {colors.map((color, index) => (
          <Button
            key={index}
            variant={`outline-${color.value}`}
            className="me-1 mb-1"
            disabled
          >
            {color.name}
          </Button>
        ))}
      </div>
    </Card.Body>
  </Card>
          </Col>
      </Row>
    </Container>
  </React.Fragment>
  )
};

export default StyleTemplate;
