import { DateTime } from 'luxon'
const { isEmpty } = require ("lodash")

const newOrderScripts = (offer, ptc, shopperData) => {
  let contract = ''
  let promotions = []
  let promoLang = ''
  let rewardLang = ''

  offer.plan_promos && offer.plan_promos.forEach(promo =>  promotions.push('Do you acknowledge that as part of this offer you will receive ' + promo.promo_desc + ' and that complete details of this promotion will be sent to you along with your contract?'))
  if (!isEmpty(offer.plan_promos)) {
    promoLang = promotions
  } else {
    promoLang = ('(No promos)')
  }

  if(!isEmpty(offer.plan_rewards_description)) {
    rewardLang = ('Do you understand that as part of this offer you will receive ' + offer.plan_rewards_description + ' and that complete details of this promotion will be sent to you along with your contract?')
  } else {
    rewardLang = ('(No rewards)')
  }

  if (offer.contract_type_code === 'fixed') {
    if (offer.product_type_code === 'NaturalGas') {
      contract = (`You have agreed to enroll your natural gas account in Inspire’s ${offer.plan_name} at a fixed supply rate of ${offer.rate_friendly} for ${offer.duration_friendly} with no deposits, sign up fees, or cancellation fees. Is that correct?`)
    } else if (offer.state_code === 'OH') {
      contract = (`Do you agree to receive a fixed electricity supply price of ${offer.rate_friendly} for ${offer.duration_friendly}, with no deposits, credit checks, sign-up fees, or cancellation fees?`)
    } else if (offer.state_code === 'NY') {
      contract = (`Do you agree to receive a month-to-month electricity supply price of ${offer.rate_friendly} for an Introductory Price period that will last ${offer.duration_friendly}, that you will receive a customized price per kWh that can change monthly afterwards that will be communicated to you at least 30 days prior to the expiration of the Introductory Price Period?`)
    } else {
      contract = (`Do you agree to receive a fixed electricity supply price of ${offer.rate_friendly} for ${offer.duration_friendly}, with no sign-up fees, deposits, or cancellation fees?      `)
    }
  } else if (offer.contract_type_code === 'subscription') {
    if (offer.is_intro_subscription) {
      if (offer.product_type_code === 'NaturalGas' && offer.state_code !== 'NY') {
        contract = (`You have agreed to receive a subscription trial price for natural gas supply of ${offer.rate_friendly} per month for ${offer.intro_period_friendly} with no deposits, signup fees or cancellation fees. After the trial period ends you will receive a new, customized subscription price that will be communicated to you by email or mail at least 45 days prior to the end of the trial period. If you do not cancel your service prior to the end of the trial period you will automatically receive a new subscription price for the remaining ${offer.duration_friendly} initial term of your contract. Is that correct?`)
      } else if (offer.state_code === 'OH') {
        contract = (`Do you agree to receive a flat monthly electricity supply price of ${offer.rate_friendly} for a Subscription Trial Period that will last ${offer.intro_period_friendly} months, and that after the Subscription Trial Period ends you will receive a new, customized flat monthly price that will be communicated to you at least 45 days prior to the end of the Subscription Trial Period, with no deposits, credit checks, sign-up fees, or cancellation fees?`)
      } else if (offer.state_code === 'NY') {
        contract = (`Do you agree to receive a flat monthly electricity supply price of ${offer.rate_friendly} for an Introductory Price period that will last ${offer.intro_period_friendly}, that you will receive a customized flat monthly price afterwards that will be communicated to you at least 30 days prior to the expiration of the Introductory Price Period?`)
      } else {
        contract = (`You have agreed to receive a subscription trial price for electricity supply of ${offer.rate_friendly} per month for ${offer.intro_period_friendly} with no deposits, signup fees or cancellation fees. After the trial period ends you will receive a new, customized subscription price that will be communicated to you by email or mail at least 45 days prior to the end of the trial period. If you do not cancel your service prior to the end of the trial period you will automatically receive a new subscription price for the remaining ${offer.duration_friendly} initial term of your contract. Is that correct?`)
      }
    } else {
      if (offer.product_type_code === 'NaturalGas') {
        contract = (`Do you agree to receive a flat monthly natural gas supply price of ${offer.rate_friendly}, for ${offer.duration_friendly}, with no deposits, sign up fees, or cancellation fees?`)
      } else if (offer.state_code === 'OH') {
        contract = (`Do you agree to receive a flat monthly electricity supply price of ${offer.rate_friendly}, for ${offer.duration_friendly}, with no deposits,  credit checks, sign-up fees, or cancellation fees?        `)
      } else {
        contract = (`Do you agree to receive a flat monthly electricity supply price of ${offer.rate_friendly}, for ${offer.duration_friendly}, with no sign-up fees, deposits, or cancellation fees?`)
      }
    }
  }


  if (offer.product_type_code === 'NaturalGas') {
    switch (offer.state_code) {
      case `NJ`:
        return [
          `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility. Inspire is licensed by the ${offer.state_code} Board of Public Utilities as an natural gas supplier, not the same company as your utility, and you are not required to enroll with Inspire.`
          `May I please have your name?`,
          `May I please have your name as it appears on your bill?`,
          `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
          `Is your service address the same as your mailing address? If NO, capture mailing address.`,
          `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
          `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
          `Can you confirm that you are the utility account holder or are authorized by the account holder to make changes to this utility account?`,
          `Do you acknowledge that Inspire is not your gas utility company and that you may choose to remain with your utility company or current gas supplier?`,
          `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
          `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your gas supplier today? (Must get a yes)`,
          `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
          `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
          `${contract}`,
          `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
          `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
          `Do you understand that the gas supply will be provided by Inspire, savings are not guaranteed, and that the delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment?`,
          `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
          `Do you understand that the Initial Term of your contract is ${offer.duration_friendly} and that at the expiration of the Initial Term your contract will automatically continue on a month-to-month basis until canceled?`,
          `Do you understand that you may resctind this enrollment without penalty within 7 calendar days from the postmark date of your gas utility company’s confirmation notice to cancel this contract without penalty and that you will receive a confirmation number should you choose to cancel, and that you can also cancel this contract anytime by calling Inspire toll-free at ${offer.cs_contact_phone} or by email at ${offer.cs_contact_email}?`,
          `${promoLang}`,
          `${rewardLang}`,
        ]

      case `PA`:
        return [
          `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
            Inspire is licensed by the ${offer.state_code}  Public Utility Commission as an natural gas supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
          `May I please have your name?`,
          `May I please have your name as it appears on your bill?`,
          `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
          `Is your service address the same as your mailing address? If NO, capture mailing address.`,
          `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
          `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
          `Can you confirm that you are the utility account holder or are authorized by the account holder to make changes to this utility account?`,
          `Do you acknowledge that Inspire is not your gas utility company and that you may choose to remain with your utility company or current gas supplier?`,
          `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
          `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your gas supplier today? (Must get a yes)`,
          `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
          `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
          `${contract}`,
          `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
          `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
          `Do you understand that the gas supply will be provided by Inspire, savings are not guaranteed, and that the delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment?`,
          `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
          `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you renewal notices prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
          `Do you understand that you may rescind this agreement within 3 business days after receiving it by contacting Inspire by phone, email, or in writing and that if you do not rescind this Agreement an enforceable agreement will be created?`,
          `${promoLang}`,
          `${rewardLang}`,
        ]

      case `OH`:
        return [
          `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
            Inspire is licensed by the Public Utilities Commission of Ohio as a gas supplier, not the same company as your utility, and you may choose to remain with your utility company on default service or choose another supplier.`,
          `May I please have your name?`,
          `May I please have your name as it appears on your bill?`,
          `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
          `Is your service address the same as your mailing address? If NO, capture mailing address.`,
          `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
          `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
          `Are you currently participating in a percentage of income payment plan (PIPP) or any home energy assistance plans with your utility company? NOTE: If the answer is yes, stop the
            enrollment and say: Thanks for letting us know. Unfortunately in this case, your utility rules do not allow us to provide service to you at this time. We appreciate your time today and hope you have a great rest of your day.`
          `Do you understand that Inspire does not offer budget billing for the generation portion of your energy bill?`
          `Can you confirm that you are the utility account holder or are authorized by the account holder to make changes to this utility account?`,
          `Do you acknowledge that Inspire is not your gas utility company and that you may choose to remain with your utility company or current gas supplier?`,
          `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your gas supplier today? (Must get a yes)`,
          `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
          `${contract}`,
          `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
          `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees, that prior to the end of your contract Inspire will send you renewal options and if no action is taken, your service with Inspire will continue on those terms?`,
          `Do you acknowledge that we are required to inform you that Inspire does not offer budget billing for the generation portion of your gas bill?`,
          `Do you understand that the gas supply will be provided by Inspire, savings are not guaranteed, and that the delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment?`,
          `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
          `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least 60 days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
          `Do you understand that you may resctind this enrollment without penalty within 7 calendar days from the postmark date of your gas utility company’s confirmation notice to cancel this contract without penalty and that you will receive a confirmation number should you choose to cancel, and that you can also cancel this contract anytime by calling Inspire toll-free at ${offer.cs_contact_phone} or by email at ${offer.cs_contact_email}?`,
          `${promoLang}`,
          `${rewardLang}`,
        ]
      default:
        return [
          `No terms for this state`,
        ]
    }
  } else {
  switch (offer.state_code) {
    case 'IL':
      if (offer.contract_type_code === 'subscription') {
        contract = (
          'Do you agree to receive a flat monthly electricity supply price of ' + offer.rate_friendly + ' for a Subscription Trial Period that will last ' + offer.duration_friendly + ', and that after the Subscription Trial Period ends you will receive a new, customized flat monthly price that will be communicated to you at least 30 days prior to the end of the Subscription Trial Period?',
          'Do you acknowledge that your monthly subscription price will not vary based on usage levels, that this supply price typically represents approximately 50-60% of your total electric bill, and that you must also pay charges from your utility company, which are not included in this subscription price?'
        )
      } else {
        contract = (`Do you agree to receive a fixed electricity supply price of ${offer.rate_friendly} for ${offer.duration_friendly}, with no sign-up fees, deposits, or cancellation fees?`)
      }
      return [
        `Thank you for your interest in signing up with Inspire. Illinois law requires that I read a disclosure before confirming your plan and information:
          As a reminder, I don’t work for or represent ComEd, any utility program, any government body or governmental body program,
          any consumer group or consumer group program, or any other electric supplier. Inspire is an independent seller of electric power and
          energy service, certified by the Illinois Commerce Commission. Inspire is not the same entity as your electric delivery company,
          and you are not required to enroll with Inspire. Beginning on ${DateTime.fromISO(ptc.from_dt).toLocaleString(DateTime.DATE_MED)}, the electric supply price to compare is ${ptc.rate}.
          The electric utility electric supply price will expire on ${DateTime.fromISO(ptc.to_dt).toLocaleString(DateTime.DATE_MED)}.
          The utility electric supply price to compare does not include the purchased electricity adjustment factor.
          The purchased electricity adjustment factor may range between +.5 cents and -.5 cents per kilowatt hour.
          For more information go to the Illinois Commerce Commission's free website at www.pluginillinois.org.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you do not participate in the PIPP or LIHEAP income assistance programs? (Must get a yes)`,
        `Can you confirm that you are the electricity account holder or are authorized by the account holder to make changes to this electricity account?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued`,
        // `Do you acknowledge that Inspire is not the same company as your electric utility company, that you are not required to enroll with Inspire, that as of ${DateTime.fromISO(ptc.updated_at).toLocaleString(DateTime.DATE_MED)}, the electricity supply price to compare is ${(ptc.rate)*100} cents per ${ptc.rate_units}, the price to compare will expire on ${DateTime.fromISO(ptc.to_dt).toLocaleString(DateTime.DATE_MED)}. The utility electric supply price to compare does not include the purchased electricity adjustment factor, and for more information about offers, you can visit the Illinois Commerce Commission’s website at www.pluginillinois.org?`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        `Do you understand that your price with Inspire will be the same regardless of electricity usage levels during the Initial Term of your contract, and that Inspire’s charges typically represent approximately 50-60% of your total bill, savings are not guaranteed, and that you must also pay charges from your utility company, which are not included in this subscription price, and that information regarding your pricing information can be received anytime by contacting us? (Must get a yes)`,
        // `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        // `Do you acknowledge that information regarding Inspire’s historical or future pricing information can be received anytime by contacting Inspire by phone?`,
        // `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
        // `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility, that you will receive written notice from your utility confirming your switch, and that you will receive a copy of your contract by mail or email within 3 business days after your enrollment is accepted, along with ways to contact Inspire?`,
        // `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
        // `Do you acknowledge that this agreement will automatically renew on the expiration of the estimated billing cycle for the initial term of the contract, the exact date that your initial agreement will begin and end depends on when your local utility company accepts our request to enroll you, this process can take up to 45 days, your renewal contract term will begin on the following billing cycle, you will be provided with a Notice of Automatic Contract Renewal, not more than 60 days and not less than 30 days before the expiration of this Agreement, that notice will advise you of product options that are available to you and the procedure for notifying us if you want to reject contract renewal and terminate your existing contract at the end of its term, to cancel your contract, or to opt-out of the automatic renewal, you may contact Inspire Member Support at  ${offer.cs_contact_phone}, ${offer.cs_contact_email}, or by chat at www.inspirecleanenergy.com?`,
        // `Do you acknowledge that you have 10 calendar days from the date your utility processes this enrollment to rescind this contract without penalty by contacting Inspire or your local utility, that you will not be switched to Inspire until this rescission period has expired, and that you can also cancel this contract anytime by calling Inspire toll-free at ${offer.cs_contact_phone} or by email at ${offer.cs_contact_email}?`,
        // `Do you acknowledge that you may contact your electric utility at [1 (800) 334-7661] and that if you have any questions or concerns about this sales solicitation, you may contact the Illinois Commerce Commission's Consumer Services Division toll-free at 1-800-524-0795?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at the expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least 30 days prior to any renewal period, and that you can cancel your service with Inspire anytime?`, //offer.market_defaults_json.minimum_regulatory_intro_period_days
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that your service with Inspire will begin upon acceptance from your utility company and that the switch to Inspire may take up to 1 to 2 billing cycles to occur?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 3 business days, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text} You may call us at 866-403-2620 or your utility at 800-334-7661 to rescind. If you have any future issues that you cannot resolve with Inspire, you may contact the ICC Consumer Services Division at 800-524-0795.`,
        `OPTIONAL OPT IN CONSENT: Before we hang up today, I wanted to ask if you’d like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive  Inspire’s services, and you can unsubscribe any time by calling us or by replying STOP to any text message. Do I have your consent? Thank you again for choosing Inspire!`
      ]

    case 'MA':
      return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code} Department of Public Utilities as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the electricity account holder or are authorized by the account holder to make changes to this electricity account?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        // `Do you acknowledge that Inspire is not your electric utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        // `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
        // `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least 30 days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text}`,
        `OPTIONAL: Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`
      ]

    case `PA`:
      return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code} Department of Public Utilities as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the utility account holder or are authorized by the account holder to make changes to this utility account?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        // `Do you acknowledge that Inspire is not your electric utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 3 business days after the completion of your enrollment?`,
        `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
        `Do you understand that your electric utility will send you a confirmation notice about your enrollment with Inspire. You have 7 calendar days from the postmark date of this notice to cancel the contract without penalty. The utility company will also provide you a cancellation number to confirm any cancellation during the cancellation period, and you can also cancel this contract anytime by calling Inspire toll-free at 866-403-2620 or by email at help@inspirecleanenergy.com?`,
        // `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        // `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at the expiration of the Initial Term your contract will automatically renew, that Inspire will send you renewal notices prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that your service with Inspire will begin upon acceptance from your utility company and that the switch to Inspire may take up to 7 to 10 business days to occur?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text}`,
        `OPTIONAL:  Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`
      ]

    case `DC`:
     return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code} Public Service Commission as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the utility account holder and are authorized to make the switch to Inspire?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Do you acknowledge that Inspire is not your electric utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        // `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        // `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
        // `Do you authorize your utility company to release to Inspire your electricity consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any electricity supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        // `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 5 business days after the completion of your enrollment?`,
        // `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at the expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least 45 days prior to any renewal period, and that you can cancel your service with Inspire anytime?`, //offer.market_defaults_json.minimum_regulatory_intro_period_days
        // `Do you understand that you may rescind this agreement within 3 business days after receiving it by contacting Inspire by phone, email, or in writing and that if you do not rescind this Agreement an enforceable agreement will be created?`, //offer.recision_text
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that your service with Inspire will begin upon acceptance from your utility company and that the switch to Inspire may take up to 1 billing cycle to occur?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text}`,
        `OPTIONAL: Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`
      ]

    case `DE`:
      return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code} Public Service Commission as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the utility account holder and are authorized to make the switch to Inspire?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        // `Do you acknowledge that Inspire is not your electric utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        // `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        // `Do you acknowledge that information regarding Inspire’s historical or future pricing information can be received anytime by contacting Inspire by phone?`,
        // `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        // `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
        // `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 3 business days after the completion of your enrollment?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at the expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least 45 days prior to any renewal period, and that you can cancel your service with Inspire anytime?`, //offer.market_defaults_json.minimum_regulatory_intro_period_days
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that your service with Inspire will begin upon acceptance from your utility company and that the switch to Inspire may take up to 1 billing cycle to occur?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text}`,
        `OPTIONAL: Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`
      ]

    case `MD`:
       return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code} Public Service Commission as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `NOTE: MD Enrollments must be completed by account holders. If the enroller is not the Account Holder, the enrollment should be discontinued.`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the utility account holder and are authorized to make the switch to Inspire? Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        // `Do you acknowledge that Inspire is not your electric utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        `Do you understand that your price with Inspire will be the same regardless of electricity usage levels during the Initial Term of your contract, the price is only for electricity supply and does not include any taxes, utility distribution charges or any other utility fees, savings are not guaranteed, that Inspire's price is not regulated by the MD Public Service Commission, and that information regarding your current or future pricing information can be received anytime by contacting us?`,
        // `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        // `Do you acknowledge that as part of your offer there are no sign-up fees, deposits, credit checks, and no cancellation fees?`,
        // `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 3 business days after the completion of your enrollment?`,
        // `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges? `,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least 45 days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that your service with Inspire will begin upon acceptance from your utility company and that the switch to Inspire may take up to 1 billing cycle to occur?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 3 business days, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text}`,
        `OPTIONAL: Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`
      ]

    case `NJ`:
      return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code} Board of Public Utilities as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the electricity account holder or are authorized by the account holder to make changes to this electricity account? Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Do you acknowledge that Inspire is not your electric utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        // `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        // `Do you acknowledge that as part of your offer there are no sign-up fees, deposits, credit checks, and no cancellation fees?`,
        // `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment?`,
        // `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly} and that at the expiration of the Initial Term your contract will automatically continue on a month-to-month basis until canceled?`, //Duration calculation?
        // `Do you acknowledge that you have 7 calendar days from the postmark date of your electric utility company’s confirmation notice to cancel this contract without penalty and that you will receive a confirmation number should you choose to cancel, and that you can also cancel this contract anytime by calling Inspire toll-free at ${offer.cs_contact_phone} or by email at ${offer.cs_contact_email}?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that your service with Inspire will begin upon acceptance from your utility company and that the switch to Inspire may take up to 1 to 2 billing cycles to occur?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text}`,
        `OPTIONAL: Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`
      ]
    case `NY`:
      return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code}  Public Service Commission as an electricity supplier, not the same company as your utility,and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the utility account holder and are authorized to make the switch to Inspire? Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        // `Do you acknowledge that Inspire is not your electric utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect
         for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that taking service from Inspire will not affect your utility distribution service, your utility company will continue to provide distribution service,
         and that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges? (Must get a yes)`,
        `${contract}`,
        `Do you acknowledge that as part of your offer there are no sign up fees, no cancellation fees, and that you are entering into a contract that will automatically renew each month until cancelled? (Must get a yes)`,
        `Do you acknowledge that we are required to make you aware of the ESCO Consumer Bill of Rights, that you can find a copy of it on the New York Public Service Commission website, and that Inspire will send you a copy of this document by email or mail following the completion of your enrollment and anytime upon your request?`,
        // `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles? `,
        // `Do you acknowledge that you can find a copy of the Energy Services Company (ESCO) Consumer Bill of Rights on the New York Public Service Commission website, and that Inspire will send you a copy of this document by email or mail following the completion of your enrollment and anytime upon your request? `,
        // `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 3 business days after the completion of your enrollment?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that your service with Inspire will begin upon acceptance from your utility company and that the switch to Inspire may take up to 1 to 2 billing cycles to occur?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `${offer.rescission_text}`,
        `Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`
      ]

    case `OH`:
      return [
        `Thank you for your interest in choosing Inspire as your electricity supplier.
          Before we begin I am required to let you know that this call is being recorded for quality assurance purposes. Do you understand?`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Are you currently participating in a percentage of income payment plan (PIPP) or any home energy assistance plans with your utility company?`, 
        `NOTE: If the answer is yes, stop the enrollment: Thanks for letting us know. Unfortunately in this case, your utility rules do not allow us to provide service to you at this time. We appreciate your time today and hope you have a great rest of your day.`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.market_code} ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the utility account holder or are authorized by the account holder to make changes to this utility account?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Do you understand that Inspire is not your utility company and that you may choose to remain with your utility company or current electricity supplier?`,
        `${contract}`,
        `${rewardLang}`,
        `${promoLang}`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your electricity supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you understand that electricity supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment? (Must get a yest)`,
        `Do you understand that you will receive a monthly consolidated bill from your utility company that includes Inspire’s supply charges and the utility delivery charges? (Must get a yest)`,
        `Do you understand that Inspire does not offer budget billiing for the generation portion of your energy bill?`,
        `Do you understand that your electric utility will send you a confirmation notice about your enrollment with Inspire. You have 7 calendar days from the postmark date of this notice to cancel the contract without penalty. The utility company will also provide you a cancellation number to confirm any cancellation during the cancellation period, and you can also cancel this contract anytime by calling Inspire toll-free at 866-403-2620 or by email at help@inspirecleanenergy.com?`,
        `Do you understand that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at the expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least 60 days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `OPTIONAL: Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`,
        `MUST PROVIDE THE CUSTOMER WITH AN ENROLLMENT CONFIRMATION NUMBER`
      ]

    default:
      // default uses PA script
      return [
        `Thank you for your interest in signing up with Inspire. As a reminder, I don’t work for or represent your local utility.
          Inspire is licensed by the ${offer.state_code} Public Utility Commission as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill?`,
        `Please verify your service address listed as ${shopperData.service_address_line_1} ${shopperData.service_address_line_2} ${shopperData.service_city}, ${shopperData.service_state} ${shopperData.service_zip_code}`,
        `Is your service address the same as your mailing address? If NO, capture mailing address.`,
        `Please verify your ${offer.account_num_synonym} as ${shopperData.account_number}`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Can you confirm that you are the utility account holder and are authorized to make the switch to Inspire? Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate utility supply service? (Must get a yes)`,
        `Can you please confirm that you voluntarily wish to choose Inspire Energy Holdings, LLC as your gas supplier today? (Must get a yes)`,
        `Do you authorize ${offer.market_code} to release to Inspire your utility consumption and any other information necessary to process your switch to Inspire, and that this authorization will remain valid and in effect for 30 days or the duration of any utility supply agreement with Inspire, whichever is longer, and that you can rescind this authorization at any time by contacting us?`,
        `Do you acknowledge that Inspire does not offer savings and that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the energy delivery to your home and will continue to respond to any service calls and emergencies and that you will receive written notification from your utility confirming your switch to Inspire?`,
        `${contract}`,
        `Do you acknowledge that your service start date with Inspire is determined by your utility company but is estimated to begin within 1 to 2 billing cycles?`,
        `Do you acknowledge that as part of your offer there are no sign up fees, deposits, credit checks, and no cancellation fees?`,
        `Do you understand that the gas supply will be provided by Inspire, savings are not guaranteed, and that the delivery, billing, and response to any emergencies should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment?`,
        `Do you acknowledge that your utility company will continue to send you a monthly consolidated bill that contains Inspire’s charges and any utility charges?`,
        `Do you understand that the Initial Term of your contract is ${offer.duration_friendly}, that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you renewal notices prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `Do you understand that you may rescind this agreement within 3 business days after receiving it by contacting Inspire by phone, email, or in writing and that if you do not rescind this Agreement an enforceable agreement will be created?`,
        `${promoLang}`,
        `${rewardLang}`,
      ]
    }
  }
}

export default newOrderScripts;
