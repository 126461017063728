import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getLinkedAccounts } from '../../apis/accounts'
import useToast from '../../hooks/useToast'
import ReactTableBasic from '../ReactTableBasic'

const LinkedAccounts = ({ accountId }) => {
  const [linkedAccounts, setLinkedAccounts] = useState([])
  const navigate = useNavigate()
  const { handleAlert } = useToast()

  useEffect(() => {
    if (accountId) {
      getLinkedAccounts(accountId, setLinkedAccounts, handleAlert)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  const rowOnClick = (e) => {
    navigate(`/profiles/${e.membership_profile_id}/accounts/${e.account_id}`)
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: linkedAccounts => `${linkedAccounts.first_name} ${linkedAccounts.last_name}`,
    },
    {
      Header: 'Product Code',
      accessor: 'product_type_code',
    },
    {
      Header: 'Service Address',
      id: 'service_address',
      accessor: linkedAccounts => `${linkedAccounts.service_address.street},  ${linkedAccounts.service_address.city},  ${linkedAccounts.service_address.state_code}  ${linkedAccounts.service_address.zip}`
    },
    {
      Header: 'Utility',
      accessor: 'market_code',
    },
    {
      Header: 'Account Identifier',
      accessor: 'account_number',
    },
  ]

  return (
    <React.Fragment>
      <Container>
        {!isEmpty(linkedAccounts) ?
          <div>
          <h4>Other Accounts for Member</h4>
            <div className="mt-3 mb-5">
              <ReactTableBasic
                columns={tableColumns}
                data={linkedAccounts}
                handleClick={rowOnClick}
                canHover={true}
              />
            </div>
          </div>
        :
          <h4>No Linked Accounts for Member</h4>
        }
      </Container>
    </React.Fragment>
  )
}

export default LinkedAccounts
