/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { ListGroup, Form, Button, Spinner } from "react-bootstrap"
import { getPTC } from '../../../apis/pricing'
import { winbackScripts, retentionScripts } from "../../../utils/scripts"
import { acceptWinback, acceptRetention } from "../../../apis/offers"
import useUser from "../../../hooks/useUser"
import useToast from "../../../hooks/useToast"
import dino from '../../../assets/img/dino.gif'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'

const Verification = ({ account, offer, winback, setShowVerification, tpv_type, channel }) => {
  const { userInfo } = useUser()
  const { handleAlert } = useToast()
  const [ptc, setPTC] = useState({})
  const [message, updateMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (account.service_state === 'IL') getPTC(account, setPTC, handleAlert)
  }, [account])

  const submitWinback = async () => {
    setLoading(true)
    const params = {
      account_id: account.account_id,
      winback_id: winback.winback_id,
      offer_code: offer.offer_code,
      campaign_id: offer.campaign_id,
      audit: {
        user_name: userInfo.email,
        user_id: userInfo.user_id
      },
      offered_dt: winback.winback_dt,
      contact_id: winback.contact_id,
      tpv_type: tpv_type,
      conversion_medium: 'INSPIRE_CRM'
    }
    const message = await acceptWinback(params, handleAlert)
    updateMessage(message)
    setLoading(false)
  }

  const submitRetention = async () => {
    setLoading(true)
    const params = {
      account_id: account.account_id,
      offer_code: offer.offer_code,
      campaign_id: offer.campaign_id,
      audit: {
        user_name: userInfo.email,
        user_id: userInfo.user_id
      },
      tpv_type: tpv_type,
      conversion_medium: 'INSPIRE_CRM'
    }
    const message = await acceptRetention(params, handleAlert)
    updateMessage(message)
    setLoading(false)
  }

  return (
    <ListGroup variant="flush">
      {
        message !== null ?
        <h1 className="text-center h2 font-weight-bold">{message} <img className="px-2 py-1" style={{ display: 'inline-block', maxWidth: 300 }} src={dino} alt="Tim Party Dino" /></h1>
        :
        <>
          {tpv_type === 'Internal' && channel === 'RETENTION' ?
            <>
              <ListGroup.Item className="font-weight-bold m-0">Verification Steps</ListGroup.Item>
              {retentionScripts({ account, offer }).map(script => !isEmpty(script) ? (<ListGroup.Item key={uuidv4()}><Form.Check type="checkbox" label={script} /></ListGroup.Item>) : '') }
              <Button variant="primary" className="mt-3 btn-lg" onClick={submitRetention}>Yes, let's retain them! {loading && <Spinner animation="border" size="sm"/>}</Button>
            </>
            :
            <>
              <ListGroup.Item className="font-weight-bold m-0">Verification Steps</ListGroup.Item>
              {winbackScripts({ account, offer, ptc }).map(script => <ListGroup.Item key={uuidv4()}><Form.Check type="checkbox" label={script} /></ListGroup.Item>)}
              <Button variant="primary" className="mt-3 btn-lg" onClick={submitWinback}>Yes, let's win them back! {loading && <Spinner animation="border" size="sm"/>}</Button>
            </>
          }
          <Button variant="link" onClick={() => setShowVerification(false)}>Back</Button>
        </>
      }
    </ListGroup>
  )
}

export default Verification
