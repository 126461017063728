import React from "react";
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import AxiosInterceptor from './utils/AxiosInterceptor'
import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import "react-app-polyfill/stable";
// import Snowfall from 'react-snowfall'

AxiosInterceptor()

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
})

const root = createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    {/* Commenting out snowfall until next winter :) */}
    {/* <Snowfall /> */}
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
