import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare, faAt, faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

export const renderSendCorrespondence = (data, handleSendCorrespondence, sending) => {
  const icons = [faEnvelope, faAt]
  const render = icons.map(icon => {
    const mail = icon === faEnvelope ? 'Mail' : 'Email'
    if(icon === faAt && !data.row.original.can_resend_via_email){
      return (<Col key={uuidv4()} ></Col>)
    } else if (icon === faEnvelope && data.row.original.correspondence_type_code === 'Rate Us on Google'){
      return (<Col key={uuidv4()} ></Col>)
    } else if (icon === faEnvelope && data.row.original.correspondence_type_code === 'Review Us on Better Business Bureau'){
      return (<Col key={uuidv4()} ></Col>)
    }
    return (
      <OverlayTrigger
        key={uuidv4()}
        placement="bottom"
        overlay={<Tooltip>Send Correspondence Via {mail}</Tooltip>}
      >
        <Col>
          <FontAwesomeIcon
            className="faux-button"
            size='2x'
            data-testid="send-button"
            color="#FF8676"
            icon={icon}
            onClick={() => handleSendCorrespondence(data, mail)}
            disabled={sending}
          />
        </Col>
      </OverlayTrigger>
    )
    })
  return render
}


export const renderTermsUrl = (data, handleViewCorrespondence) => {
  return (
    <span >
    {!data.row.original.url && (data.row.original.disclosure || data.row.original.archive_loc) &&
      <>
      <OverlayTrigger
        key={uuidv4()}
        placement="top"
        overlay={<Tooltip>View</Tooltip>}
      >
        <span
        className="primary-anchor"
        variant="primary"
        target="_blank"
        rel="noreferrer"
        onClick={() => {handleViewCorrespondence(data.row.original)}}
        >
          <FontAwesomeIcon size="2x" className="faux-button" data-testid="view-button" icon={faUpRightFromSquare} />
        </span>
      </OverlayTrigger>
      </>
    }{data.row.original.url &&
    <a
      target="_blank"
      rel="noreferrer"
      href={data.row.original.url}
      >
        <FontAwesomeIcon size="2x" className="faux-button" data-testid="view-button" icon={faUpRightFromSquare} />
    </a>
    }
    </span>
  )
}
