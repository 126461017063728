/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Form, Button, Modal } from 'react-bootstrap'
import Select from 'react-select'
import ReactTable from '../ReactTable'
import { allTableColumns } from './helpers/casesTableAllColumns'
import casesTableColumnConfig  from '../../components/cases/helpers/casesTableColumnConfig'
import Case from '../../pages/case/index'
import useUser from '../../hooks/useUser'
import useLocalStorage from '../../hooks/useLocalStorage'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom'

const CasesTable = ({ cases, loadCases }) => {
  const { roles } = useUser().userInfo
  const [columnViewChange, setColumnViewChange] = useLocalStorage('column_view_change', [])
  const [columnsToDisplay, setColumnsToDisplay] = useLocalStorage('columns_to_display', casesTableColumnConfig(roles, columnViewChange))
  const [showCaseModal, setShowCaseModal] = useState(false)
  const [caseGuid, setCaseGuid] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    setColumnsToDisplay(casesTableColumnConfig(roles, columnViewChange))
  }, [roles, columnViewChange])

  const columnOptions = () => {
    return allTableColumns.reduce((previousValue, currentValue) => {
      if(!columnsToDisplay.includes(currentValue)){
        previousValue.push({label: currentValue.Header, value: currentValue.accessor})
      }
      return previousValue
    }, [])
  }

  const resetColumnView = () => {
    setColumnViewChange([])
    setColumnsToDisplay(casesTableColumnConfig(roles, []))
  }

  const handleShowCaseModal = (caseGuid) => {
    setCaseGuid(caseGuid)
    setShowCaseModal(true)
    window.history.pushState({}, '', `cases/${caseGuid}`)
  }

  const handleCloseCaseModal = () => {
    navigate('/cases')
    setShowCaseModal(false)
    loadCases(true)
  }

  return (
    <div data-testid="cases-table">
      <Row className="p-4">
        <h2>Cases</h2>
        <p>{cases.length} total cases</p>
        <ReactTable
          columns={columnsToDisplay}
          data={cases}
          handleClick={(e) => handleShowCaseModal(e.case_guid)}
          canHover={true}
        />
      </Row>
      <Row className="p-4">
        <Form.Label>Show Columns:</Form.Label>
        <Select
          aria-label="Columns Displayed"
          size="med"
          isMulti
          options={columnOptions()}
          onChange={(e) => setColumnViewChange(e)}
          value={columnsToDisplay.map(col => ({label: col.Header, value: col.accessor}) )}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#B0DBE9',
              primary: '#0E7C9E',
            },
          })}
        />
      </Row>
      <Row className="p-4">
        <Button className="btn btn-primary px-2" onClick={resetColumnView} disabled={isEmpty(columnViewChange)}>Reset Column View</Button>
      </Row>
        {showCaseModal &&
          <>
            <Modal show={handleShowCaseModal} onHide={handleCloseCaseModal} size="lg">
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body data-testid="case-modal">
                {caseGuid &&
                  <Case caseGuid={caseGuid} />
                }
                </Modal.Body>
            </Modal>
          </>
        }
    </div>
  )
}

export default CasesTable
