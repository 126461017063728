import React from "react";
import { MapPin } from "react-feather";
import { Card, Col, Row, Badge } from "react-bootstrap";
import { DateTime } from "luxon"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore, faReceipt } from "@fortawesome/pro-light-svg-icons";
import MapMarker from "../../../utils/mapMarker";

const SalesLocation = (props) => {
  const { order } = props
    return (
      <Row>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2">Sales Location</h3>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat">
                    <MapPin className="align-middle text-success" />
                  </div>
                </div>
              </div>
              <div className="mb-0">
                {order.sales_location_code &&
                  <p className="mb-2">
                    <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faStore} fixedWidth/>
                    {order.sales_location_code}
                  </p>
                }
                <div className="mb-2">
                  <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faReceipt} fixedWidth/>
                  Order Date:&nbsp;  <Badge bg="salmonLight" text="black" className="me-5">{DateTime.fromISO(order.sale_dt).toLocaleString(DateTime.DATE_MED)}</Badge>
                </div>
                <br />
                {!order.latitude ?
                  <div>
                    <p className="mb-2"><Badge bg="" className="badge-soft-success me-2">No location available</Badge></p>
                  </div>
                :
                  <div>
                    <MapMarker lat={+order.latitude} long={+order.longitude} />
                  </div>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

export default SalesLocation
