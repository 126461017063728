import axios from 'axios'

export const getEnergyImpact = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/agent_energy_impact_comparisons`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const searchSalesAgents = async (agentQuery, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/sales/agent/search?query=${agentQuery}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getAgentRefundSummary = async (userId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/refunds/agent_summary`, {params: {user_id: userId} })
    callback(response.data)
  } catch (error) {
    throw error
  }
}
