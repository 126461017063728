import axios from 'axios'

export const getOffers = async (params, callback, handleAlert) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/sales/app/offers`, params)
    callback({ results: response?.data.eligible_offers, 'x-request-id': response.headers['x-request-id'].split("-")[0] })
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

//single offer endpoint
export const getOffer = async (offerCode, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/${offerCode}`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting offer: ${error}`, false)
    throw error
  }
}

export const getWinback = async (account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/winbacks/account/${account_id}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getWinbackOffers = async (account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/winbacks/offers/${account_id}`)
    callback({ results: response?.data.eligible_offers, 'x-request-id': response.headers['x-request-id'].split("-")[0] })
  } catch (error) {
    throw error
  }
}

export const getOfferDetails = async (account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/offers/change/details`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const acceptWinbackDisposition = async (params, handleAlert) => {
  try {
    axios.post(`${process.env.REACT_APP_GARCON_API}/winbacks/${params.winback_id}`, params)
    handleAlert('Successful Disposition!', true)
  } catch (error) {
    throw error
  }
}

export const acceptWinback = async (params, handleAlert) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/winbacks/offers/${params.offer_code}/accept/${params.account_id}`, params)
    return `Successful Winback! Confirmation Code: ${response.data.confirmation_code}`
  } catch (e) {
    handleAlert(`Something went wrong: ${e}`, false)
    throw e
  }
}

export const acceptRetention = async (params, handleAlert) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/retention/offers/${params.offer_code}/accept/${params.account_id}`, params)
    return `Successful Retention! Confirmation Code: ${response.data.confirmation_code}`
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const getRetentionOffers = async (account_id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/retention/offers/${account_id}`)
    if (response?.data) {
      return { results: response?.data.eligible_offers, 'x-request-id': response.headers['x-request-id'].split("-")[0] }
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
}

export const updateZip = async (zip_code) => {
  try {
    return await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/markets/${zip_code}`)
  } catch (error) {
    throw error
  }
}

export const getCampaigns = async (userInfo, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/campaigns?active=true&source_code=${userInfo.source_code}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getMarketInfo = async (formInfo, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/markets/market/${formInfo.market_code}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getTpvOptions = async (channel_code, state_code, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/sales/app/team/permissions?channel_code=${channel_code}&source_code=INSPIRE&state_code=${state_code}`)
    callback(response.data?.tpv_types)
  } catch (error) {
    throw error
  }
}

export const snoozeWinbacks = async (account_id, handleAlert) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/snooze_winbacks`, {})
    handleAlert('Successfully Snoozed Winbacks', true)
  } catch (error) {
    throw error
  }
}


export const changeOffer = async (accountId, dropOffer, userEmail, handleAlert) => {
  try {
    const payload = {
      audit: {
        user_name: userEmail
      }
    }
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/drop_offer/${dropOffer.offer_code}`, payload)
    handleAlert(response.message, true)
  } catch (error) {
    handleAlert(`Failed to change offer: ${error}`, false)
    throw error
  }
}
