import React from 'react'
import { Card } from 'react-bootstrap'
import CheckDetail from './CheckDetail'
import { v4 as uuidv4 } from 'uuid'

const ChecksContainer = ({account, accountPayableBills}) => {
  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title tag="h3">
            Checks
          </Card.Title>
        </Card.Header>
        <Card.Body >
          {accountPayableBills.map((bill, index) => (
            <CheckDetail
              key={uuidv4()}
              account={account}
              bill={bill}
              index={index}
              />
          ))}
        </Card.Body>
      </Card>
    </div>
  )
}

export default ChecksContainer
