/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react'
import { Row, Form, Button } from 'react-bootstrap'
import findCaseAttribute from '../helpers/findCaseAttribute'
import { isEmpty } from 'lodash'

const RefundAttributeTextInput = ({
  attributeInput,
  caseTypeAttributes,
  setCaseTypeAttributes,
  handleUpdateCaseAttribute
}) => {

  const [copyAttributes, setCopyAttributes] = useState(caseTypeAttributes.map(attribute => ({...attribute})))
  const localCopyAttribute = findCaseAttribute(attributeInput.data_name, copyAttributes)
  const errorText = (inputName) => <Form.Text style={{ color: '#E86371' }}>{inputName}</Form.Text>

  const roundRefundAmount = (value) => {
    const zeroValueAfterDecimal = /^\$?[0-9]+\.?[0]*$/.test(value)
    return zeroValueAfterDecimal ? value : (Math.round(value * 100) / 100)
  }

  const attributeValue = attributeInput.data_name === 'refund_amount' ? roundRefundAmount(localCopyAttribute.value) :  localCopyAttribute.value
  const handleEmptyField = attributeValue.length === 0 ? <>{errorText('This field is required')}< br /></> : ''

  const handleDataChange = (e) => {
    const copyAttributesWithNewData = copyAttributes.map(attribute => ({...attribute}))
    copyAttributesWithNewData.find(attribute => {
      return attribute.attribute_id === attributeInput.attribute_id
    }).value = e.target.value
    setCopyAttributes(copyAttributesWithNewData)
  }

  return (
    <Row className="my-2">
      <Form.Group controlId={attributeInput.data_name}>
        <Form.Label>{attributeInput.attribute_name}</Form.Label>
        <Form.Control
          type="text"
          name={attributeInput.data_name}
          onChange={(e) => handleDataChange(e)}
          value={attributeValue}
          onBlur={() => setCaseTypeAttributes(copyAttributes)}
          isInvalid={attributeValue.length === 0}
        />
        {handleEmptyField}
        {attributeInput.validation_rules && <><Form.Text>Example:{attributeInput.validation_rules.format_example}</Form.Text><br /></>}
        {handleUpdateCaseAttribute &&
          <Button
            variant="danger"
            size="sm"
            disabled={attributeInput.original_value === attributeInput.value || isEmpty(attributeValue) ? true : false}
            onClick={() => handleUpdateCaseAttribute(attributeInput)}
          >
            Update {attributeInput.attribute_name}
          </Button>
        }
      </Form.Group>
    </Row>
  )
}

export default RefundAttributeTextInput

