import React, { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { updateContactPrefs } from '../../apis/prospects'
import useToast from '../../hooks/useToast'

const ProspectPreferences = ({ prospect }) => {
  const [formPrefs, setFormPrefs] = useState({})
  const [formLang, setFormLang] = useState({language_preference: prospect?.language_preference})
  const navigate = useNavigate()
  const { handleAlert } = useToast()

  const langOptions = [{
    value: "ENGLISH",
    label: "ENGLISH",
  },{
    value: "SPANISH",
    label: "SPANISH",
  },{
    value: "MANDARIN",
    label: "MANDARIN",
  }]

  const handleContactPrefs = (e) => {
    setFormPrefs({...formPrefs, [e.target.name]: e.target.checked})
  }

  const updateContact = async(e) => {
    e.preventDefault()
    const newPrefs = {...formPrefs, ...formLang}
    const response = await updateContactPrefs(prospect.prospect_guid, newPrefs, handleAlert)
    if (response.status === 200) {
      navigate(0)
    }
  }

  return (
    <React.Fragment>
      <Container>
      <h1>Contact Preferences</h1>
      <br />
      {prospect &&
      <Form>
        <Row>
          <Col md="4" className="px-5">
            <Form.Check
              type="switch"
              label="Calls"
              onClick={handleContactPrefs}
              name="can_call"
              defaultChecked={prospect.can_call}
            />
            <Form.Check
              type="switch"
              label="Texts"
              defaultChecked={prospect.can_text}
              onClick={handleContactPrefs}
              name="can_text"
            />
            <Form.Check
              type="switch"
              label="USPS Mail"
              defaultChecked={prospect.can_mail}
              onClick={handleContactPrefs}
              name="can_mail"
            />
            <Form.Check
              type="switch"
              label="Emails"
              name="can_email"
              defaultChecked={prospect.can_email}
              onClick={handleContactPrefs}
            />
            <Form.Check
              type="switch"
              label="Knock"
              name="can_knock"
              defaultChecked={prospect.can_knock}
              onClick={handleContactPrefs}
              className="mb-2"
            />
            Preferred Language
            <Select
              aria-label="Set Preferred Language"
              className="react-select-container mb-3"
              classNamePrefix="react-select"
              defaultValue={formLang}
              placeholder="Select..."
              onChange={e => setFormLang({...formLang, language_preference: e.value})}
              options={langOptions}
            />
            <Row>
              <Button variant="primary" type="submit" onClick={updateContact}>
                Update Preferences
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
      }
      </Container>
    </React.Fragment>
  )
}

export default ProspectPreferences
