/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Modal, Button } from 'react-bootstrap'
import { getAllTraits, addMemberTrait, deleteMemberTrait } from '../../../apis/member'
import ReactTableBasic from '../../ReactTableBasic'
import useUser from '../../../hooks/useUser'
import useToast from '../../../hooks/useToast'
import Select from 'react-select'
import { isEmpty } from 'lodash'

const TraitsModal = ({ member, memberTraits, show, handleClose, setMemberTraits }) => {
  const [allTraits, setAllTraits] = useState([])
  const defaultSelectValue = {label: 'Choose from the following traits', value: ''}
  const [selectedTrait, setSelectedTrait] = useState(defaultSelectValue)
  const [reason, setReason] = useState('')
  const { userInfo } = useUser()
  const { handleAlert } = useToast()

  useEffect(() => {
    getAllTraits(setAllTraits)
  }, [])

  const handleDeleteTrait = (row) => {
    deleteMemberTrait(member.prospect_guid, row.trait_code, setMemberTraits, handleAlert)
  }

  const handleAddTrait= () => {
    const payload = {
      prospect_guid: member.prospect_guid,
      trait_code: selectedTrait.value,
      reason: reason,
      user_id: userInfo.user_id
    }
    addMemberTrait(payload, setMemberTraits, handleAlert)
    setSelectedTrait(defaultSelectValue)
    setReason('')
  }

  const modalTableColumns = [
    {
      Header: 'Trait',
      accessor: 'trait_name',
    },
    { Header: 'Reason',
      accessor: 'reason',
    },
    { Header: '',
      accessor: 'trait_code',
      width: 90,
      Cell: (props) => (
        <div className="text-center">
          <Button variant="danger" onClick={() => handleDeleteTrait(props.row.original)}>
            Delete
          </Button>
        </div>
      )
    }
  ]

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton><h3 className='mb-0'>Traits</h3></Modal.Header>
        <Modal.Body>
        {isEmpty(memberTraits)
        ? <p>No traits exist for this member</p>
        : <ReactTableBasic
            title="Member Traits"
            columns={modalTableColumns}
            data={memberTraits}
            canHover={false}
          />
        }
          <Row className="my-3">
            <h4>Add a new trait to this member</h4>
          </Row>
          <Row className="my-3">
            <Col>
              <Select
                aria-label="Traits select"
                className="react-select-container"
                classNamePrefix="react-select"
                data-testid="traits-select"
                placeholder="Choose from the following traits"
                onChange={(e) => setSelectedTrait(e)}
                value={selectedTrait}
                options={allTraits.filter(trait => trait.trait_code !== "INELIGIBLE_ACCOUNT").map(trait => ({value: trait.trait_code, label: trait.trait_name}))}
                />
            </Col>
            <Col>
              <Form.Control
                type="text"
                name="reason"
                onChange={(e) => setReason(e.target.value)}
                placeholder="Reason"
                value={reason}
              />
            </Col>
            <Col>
              <Button
                disabled={selectedTrait === defaultSelectValue || reason.length === 0}
                onClick={() => handleAddTrait()}
              >
                Add Trait
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TraitsModal
