import React from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { UserProvider } from "./contexts/UserContext";
import { ToastProvider } from "./contexts/ToastContext";
import { Auth0Provider } from '@auth0/auth0-react'
import "./i18n";
import routes from "./routes";

const App = () => {
  const content = useRoutes(routes);
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Inspire CRM"
        defaultTitle="Inspire CRM"
      />
      <ThemeProvider>
        <SidebarProvider>
          <LayoutProvider>
            <UserProvider>
              <Auth0Provider
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                redirectUri={window.location.origin+'/callback'}
                responseType="token id_token"
                scope="openid profile email offline_access"
              >
                <ToastProvider>
                  {content}
                </ToastProvider>
              </Auth0Provider>
            </UserProvider>
          </LayoutProvider>
        </SidebarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
