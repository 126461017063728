import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img alt="logo" className="w-25 p-2" src="/inspire_logo_white.png" />
            <span className="align-middle logo h1 me-3">Glow</span>
          </a>
          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
