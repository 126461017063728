import React from 'react'
import { Container, Card } from 'react-bootstrap'
import PlanTerms from './PlanTerms'
import PlanDetails from './PlanDetails'
import AccountCorrespondence from '../accountCorrespondence/AccountCorrespondence'

const PlanContainer = ({ account }) => {
  return (
    <div>
      <Card>
          <Card.Body >
          <Card.Title tag="h3">
            Plan Details
          </Card.Title>
          <hr />
          <PlanDetails account={account} />
          <Container className="mt-4">
            <PlanTerms offerHistories={account.offer_histories} />
          </Container>
          <hr />
          <Container className="mt-4">
            <AccountCorrespondence account={account}/>
          </Container>
        </Card.Body>
      </Card>
    </div>
  )
}

export default PlanContainer
