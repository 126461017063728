import React, { useState, useEffect } from 'react'
import { Card, Col } from 'react-bootstrap'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature, faUserHeadset, faHashtag, faCalendar, faLampStreet, faBrowser, faPenToSquare } from '@fortawesome/pro-light-svg-icons'
import { Info } from 'react-feather'
import { updateCaseOrder } from '../../../apis/caseEdits'
import isEmpty from 'lodash/isEmpty'
import { DateTime } from 'luxon'
import { getOrdersList } from '../../../apis/orders'
import { v4 as uuidv4 } from 'uuid'
import useToast from '../../../hooks/useToast'
import useUser from '../../../hooks/useUser'

const AccountInfoCard = ({caseDetails, reloadCase}) => {
  const [showOrderDropDown, setShowOrderDropDown] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState({label: '', value: ''})
  const [orders, setOrders] = useState([])
  const { userInfo } = useUser()
  const { handleAlert } = useToast()

  useEffect(() => {
    if(caseDetails?.account_id){
      getOrdersList(caseDetails.account_id, setOrders)
    }
  }, [caseDetails])

  const matchAccountNumber = () => {
    return caseDetails.accounts.find(account => account.account_id === caseDetails.account_id)?.account_number
  }

  const orderOptions = () => {
    return orders.map(order => {
      return {
        label: `Order ID: ${order.order_id} sold on ${DateTime.fromISO(order.sale_dt).toLocaleString(DateTime.DATE_MED)}`,
        value: order.order_id
      }
    })
  }

  const accountPage = () => {
    const matchedAccount = caseDetails.accounts.find(account => account.account_id === caseDetails.account_id)
    if(matchedAccount) {
      return (
      <a href={`/profiles/${matchedAccount.membership_profile_id}/accounts/${matchedAccount.account_id}`} target="_blank" rel="noreferrer">
        {matchedAccount.first_name} {matchedAccount.last_name}
      </a>
      )
    } else {
      return <p>No Account Information Available</p>
    }
  }

  const handleSelectOrder = (e) => {
    setSelectedOrder(e)
    const payload = {
      order_id: e.value,
      case_guid: caseDetails.case_guid,
      user_id: userInfo.user_id
    }
    updateCaseOrder(payload, handleAlert, reloadCase)
    setShowOrderDropDown(false)
  }


  return(
    <Card className="flex-fill mx-3">
      <Card.Body className="py-4">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            { !caseDetails?.case_guid ?
              <h3 className="mb-4">No Account Information</h3>
            :
              <>
                <h3 className="mb-4">Account Information</h3>
                <Col>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                    <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faHashtag} fixedWidth/>
                    Account ID: {caseDetails.account_id || 'N/A'}
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faLampStreet} fixedWidth/>
                      Account Identifier: {caseDetails.account_id ? matchAccountNumber() : 'N/A'}
                    </li>
                    {!isEmpty(caseDetails.accounts) &&
                      <>
                        <li className="mb-1">
                          <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faBrowser} fixedWidth/>
                          Account: {accountPage()}
                        </li>
                        <li className="mb-1">
                        <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faFileSignature} fixedWidth/>
                        Associated Accounts:
                          <ul className="list-unstyled">
                            {caseDetails.accounts?.map(account =>
                              <li className="mx-4" key={uuidv4()} >
                                <a href={`/profiles/${account.membership_profile_id}/accounts/${account.account_id}`}>Account ID: {account.account_id}</a>
                              </li>
                            )}
                          </ul>
                        </li>
                      </>
                    }
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faCalendar} fixedWidth/>
                      Enrollment Date: { caseDetails.order_sale_dt ? DateTime.fromISO(caseDetails.order_sale_dt).toLocaleString(DateTime.DATE_MED) : 'No sale date available'}
                    </li>
                    <li className="mb-2">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faUserHeadset} fixedWidth/>
                      Order ID: {caseDetails.order_id || 'N/A'}
                      <FontAwesomeIcon
                        title="edit-order-icon"
                        style={{cursor: 'pointer'}}
                        size="sm"
                        className="ms-2 me-1"
                        color="#025169"
                        icon={faPenToSquare}
                        fixedWidth
                        onClick={() => setShowOrderDropDown(true)}
                      />
                    </li>
                    {showOrderDropDown &&
                      <Select
                        className="react-select-order"
                        aria-label="Associated Order"
                        size="sm"
                        options={orderOptions()}
                        onChange={(e) => handleSelectOrder(e)}
                        value={selectedOrder}
                      />
                    }
                  </ul>
                </Col>
              </>
            }
          </div>
          <div className="stat">
            <Info className="align-middle text-success" />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default AccountInfoCard
