import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet-async"
import { useParams } from 'react-router-dom'
import { getMembership } from '../../apis/member'
import { Card, Col, Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouseChimneyUser } from '@fortawesome/pro-duotone-svg-icons'
import { faCircle, faPhone, faAt, faGlobe } from "@fortawesome/pro-light-svg-icons"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"
import LinkedAccounts from '../../components/member/LinkedAccounts'
import LinkedProspects from '../../components/member/LinkedProspects'
import { isEmpty } from 'lodash'

const Member = () => {
  const [member, setMember] = useState({})
  const { membership_profile_id } = useParams()
  const [accountId, setAccountId] = useState(null)

  useEffect(() => {
    if (membership_profile_id) {
      getMembership(membership_profile_id, setMember)
    }
  }, [membership_profile_id])

  useEffect(() => {
    if(!isEmpty(member.accounts)) {
      setAccountId(member.accounts[0].account_id)
    }
  }, [member.accounts])

  return (
    <React.Fragment>
      <Helmet title='Member Info' />
      <Container fluid className="p-0">
        <h1>Member</h1>
        { member.membership_profile_id &&
          <Card>
            <Card.Header>
              <Card.Title className="h2 mb-0">
                <Row>
                  <Col md="5">
                    <h2> {member.first_name} {member.last_name}</h2>
                    <h5 className="mb-3"> (Last updated <Link to="">{DateTime.fromISO(member.updated_at).toLocaleString(DateTime.DATETIME_MED)}</Link>)</h5>
                  </Col>
                  <Col md="5">
                    <Row>
                      <ul className="list-unstyled">
                        <li className="h4 mb-2">
                          <FontAwesomeIcon size="lg" className="me-1" color="#ffb002" icon={faPhone} fixedWidth />
                          <span>{member.primary_phone?.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}</span>
                        </li>
                        <li className="h4 mb-2">
                          { member.email &&
                          <span>
                            <FontAwesomeIcon size="lg" className="me-1" color="#ffb002" icon={faAt} fixedWidth />
                            {member.email.email_address}
                          </span>
                          }
                        </li>
                        {member.prospect &&
                          <li className="h4 mb-2">
                          <FontAwesomeIcon size="lg" className="me-1" color="#ffb002" icon={faGlobe} fixedWidth />
                          <span> {member.prospect.language_preference}</span>
                        </li>
                        }
                      </ul>
                    </Row>
                  </Col>
                  <Col md="2">
                    <div className="text-center">
                      <span className="text-center card-img-bottom fa-layers fa-fw" style={{marginRight: "-2.75rem !important", marginTop:"1.75rem"}}>
                        <FontAwesomeIcon icon={faCircle} color="#ffb002" size="5x" />
                        <FontAwesomeIcon icon={faHouseChimneyUser} color="#ffb002" fixedWidth size="3x" />
                      </span>
                    </div>
                  </Col>
                </Row>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <hr />
              <h3>Linked Accounts</h3>
              <LinkedAccounts accountId={accountId} />
              <hr />
              <h3>Linked Prospects</h3>
              <LinkedProspects />
            </Card.Body>
          </Card>
        }

      </Container>
    </React.Fragment>
  )
}

export default Member
