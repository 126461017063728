import React from 'react'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import useLocalStorage from '../hooks/useLocalStorage'

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter, id },
}) => {
  const count = preFilteredRows.length
  const [inputFilters, setInputFilters] = useLocalStorage(`${id}`, '')

  return (
    <Form.Control
      value={inputFilters || ""}
      onChange={(e) => {
        setInputFilters(e.target.value)
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} results...`}
      className="mt-2"
    />
  )
}

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // id here is the column header ex: 'case_type_description' 'category_name'
  // preFilteredRows is all the rows
  // row.values is basically the same as row.original (it is a single case's details)
  // row.values[id] gets the column header info from the row
  // line 41-42 is checking to make sure the value isn't already an option in the multi-select options array
  const [selectFilters, setSelectFilters] = useLocalStorage(`${id}`, [])
  const options = React.useMemo(() => {
    return preFilteredRows.reduce((prevValue, row) => {
      let rowValueObject = {label: row.values[id], value: row.values[id]}
      if (!prevValue.some(option => option?.value === rowValueObject.value)) {
        prevValue.push(rowValueObject)
      }

      return prevValue
    }, [])
  }, [id, preFilteredRows])

  return (
    <Select
      id="custom-select"
      size="sm"
      isMulti
      value={selectFilters}
      options={options}
      onChange={(e) => {
        setFilter(e || undefined) // Set undefined to remove the filter entirely
        setSelectFilters(e)
      }}
    />
  )
}
