import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { getContactDispositions, addNewContact } from '../../../apis/contacts'
import Select from 'react-select'
import useUser from '../../../hooks/useUser'

const AddContactModal = ({ handleClose, member, show  }) => {

  const [contactDispositions, setContactDispositions] = useState([])
  const [contactType, setContactType] = useState('')
  const [selectedDisposition, setSelectedDisposition] = useState({})
  const [comment, setComment] = useState('')
  const { userInfo } = useUser();

  useEffect(() => {
    getContactDispositions(setContactDispositions)
  }, [])

  const contactTypes = [
    {value: 'inbound - offline', label: 'inbound - offline'},
    { value: 'mail', label: 'mail'},
    { value: 'facebook', label: 'facebook'},
    { value: 'twitter', label: 'twitter'},
    { value: 'escalation callback', label: 'escalation callback'}
  ]

  const dispositionOptions = () => {
    return contactDispositions.map(disposition => {
      return { value: disposition.disposition_code, label: disposition.disposition_desc }
    })
  }

  const handleSubmitContact = async() => {
    const newContact = {
      prospect_guid: member.membership_profile.prospect_guid,
      comments: comment,
      disposition_code: selectedDisposition.value,
      type_name: contactType.value,
      source_code: userInfo.source_code ?? null,
      user_name: userInfo.email
    }
    const response = await addNewContact(newContact)
    if(response.status === 200){
      handleClose()
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Add Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { contactDispositions.length > 0 &&
            <div>
              <Row>
                <Col md={6} className="text-center">
                  <Select
                    aria-label="Contact Type Options"
                    size="med"
                    options={contactTypes}
                    onChange={(e) => setContactType(e)}
                    value={contactType}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#B0DBE9',
                        primary: '#0E7C9E',
                      },
                    })}
                  />
                </Col>
                <Col md={6} className="text-center">
                  <Select
                    aria-label="Contact Disposition Options"
                    size="med"
                    options={dispositionOptions()}
                    onChange={(e) => setSelectedDisposition(e)}
                    value={selectedDisposition}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#B0DBE9',
                        primary: '#0E7C9E',
                      },
                    })}
                  />
                </Col>
              </Row>
              <Row>
                <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Add comments for this contact:</Form.Label>
                  <Form.Control as="textarea" rows={3}  value={comment} onChange={(e) => setComment(e.target.value)}/>
                </Form.Group>
              </Row>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className="col-12" aria-label="Submit New Contact" variant="success" onClick={handleSubmitContact}>
            Add New Contact
          </Button>
          <Button className="col-12" aria-label="Close modal" variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddContactModal
