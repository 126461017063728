import axios from 'axios'
import { DateTime } from 'luxon'

export const getEligibleRewardsForAccount = async(accountId, callback) => {
  const today = DateTime.local().startOf('day').toISO()
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/rewards/${accountId}`)
    const eligibleRewards = response.data.filter(reward => {
      return reward.refundable_in_dollars && today > reward.vest_dt
    })
    callback(eligibleRewards)
  } catch (error) {
    throw error
  }
}

export const getAllRewardsForAccount = async(accountId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/rewards`)
    callback(response.data.all_account_rewards)
  } catch (error) {
    throw error
  }
}

export const getRewardsByUnitForAccount = async(accountId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/rewards`)
    callback(response.data.account_rewards_by_unit)
  } catch (error) {
    throw error
  }
}

export const getAccountPayableBills = async (accountId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/account_payable_bills`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const voidAndReissueCheck = async (params, handleAlert) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/${params.accountId}/account_payable_bills/${params.accountPayableBillId}/void_and_reissue`, params.billReissueInfo)
    handleAlert('Bill has been successfully voided and reissued', true)
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}


export const resendReward = async (params, callback, handleAlert) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/rewards/${params.reward_guid}/resend`, params)
    callback('RESENT')
    handleAlert('Reward has been successfully resent', true)
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}


export const redeemReward = async (params, callback, handleAlert) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/rewards/${params.reward_guid}/redeem`, params)
    callback('REDEEMED')
    handleAlert('Reward has been successfully redeemed', true)
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const getReferralProgram = async (accountId, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/referrals`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting the referral program: ${error}`, false)
    throw error
  }
}
