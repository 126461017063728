import React from "react";
import { Info } from "react-feather";
import { Card, Col, Row, Badge } from "react-bootstrap"
import { DateTime } from "luxon"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcaseArrowRight, faFileSignature, faHashtag, faStore, faUserHeadset } from "@fortawesome/pro-light-svg-icons"

const SalesInfo = ({ account, order }) => (

  <Row>
    <Col md="6" xl className="d-flex">
      <Card className="flex-fill">
        <Card.Body className=" py-4">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h3 className="mb-4">Sales Information</h3>
              <Col>
                <ul className="list-unstyled">
                  <li className="mb-1">
                    <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faHashtag} fixedWidth/>
                    Member Number:&nbsp;{account.member_number}
                  </li>
                  <li className="mb-1">
                    <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faFileSignature} fixedWidth/>
                    Signed up on:&nbsp; <Badge bg="salmonLight" text="black" className="me-2">{DateTime.fromISO(order.sale_dt).toLocaleString(DateTime.DATE_MED)}</Badge>
                  </li>
                  <li className="mb-2">
                    <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faBriefcaseArrowRight} fixedWidth/>
                    Via:&nbsp;{order.source_description}
                  </li>
                  <li className="mb-2">
                    <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faUserHeadset} fixedWidth/>
                    Sales Agent:&nbsp; <Badge bg="salmonLight" text="black" className="me-2">{order.agent_id} {order.agent_name}</Badge>
                  </li>
                  <li>
                    <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faStore} fixedWidth/>
                    Office Code:&nbsp; {order.sales_agent?.office_code ? <Badge bg="salmonLight" text="black">{order.sales_agent?.office_code}</Badge> : <Badge bg="" className="badge-soft-success me-2"> No Office Code Available</Badge>}
                  </li>
                </ul>
              </Col>
            </div>
            <div className="d-inline-block ms-3">
              <div className="stat">
                <Info className="align-middle text-success" />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
)

export default SalesInfo
