import {
  faTrophy,
  faSmile,
  faHeart,
  faUser,
  faAddressBook,
  faSearch,
  faBriefcase,
  faGift,
  faLightbulb,
  faBullhorn,
  faFlask,
  faBolt,
  faThermometer,
  faPlug,
  faFrown,
  faFire,
  faGamepad,
  faFaceMehBlank,
  faDesktop,
  faBan
} from '@fortawesome/pro-light-svg-icons'

export const traitsIconParser = (icon) => {
  if (!icon) { return }
  switch(icon) {
    case 'fa fa-trophy text-warning':
      return faTrophy
    case 'fa fa-heart text-danger':
      return faHeart
    case 'fa fa-smile text-success':
      return faSmile
    case 'fa fa-briefcase text-warning':
      return faBriefcase
    case 'fa fa-gift text-success':
      return faGift
    case 'fa fa-address-card text-success':
      return faAddressBook
    case 'fa fa-bullhorn text-info':
      return faBullhorn
    case 'fa fa-lightbulb text-success':
      return faLightbulb
    case 'fa fa-bullhorn text-success':
      return faBullhorn
    case 'fa fa-neutral-o text-success':
      return faFaceMehBlank
    case 'fa fa-flask':
      return faFlask
    case 'fa fa-search text-info':
      return faSearch
    case 'fa fa-thermometer-three-quarters text-danger':
      return faThermometer
    case 'fa fa-bolt text-primary':
      return faBolt
    case 'fa fa-plug text-success':
      return faPlug
    case 'fa fa-frown-o text-success':
      return faFrown
    case 'fa fa-fire text-info':
      return faFire
    case 'fa fa-gamepad text-warning':
      return faGamepad
    case 'fa fa-desktop text-success':
      return faDesktop
    case 'fa fa-ban text-danger':
      return faBan
    default:
      return faUser
  }
}
