import React from 'react'
import ReactTableBasic from  '../../ReactTableBasic'
import { DateTime } from 'luxon'

export const CheckHistory = ({bill}) => {

  const tableColumns = [
    {
      Header: 'Status',
      accessor: 'check_status',
    },
    {
      Header: 'Note',
      accessor: 'note',
    },
    {
      Header: 'Change Date',
      accessor: 'created_at',
      Cell: (e) => (
        e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      )
    }
  ]

  return (
    <div className="mt-4">
      <div className="mt-3 mb-5">
        <ReactTableBasic
          title="Check History"
          columns={tableColumns}
          data={bill.histories}
          canHover={false}
        />
      </div>
    </div>
  )
}

export default CheckHistory
