/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Card } from "react-bootstrap"
import { dateTimeUTC } from '../../utils/dateTimeUTC'
import { getWinback } from '../../apis/offers'
import { getUtility } from '../../apis/utilities'
import useToast from '../../hooks/useToast'
import { DateTime } from 'luxon'

const AccountDropInformation = ({ account }) => {
  const [winbackAccount, setWinbackAccount] = useState({})
  const [utility, setUtility] = useState({})
  const { handleAlert } = useToast()

  useEffect(() => {
     getWinback(account.account_id, setWinbackAccount)
     getUtility(account.market?.utility_code, setUtility, handleAlert)
  }, [account])

  const servicePeriod = winbackAccount?.confirmed_start_dt ?
                          winbackAccount?.confirmed_drop_dt ?
                            `${dateTimeUTC(winbackAccount?.confirmed_start_dt).toLocaleString(DateTime.DATE_SHORT)}  -  ${dateTimeUTC(winbackAccount?.confirmed_drop_dt).toLocaleString(DateTime.DATE_SHORT)}`
                          :
                            `${dateTimeUTC(winbackAccount?.confirmed_start_dt).toLocaleString(DateTime.DATE_SHORT)}  - Date Unavailable`
                        : 'Service Period Dates Unavailable'


  return (
    <Card.Body>
      <Card.Title tag="h5">Drop Information</Card.Title>
      <ul className="list-unstyled mb-0">
        <li className="d-flex mb-1">
          <b>Drop Reason: &nbsp;</b>
          {winbackAccount?.drop_reason || 'Not Available'}
        </li>
        <li className="d-flex mb-1">
          <b>Drop Requested Date:&nbsp;</b>
          {dateTimeUTC(winbackAccount?.drop_dt).toLocaleString(DateTime.DATE_SHORT) || 'Not Available'}
        </li>
        <li className="d-flex mb-1">
          <b>Drop Date: &nbsp;</b>
          {dateTimeUTC(winbackAccount?.confirmed_end_dt).toLocaleString(DateTime.DATE_SHORT) || 'Not Available'}
        </li>
        <li className="d-flex mb-1">
          <b>Service Period: &nbsp;</b>
          {servicePeriod}
        </li>
        {account.upcoming_rate ?
          <li className="d-flex mb-1">
            <b>Upcoming Rate: &nbsp;</b>
            {account.upcoming_rate}/{utility?.native_unit_of_measure} - {account.upcoming_rate_source_type}
          </li>
        :
          <li className="d-flex mb-1">
            <b>No Upcoming Rate Information Available</b>
          </li>
        }
      </ul>
    </Card.Body>
  )
}

export default AccountDropInformation;

