import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const initialState = {
  avatar: '',
  created_at: '',
  email: 'test@inspirecleanenergy.com',
  external_id: 'test@inspirecleanenergy.com',
  first_name: 'Test',
  is_active: true,
  last_name: 'User',
  last_sign_in_at: '',
  last_sign_in_ip: '',
  permissions: [],
  phone: '',
  roles: [],
  sign_in_count: 0,
  slack_username: '',
  source: {},
  source_code: 'INSPIRE',
  telephony_id: '',
  updated_at: '',
  user_id: 1,
  xref_id: ''
};

const UserContext = React.createContext(initialState);

function UserProvider({ children }) {
  const [userInfo, setUser] = useLocalStorage('user', initialState);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext };
