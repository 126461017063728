/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { getShopper } from '../../apis/shoppers'
import { getOffer } from '../../apis/offers'
import OfferCardBody from '../../components/offers/OfferCardBody'
import OrderVerificationForm from '../../components/orders/OrderVerificationForm'
import useToast from '../../hooks/useToast'

const OrderVerification = () => {
  const { shopper_short_key } = useParams()
  const [loading, setLoading] = useState(true)
  const [shopperData, setShopperData] = useState(null)
  const [selectedOffer, setSelectedOffer] = useState(null)
  const { handleAlert } = useToast()

  useEffect(() => {
    if (shopper_short_key) {
      getShopper(shopper_short_key, setShopperData)
      setLoading(false)
    }
  }, [shopper_short_key])

  useEffect(() => {
    if (shopperData && shopperData[0].offer_code) getOffer(shopperData[0].offer_code, setSelectedOffer, handleAlert)
  }, [shopperData])

  const shopperUrl = `${process.env.REACT_APP_CUST_SITE}/join/${shopper_short_key}`

  return (
    <>
      <Helmet title="Order Verification" />
      <h1>Order Verification</h1>
      <Row xs={1} sm={1} md={2} style={{ maxWidth: '1000px' }}>
        <Col md={4}>
          {selectedOffer ?
            <Card data-testid="offer-card">
              <OfferCardBody offer={selectedOffer} />
              <Card.Footer>Continue Clean Order Online: <a href={shopperUrl}>{shopperUrl}</a></Card.Footer>
            </Card>
            : <Card data-testid="offer-card">
              <Card.Body>Continue Clean Order Online: <a href={shopperUrl}>{shopperUrl}</a></Card.Body>
            </Card>
          }
        </Col>
        <Col md={8}>
          {loading ?
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner> :
            <>{shopperData?.length && <OrderVerificationForm shopper={shopperData[0]} />}</>
          }
        </Col>
      </Row>
    </>
  )
}

export default OrderVerification
