/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { Col, Container, Row, Form, Button, Spinner } from "react-bootstrap"
import { getAccount, updateContactEmail, updateContactName, updatePrimaryPhone } from '../../apis/accounts'
import { addPhone, getProspect, updateContactPrefs } from '../../apis/prospects'
import useUser from '../../hooks/useUser'
import { isEmpty } from 'lodash'
import Phones from './Phones'
import useToast from '../../hooks/useToast'

const ContactPreferences = ({ member, memberAccount }) => {
  const accountId = memberAccount.account_id
  const [account, setAccount] = useState(memberAccount)
  const [prospect, setProspect] = useState({})
  const [formFirstName, setFormFirstName] = useState(member.first_name)
  const [formLastName, setFormLastName] = useState(member.last_name)
  const [formPrefs, setFormPrefs] = useState({})
  const [formLang, setFormLang] = useState({})
  const [formPhone, setFormPhone] = useState('')
  const [formPhoneType, setFormPhoneType] = useState({})
  const [prospectGuid, setProspectGuid] = useState('')
  const [reloadAccount, setReloadAccount] = useState(false)
  const [formEmail, setFormEmail] = useState(memberAccount.email)
  const { handleAlert } = useToast()
  const { userInfo } = useUser()

  const langOptions = [{
    value: "ENGLISH",
    label: "ENGLISH",
  },{
    value: "SPANISH",
    label: "SPANISH",
  },{
    value: "MANDARIN",
    label: "MANDARIN",
  }]

  const phoneOptions = [{
    value: "mobile",
    label: "Mobile",
  },{
    value: "home",
    label: "Home",
  }, {
    value: "work",
    label: "Work",
  }]

  useEffect(() => {
    if(!isEmpty(member.prospect)) {
      setProspectGuid(member.prospect.prospect_guid)
      getProspect(member.prospect.prospect_guid, setProspect)
    }
  }, [member])

  useEffect(() => {
    getAccount(accountId, setAccount)
  }, [reloadAccount])

  useEffect(() => {
    if (prospect.prospect_guid) setFormLang(langOptions.filter(option => option.value === prospect?.language_preference)[0])
  }, [prospect])

  const handleContactPrefs = (e) => {
    setFormPrefs({...formPrefs, [e.target.name]: e.target.checked})
  }

  const updateName = async (e) => {
    if (isEmpty(formFirstName) || isEmpty(formLastName)) {
      handleAlert('Please enter a first AND last name.', false)
    } else {
    const newName = {first_name: formFirstName, last_name: formLastName}
    await updateContactName(accountId, newName, userInfo, handleAlert)
    setReloadAccount(!reloadAccount)
    }
  }

  const updateContact = (e) => {
    const newPrefs = {...formPrefs, language_preference: formLang.value}
    updateContactPrefs(prospect.prospect_guid, newPrefs, handleAlert)
  }

  const updateEmail = async (e) => {
    const emailValid = formEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    if (!emailValid) {
      handleAlert(`Email is invalid.`, false)
    } else {
      await updateContactEmail(accountId, formEmail, userInfo, handleAlert)
      setReloadAccount(!reloadAccount)
    }
  }

  const handleNewPhone = async (e) => {
    if (!account.primary_phone) {
      // on the backend, the method called from this endpoint will add a new prospect phone
      // in addition to setting this number to the primary_phone
      const payload = {
        phone: formPhone,
        membership_profile_id: memberAccount.membership_profile_id
      }
      await updatePrimaryPhone(accountId, payload, userInfo, handleAlert)
    } else {
      const newPhonePayload = {phone: formPhone, phone_type: formPhoneType}
      await addPhone(prospectGuid, newPhonePayload, handleAlert)
      setReloadAccount(!reloadAccount)
      setFormPhone('')
      setFormPhoneType({})
    }
  }

  return (
    <Container className='ms-0'>
      <h1>Contact Preferences</h1>
      <br />
      {member &&
        <>
          <Row className='mb-6'>
            {accountId &&
              <>
              <Col md="4" className="ps-5">
                <Form.Label>Name</Form.Label>
                <Row>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    size="md"
                    className="mb-3"
                    onChange={e => setFormFirstName(e.target.value)}
                    value={formFirstName}
                  />
                </Row>
                <Row>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    size="md"
                    className="mb-3"
                    onChange={e => setFormLastName(e.target.value)}
                    value={formLastName}
                  />
                </Row>
                <Row>
                  <Button onClick={updateName}>Update Name</Button>
                </Row>
              </Col>
              <Col md="4" className="ps-5">
                <Row>
                  <Form.Group>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      size="md"
                      className="mb-3"
                      value={formEmail}
                      disabled={!accountId}
                      onChange={(e) => setFormEmail(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mx-1">
                  <Button variant="primary" onClick={updateEmail}>Update Email</Button>
                </Row>
              </Col>
              </>
            }
            {!prospect.prospect_guid ?
            <Col md="4"className="px-5">
              <Spinner variant="primary" role="status" animation="border" />
            </Col>
            :
            <Col md="4" className="px-5">
              <Form.Check
                type="switch"
                label="Calls"
                onClick={handleContactPrefs}
                data-testid="can-call"
                name="can_call"
                defaultChecked={prospect.can_call}
              />
              <Form.Check
                type="switch"
                label="Texts"
                defaultChecked={prospect.can_text}
                onClick={handleContactPrefs}
                data-testid="can-text"
                name="can_text"
              />
              <Form.Check
                type="switch"
                label="USPS Mail"
                defaultChecked={prospect.can_mail}
                onClick={handleContactPrefs}
                data-testid="can-mail"
                name="can_mail"
              />
              <Form.Check
                type="switch"
                label="Emails"
                name="can_email"
                data-testid="can-email"
                defaultChecked={prospect.can_email}
                onClick={handleContactPrefs}
              />
              <Form.Check
                type="switch"
                label="Knock"
                name="can_knock"
                data-testid="can-knock"
                defaultChecked={prospect.can_knock}
                onClick={handleContactPrefs}
                className="mb-2"
              />
              Preferred Language
              <Select
                aria-label="Set Preferred Language"
                className="react-select-container mb-3"
                classNamePrefix="react-select"
                value={formLang}
                placeholder="Select..."
                onChange={e => setFormLang(e)}
                options={langOptions}
              />
              <Row>
                <Button variant="primary" onClick={updateContact}>
                  Update Preferences
                </Button>
              </Row>
            </Col>
            }
          </Row>
          <Row className="mt-2">
            <Col>
              {prospectGuid &&
                <Phones account={account} reloadAccount={reloadAccount} setReloadAccount={setReloadAccount} />
              }
            </Col>
            <Col>
            <Form.Group>
              <Row>
                <Col className="ms-2">
                  <Form.Label>Add New Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                    size="lg"
                    className="mb-3"
                    value={formPhone}
                    onChange={(e) => setFormPhone(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Label className='mb-2'>Phone Type</Form.Label>
                  <Select
                    name="phoneType"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={phoneOptions}
                    onChange={e => setFormPhoneType(e.value)}
                  />
                </Col>
              </Row>
              <Row className="m-1">
                <Button onClick={(e) => handleNewPhone(e)}>Add Phone Number</Button>
              </Row>
              </Form.Group>
            </Col>
          </Row>
        </>
      }
    </Container>
  )
}

export default ContactPreferences
