import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { Card, Spinner } from 'react-bootstrap'
import { getShopper } from '../../apis/shoppers'
import { submitOrder } from '../../apis/orders'
import OrderPending from '../../components/orders/OrderPending'
import OrderSuccess from '../../components/orders/OrderSuccess'
import dino from '../../assets/img/dino.gif'
import useToast from '../../hooks/useToast'
import useUser from '../../hooks/useUser'

const OrderConfirmation = () => {
  const { shopper_short_key } = useParams()
  const [loadingShopper, setLoadingShopper] = useState(true)
  const [shopper, setShopper] = useState(null)
  const [order, setOrder] = useState(null)
  const [submittingOrder, setSubmittingOrder] = useState(false)
  const { handleAlert } = useToast()
  const { userInfo } = useUser()

  useEffect(() => {
    getShopper(shopper_short_key, setShopper)
      .then(() => {
        setLoadingShopper(false)
      })
  }, [shopper_short_key])

  const setOrderRequestPayload = (shopper) => {
    return {
      shopper_guid:
        shopper.shopper_guid,
      prospect_guid:
        shopper.prospect_guid,
      sold_by_user_id:
        userInfo.external_id,
      agent_id: userInfo.external_id,
      original_agent_id: userInfo.external_id,
      source_code: shopper.source_code || userInfo.source_code,
      offer_code: shopper.offer_code,
      market_code: shopper.market_code,
      first_name: shopper.first_name,
      last_name: shopper.last_name,
      email: shopper.email,
      primary_phone: shopper.primary_phone,
      contact_phone_number: shopper.primary_phone,
      channel_code: shopper.channel_code,
      revenue_class_code: shopper.revenue_class_code,
      // we may also need to add service address id here
      service_address_line_1: shopper.service_address_line_1,
      service_address_line_2: shopper.service_address_line_2,
      service_city: shopper.service_city,
      service_state: shopper.service_state,
      service_zip_code: shopper.service_zip_code,
      billing_address_line_1: shopper.service_address_line_1,
      billing_address_line_2: shopper.service_address_line_2,
      billing_city: shopper.service_city,
      billing_state: shopper.service_state,
      billing_zip_code: shopper.service_zip_code,
      account_number: shopper.account_number,
      contact_first_name: shopper.contact_first_name,
      contact_last_name: shopper.contact_last_name,
      contact_relationship: 'Self',
      conversion_medium: 'INSPIRE_CRM',
      ack_can_text: true,
      ack_email_disclosure: true,
      tpv_type: shopper.tpv_type,
      enrollment_type: shopper.enrollment_type
    }
  }

  const placeOrder = () => {
    setSubmittingOrder(true)
    submitOrder(setOrderRequestPayload(shopper[0]), setOrder, handleAlert)
      .finally(() => setSubmittingOrder(false))
  }

  return (
    <>
      <Helmet title="Order" />

      <Confetti
        style={{ position: 'fixed' }}
        width={2000}
        height={2000}
        numberOfPieces={100}
        run={!!order}
      />

      <h1>Order Status <img className="px-2 py-1" style={{ display: `${order ? 'inline-block' : 'none'}`, maxWidth: 300 }} src={dino} alt="Tim Party Dino" /></h1>
      <Card>
        <Card.Body>
          {loadingShopper &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}
          {!loadingShopper && shopper && !order &&
            <OrderPending
              placeOrder={placeOrder}
              shopperFullName={`${shopper[0].first_name} ${shopper[0].last_name}`}
              shopperShortKey={shopper_short_key}
              submittingOrder={submittingOrder}
            />
          }
          {order && <OrderSuccess order={order} prospectGuid={shopper[0].prospect_guid} shopperFullName={`${shopper[0].first_name} ${shopper[0].last_name}`} />}
        </Card.Body>
      </Card>
    </>

  )
}

export default OrderConfirmation
