/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Form, Modal, Button, Spinner } from 'react-bootstrap'
import * as DOMPurify from 'dompurify'
import { getRetentionOffers, changeOffer } from '../../../apis/offers'
import { isEmpty } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import useUser from '../../../hooks/useUser'
import useToast from '../../../hooks/useToast'

const PendingCancellationModal = ({ notification, account, show, handleClose }) => {
  const [loading, setLoading] = useState(true)
  const [offers, setOffers] = useState([])
  const [selectedDropOffer, setSelectedDropOffer] = useState({})
  const { userInfo } = useUser()
  const { handleAlert } = useToast()

  useEffect(() => {
    const getOffersForAccount = async () => {
      const retentionOffers = await getRetentionOffers(account?.account_id)
      setOffers(retentionOffers?.results)
      setLoading(false)
    }

    getOffersForAccount()
  }, [])

  const handleSelectOffer = (e) => {
    const matchedOffer = offers.find(offer => offer.offer_code === e.target.value)
    setSelectedDropOffer(matchedOffer)
  }
  const handleChangeOffer = () => {
    changeOffer(account.account_id, selectedDropOffer, userInfo.email, handleAlert)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton><h3 className='mb-0'>Pending Cancellation</h3></Modal.Header>
        <Modal.Body>
          <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.description) }}></h4>
          <br />
          {loading &&
            <>
              <h3>Loading Eligible Orders</h3>
              <Spinner
                data-testid="spinner-loading"
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                variant="primary"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <br />
            </>
          }
          {!loading &&
            <>
              {!isEmpty(offers)
                ? <Form.Group>
                  <Form.Label>Change Current Account's Offer</Form.Label>
                  <Form.Select
                    onChange={(e) => handleSelectOffer(e)}
                    aria-label="Offers Dropdown"
                  >
                    <option>Keep current offer</option>
                    {offers.map(offer =>
                      <option key={offer.offer_code} value={offer.offer_code}>{offer.offer_desc + ' - ' + offer.rate_friendly}</option>
                    )}
                  </Form.Select>
                </Form.Group>
                : <p>No eligible offers were found for this account</p>
              }
              <br />
              {!isEmpty(selectedDropOffer) &&
                <div>
                  <h4>Promos:</h4>
                  {!isEmpty(selectedDropOffer.plan_promos)
                    ? <ul>
                      {selectedDropOffer.plan_promos.map(promo => (
                        <li key={uuidv4()}>{promo.promo_desc}</li>
                      ))}
                    </ul>
                    : <p>None available</p>
                  }
                  <h4>Loyalty Programs:</h4>
                  {!isEmpty(selectedDropOffer.plan_loyalty_programs)
                    ? <ul>
                      {selectedDropOffer.plan_loyalty_programs.map(loyaltyProgram => (
                        <li key={uuidv4()}>{loyaltyProgram.loyalty_program_desc}</li>
                      ))}
                    </ul>
                    : <p>None available</p>
                  }
                </div>
              }
            </>
          }
          <br />
          <Button disabled={!!isEmpty(selectedDropOffer)} onClick={() => handleChangeOffer()}>Change Offer</Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PendingCancellationModal
