import React from 'react'
import { useParams } from 'react-router-dom'
import Case from '.'

const CasePage = () => {
  const params = useParams()

  return (
    <React.Fragment>
      {params &&
        <Case caseGuid={params.case_guid} />
      }
    </React.Fragment>
  )
}

export default CasePage
