export const validateField = (marketInfo, fieldName, value, requiredField = false) => {
  let _fieldName = requiredField ? 'required' : fieldName
  let fieldValidationError = ''
  switch (_fieldName) {
    case 'account_number':
      if (value?.length > 0 && marketInfo) {
        const accountValid = value?.match(marketInfo.validation_rules.account_number_regexp)
        if (!accountValid) fieldValidationError = (marketInfo.account_num_synonym + ' is invalid')
      }
      break
    case 'email':
      const emailValid = value?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      if (!emailValid) fieldValidationError = 'email is invalid'
      break
    case 'primary_phone':
      const phoneNumberValid = value?.match(/^\d{10}$/)
      if (!phoneNumberValid) fieldValidationError = 'phone number is invalid'
      break
    case 'required':
      const fieldValid = value?.length > 0
      if (!fieldValid) fieldValidationError = `${fieldName.replace(/_/g, ' ')} is required`
      break
    default:
      break
  }
  return fieldValidationError
}
