import axios from 'axios'

export const getMembership = async(membership_profile_id, callback) => {
  try {
    const response = await axios.get (`${process.env.REACT_APP_GARCON_API}/membership_profiles/${membership_profile_id}`)
    callback(response?.data)
  } catch (error) {
    throw error
  }
}

export const getMemberProspects = async(membership_profile_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/membership_profiles/${membership_profile_id}/prospects`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getMemberTraits = async (prospectGuid, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospectGuid}/traits`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getAllTraits = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/prospects/traits`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const addMemberTrait = async (payload, callback, handleAlert) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${payload.prospect_guid}/traits`, payload)
    if (response.status === 200) {
      callback(response.data)
      handleAlert(`Success! ${payload.trait_code} added!`, true)
    }
  } catch (error) {
    handleAlert(`Error! ${error}`, false)
    throw error
  }
}

export const deleteMemberTrait = async (prospectGuid, traitCode, callback, handleAlert) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospectGuid}/traits`, {params: {trait_code: traitCode} })
    if (response.status === 200) {
      callback(response.data)
    } handleAlert(`Success! ${traitCode} deleted!`, true)
  } catch (error) {
    handleAlert(`Error! ${error}`, false)
    throw error
  }
}
