// Winback and Retention scripts
const { DateTime } = require("luxon")
const { isEmpty } = require ("lodash")

export const winbackScripts = ({ account, offer, ptc }) => {
  let promotions = []
  let promoLang = ''
  let rewardLang = ''
  let nyPlanLanguage = ''
  let productType = account.product_type_code === 'NaturalGas' ? 'natural gas' : 'electricity'

  offer.plan_promos && offer.plan_promos.forEach(promo =>  promotions.push('Do you acknowledge that as part of this offer you will receive ' + promo.promo_desc + ' and that complete details of this promotion will be sent to you along with your contract?'))
  if (!isEmpty(offer.plan_promos)) {
    promoLang = promotions
  } else {
    promoLang = ('(No promos)')
  }

  if(!isEmpty(offer.plan_rewards_description)) {
    rewardLang = ('Do you understand that as part of this offer you will receive ' + offer.plan_rewards_description + ' and that complete details of this promotion will be sent to you along with your contract?')
  } else {
    rewardLang = ('(No rewards)')
  }

  if (offer.contract_type_code === 'subscription' && account.service_state === 'NY') {
    nyPlanLanguage = `Monthly Subscription Plan: As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at an introductory ${productType} supply price of ${offer.rate_friendly}.  Is that correct? `
  } else if (offer.contract_type_code === 'fixed' && account.service_state === 'NY') {
    nyPlanLanguage = `Monthly Evergreen Plan: As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at an introductory ${productType} supply price of ${offer.rate_friendly}. Is that correct? `
  }


  switch (account.service_state) {
    case 'MD':
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.
          As a reminder, I don’t work for or represent your local utility. Inspire is licensed by the ${account.service_state} Public Utility Commission as an ${productType} supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `Can you please verify the current date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}and the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill? (Dual Fuel Retail Partial)(${account.first_name + ' ' + account.last_name})`,
        `Can you confirm that you are the ${productType} account holder or are authorized to make the swtich to Inspire`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Please verify your service address listed as ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}`,
        `Great, I’m going to verify the terms of your new contract.`,
        `As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at a fixed ${productType} supply price of ${offer.rate_friendly}. Is that correct?`,
        `The agreement term is ${offer.duration_friendly} months. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `Do you understand that your price with Inspire will be the same regardless of ${productType} usage levels during the Initial Term of your contract, the price is only for ${productType} supply and does not include any taxes, utility distribution charges or any other utility fees, savings are not guaranteed, that Inspire's price is not regulated by the MD Public Service Commission, and that information regarding your current or future pricing information can be received anytime by contacting us?`,
        `Do you understand that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least ${offer.days_notice_before_expiration} days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already recieved are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the delivery of electric power and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} to rescind.`,
        `Note: OPTIONAL Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`,
        `Thank you again for choosing Inspire as your ${productType} supplier. You're all set!	`,
      ];
    case 'IL':
      return [
        `Thank you for continuing your service with Inspire.
          Illinois law requires that I read a disclosure before confirming your plan and information:
          As a reminder, I do not work for or represent ComEd, any utility program, any government body or governmental body program, any consumer group or consumer group program, or any other electric supplier.
          Inspire is an independent seller of electric power and energy service, certified by the Illinois Commerce Commission.
          Inspire is not the same entity as your electric delivery company, and you are not required to enroll with Inspire.
          Beginning on ${DateTime.fromISO(ptc.from_dt).toLocaleString(DateTime.DATE_MED)}, the electric supply price to compare is ${DateTime.fromISO(ptc.rate).toLocaleString(DateTime.DATE_MED)}.
          The electric utility electric supply price will expire on ${DateTime.fromISO(ptc.to_dt).toLocaleString(DateTime.DATE_MED)}. The utility electric supply price to compare does not include the purchased ${productType} adjustment factor.
          The purchased ${productType} adjustment factor may range between +.5 cents and -.5 cents per kilowatt hour. For more information go to the Illinois Commerce Commission free website at www.pluginillinois.org`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `May I please have your name?`,
        `May I please have your name as it appears on your bill? (Dual Fuel Retail Partial)(${account.first_name + ' ' + account.last_name})`,
        `Can you confirm that you are the ${productType} account holder or are authorized to make changes to this ${productType} account?`,
        `Can you confirm that you do not participate in the PIPP or LIHEAP income assistance programs?`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Please verify your service address listed as ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}`,
        `Great, I’m going to verify the terms of your new contract.`,
        `As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at a fixed ${productType} supply price of ${offer.rate_friendly}. Is that correct?`,
        `The agreement term is ${offer.duration_friendly} months. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `This Agreement will automatically renew on the expiration of the estimated billing cycle for the initial term of the contract. Your renewal contract term will begin on the following billing cycle.
          You will be provided with a Notice of Automatic Contract Renewal, not more than 60 days and not less than 30 days before the expiration of this Agreement.
          That notice will advise you of product options that are available to you and the procedure for notifying us if you want to reject contract renewal and terminate your existing contract at the end of its term.
          To cancel your contract, or to opt-out of the automatic renewal, you may contact Inspire Member Support at (866) 403-2620, help@inspirecleanenergy.com, or by chat at www.inspirecleanenergy.com. Do you understand? `,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already recieved are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the delivery of electric power and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 3 days of ComEd's receipt of your enrollment, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} or your utility at 800-334-7661 to rescind. If you have any future issues that you cannot resolve with Inspire, you may contact the ICC Consumer Services Division at 800-524-0795.`,
        `Note: OPTIONAL Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply.
          Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`,
        `Thank you again for choosing Inspire as your ${productType} supplier. You're all set!	`,
      ];
    case 'NY':
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.
          As a reminder, I don’t work for or represent your local utility. Inspire is licensed by the ${account.service_state} Public Utility Commission as an ${productType} supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name`,
        `May I please have your name as it appears on your bill? (Dual Fuel Retail Partial)(${account.first_name + ' ' + account.last_name})`,
        `Can you confirm that you are the ${productType} account holder or are authorized to make changes to this ${productType} account?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Please verify your service address listed as ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}`,
        `Great, I’m going to verify the terms of your new contract.`,
        `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate ${productType} supply service and that you voluntarily wish to choose Inspire as your ${productType} supplier today?`,
        `${nyPlanLanguage}`,
        `The introductory term is ${offer.duration_friendly} months. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `Do you acknowledge that we are required to make you aware of the ESCO Consumer Bill of Rights, that you can find a copy of it on the New York Public Service Commission website,
          and that Inspire will send you a copy of this document by email or mail following the completion of your enrollment and anytime upon your request?`,
        `Do you understand that 30 days prior to the expiration of the Introductory Term, Inspire will send you a renewal notice and that your contract will continue to renew monthly until canceled?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already recieved are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains
          Inspire's charges and any utility charges, they will also remain responsible for the delivery of electric power and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day,
          a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} to rescind.`,
        `Thank you again for choosing Inspire as your ${productType} supplier. You're all set!`
      ];
    default:
      // default is for PA, OH, MA, DE, DC, NJ
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording. As a reminder, I don’t work for or represent your local utility. Inspire is licensed by the ${account.service_state} Public Utility Commission as an electricity supplier, not the same company as your utility, and you are not required to enroll with Inspire.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name`,
        `May I please have your name as it appears on your bill? (Dual Fuel Retail Partial)(${account.first_name + ' ' + account.last_name})`,
        `Can you confirm that you are the ${productType} account holder or are authorized to make changes to this ${productType} account?`,
        `Note: If the customer does not or can not affirm the information, the call should be discontinued.`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Please verify your service address listed as ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}`,
        `Great, I’m going to verify the terms of your new contract.`,
        `As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at a fixed ${productType} supply price of ${offer.rate_friendly}. Is that correct?`,
        `The agreement term is ${offer.duration_friendly} months. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `Do you understand that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least ${offer.days_notice_before_expiration} days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already recieved are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the delivery of electric power and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} to rescind.`,
        `Note: OPTIONAL Before we hang up today, I wanted to ask if you'd like to sign up to receive promotional calls or text messages from Inspire at the number you provided that may be autodialed and/or pre-recorded. Standard carrier charges would apply. Your consent is not required in order to receive Inspire's services, and you can unsubscribe any time by calling us or by replying STOP to any test message.`,
        `Thank you again for choosing Inspire as your ${productType} supplier. You're all set!`
      ];
  }
};

export const retentionScripts = ({ account, offer }) => {
  let promotions = []
  let promoLang = ''
  let rewardLang = ''
  let planLanguage = ''
  let productType = account.product_type_code === 'NaturalGas' ? 'natural gas' : 'electricity'
  let naturalGasRecordingLanguage = `Do you understand that this conversation is being recorded and that oral acceptance of Inspire’s offer is an agreement to initiate natural gas supply service?`

  offer.plan_promos && offer.plan_promos.forEach(promo =>  promotions.push('Do you acknowledge that as part of this offer you will receive ' + promo.promo_desc + ' and that complete details of this promotion will be sent to you along with your contract?'))
  if (!isEmpty(offer.plan_promos)) {
    promoLang = promotions
  } else {
    promoLang = ('(No promos)')
  }

  if (!isEmpty(offer.plan_rewards_description)) {
    rewardLang = ('Do you understand that as part of this offer you will receive ' + offer.plan_rewards_description + ' and that complete details of this promotion will be sent to you along with your contract?')
  } else {
    rewardLang = ('(No rewards)')
  }

  if (offer.contract_type_code === 'subscription') {
    if (!offer.is_intro_subscription) {
      if (account.service_state !== 'NY' && account.service_state !== 'IL') {
        planLanguage = `As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at a fixed ${productType} supply price of ${offer.rate_friendly} for ${offer.duration_friendly} with no deposits, sign up or cancellation fees. Is that correct?`
      } else if (account.service_state === 'NY') {
        planLanguage = `Do you agree to receive a flat monthly ${productType} supply price of ${offer.rate_friendly} for an Introductory Price period that will last ${offer.duration_friendly} months, that you will receive a customized flat monthly price afterwards that will be communicated to you at least 30 days prior to the expiration of the Introductory Price Period?`
      } else {
        planLanguage = `As we discussed, you will be enrolling in the ${offer.plan_name} at a flat, monthly ${productType} supply price of ${offer.rate_friendly} for ${offer.duration_friendly} months with no deposits, sign up or cancellation fees. ComEd's distribution and other charges are not included in the flat fee. Is that correct? `
      }
    } else {
      if (account.service_state !== 'NY' && account.service_state !== 'IL') {
        planLanguage = `You have agreed to receive a subscription trial price for ${productType} supply of ${offer.rate_friendly} per month for ${offer.intro_period_friendly} with no deposits, signup fees or cancellation fees. After the trial period ends you will receive a new, customized subscription price that will be communicated to you by email or mail at least 45 days prior to the end of the trial period. If you do not cancel your service prior to the end of the trial period you will automatically receive a new subscription price for the remaining ${offer.duration_friendly} month initial term of your contract. Is that correct?`
      } else if (account.service_state === 'NY') {
        planLanguage = `Do you agree to receive a flat monthly ${productType} supply price of ${offer.rate_friendly} for an Introductory Price period that will last ${offer.duration_friendly} months, that you will receive a customized flat monthly price afterwards that will be communicated to you at least 30 days prior to the expiration of the Introductory Price Period?`
      } else {
        planLanguage = `As we discussed, you will be enrolling in the ${offer.plan_name} at a flat, monthly ${productType} supply price of ${offer.rate_friendly} for ${offer.duration_friendly} months with no deposits, sign up or cancellation fees. ComEd's distribution and other charges are not included in the flat fee. Is that correct? `
      }
    }

  } else if (offer.contract_type_code === 'fixed') {
    if (account.service_state !== 'NY') {
      planLanguage = `Fixed: As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at a fixed ${productType} supply price of ${offer.rate_friendly}. Is that correct?`
    } else if ( account.service_state === 'NY') {
      planLanguage = `Monthly Evergreen Plan: As we discussed, you will be enrolling in Inspire's ${offer.plan_name} plan at an introductory electricity supply price of ${offer.rate_friendly}. Is that correct?`
    }
  }

  switch (account.service_state) {
    case 'OH':
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name. (${account.first_name + ' ' + account.last_name})`,
        `I need to confirm you are an account owner or authorized to make changes to this ${productType} account. Is that correct?`,
        `${account.product_type_code === 'NaturalGas' ? `${naturalGasRecordingLanguage}`: ''}`,
        `${account.product_type_code === 'NaturalGas' ? 'Can you please confirm that you voluntarily wish to choose Inspire as your natural gas supplier today?' : ''}`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Your service address is ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}. Is that correct?`,
        `Great. I'm going to verify the terms of your new contract`,
        `${planLanguage}`,
        `The agreement term is ${offer.duration_friendly}. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `Do you understand that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least ${offer.days_notice_before_expiration}
          days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already received are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you understand that ${productType} supply will be provided by Inspire, savings are not guaranteed, and that energy delivery, billing, and response to any emergencies
          should they occur shall continue to be provided by your utility and that you will receive a copy of your contract by mail or email within 1 business day after the completion of your enrollment?`,
        `Do you understand that you will receive a monthly consolidated bill from your utility company that includes Inspire’s supply charges and the utility delivery charges?`,
        `Thank you for choosing Inspire as your ${productType} supplier. You’re all set.`,
      ]
    case 'MD':
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name. (${account.first_name + ' ' + account.last_name})`,
        `Can you confirm that you are the ${productType} account holder and are authorized to make the switch to Inspire?`,
        `${account.product_type_code === 'NaturalGas' ? `${naturalGasRecordingLanguage}` : ''}`,
        `${account.product_type_code === 'NaturalGas' ? 'Can you please confirm that you voluntarily wish to choose Inspire as your natural gas supplier today?' : ''}`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Your service address is ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}. Is that correct?`,
        `Great. I'm going to verify the terms of your new contract`,
        `${planLanguage}`,
        `The agreement term is ${offer.duration_friendly}. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `Do you understand that your price with Inspire will be the same regardless of ${productType} usage levels during the Initial Term of your contract, the price is only for ${productType} supply and does not
        include any taxes, utility distribution charges or any other utility fees, savings are not guaranteed, that Inspire's price is not regulated by the MD Public Service Commission, and that information regarding your current
         or future pricing information can be received anytime by contacting us?`,
        `Do you understand that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least ${offer.days_notice_before_expiration} days prior to any renewal period,
        and that you can cancel your service with Inspire anytime?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already received are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the delivery of electric power
         and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 3 business days, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} to rescind.`,
        `Thank you for choosing Inspire as your ${productType} supplier. You’re all set.`,
      ]
    case 'NJ':
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name. (${account.first_name + ' ' + account.last_name})`,
        `I need to confirm you are an account owner or authorized to make changes to this ${productType} account. Is that correct?`,
        `${account.product_type_code === 'NaturalGas' ? `${naturalGasRecordingLanguage}` : ''}`,
        `${account.product_type_code === 'NaturalGas' ? 'Can you please confirm that you voluntarily wish to choose Inspire as your natural gas supplier today?' : ''}`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Your service address is ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}. Is that correct?`,
        `Great. I'm going to verify the terms of your new contract`,
        `${planLanguage}`,
        `The agreement term is ${offer.duration_friendly}. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `Do you understand that at the expiration of the Initial Term of your contract, you will automatically renew and that your electric supply price may vary on a month to month basis?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already received are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the delivery of
          electric power and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} to rescind.`,
        `Thank you for choosing Inspire as your ${productType} supplier. You’re all set.`,
      ]
    case 'IL':
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name. (${account.first_name + ' ' + account.last_name})`,
        `I need to confirm you are an account owner or authorized to make changes to this ${productType} account. Is that correct?`,
        `${account.product_type_code === 'NaturalGas' ? `${naturalGasRecordingLanguage}` : ''}`,
        `${account.product_type_code === 'NaturalGas' ? 'Can you please confirm that you voluntarily wish to choose Inspire as your natural gas supplier today?' : ''}`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Your service address is ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}. Is that correct?`,
        `Great. I'm going to verify the terms of your new contract`,
        `${planLanguage}`,
        `The agreement term is ${offer.duration_friendly}. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `This Agreement will automatically renew on the expiration of the estimated billing cycle for the initial term of the contract. Your renewal contract term will begin on the following billing cycle.
          You will be provided with a Notice of Automatic Contract Renewal, not more than 60 days and not less than 30 days before the expiration of this Agreement. That notice will advise you of product options
          that are available to you and the procedure for notifying us if you want to reject contract renewal and terminate your existing contract at the end of its term. To cancel your contract,
          or to opt-out of the automatic renewal, you may contact Inspire Member Support at (866) 403-2620, help@inspirecleanenergy.com, or by chat at www.inspirecleanenergy.com. Do you understand?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already received are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the delivery of electric
         power and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 3 business days, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} to rescind.`,
        `Thank you for choosing Inspire as your ${productType} supplier. You’re all set.`,
      ]
    case 'NY':
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name. (${account.first_name + ' ' + account.last_name})`,
        `I need to confirm you are an account owner or authorized to make changes to this account. Is that correct?`,
        `${account.product_type_code === 'NaturalGas' ? `${naturalGasRecordingLanguage}` : ''}`,
        `${account.product_type_code === 'NaturalGas' ? 'Can you please confirm that you voluntarily wish to choose Inspire as your natural gas supplier today?' : ''}`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Your service address is ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}. Is that correct?`,
        `Great. I'm going to verify the terms of your new contract`,
        `${planLanguage}`,
        `The introductory term is ${offer.duration_friendly}. There are no sign-up fees and no cancellation fees. Do you understand? `,
        `Do you understand that 30 days prior to the expiration of the Introductory Term, Inspire will send you a renewal notice and that your contract will continue to renew monthly until canceled?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already received are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges, they will also remain responsible for the delivery of electric power
          and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 3 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that ${offer.rescission_text} by contacting Inspire by phone, email, or in writing, and that if you do not rescind this Agreement an enforceable agreement will be created?`,
        `Thank you for choosing Inspire as your ${productType} supplier. You’re all set.`,
      ]
    default:
        // default is for PA, MA, DE, DC
      return [
        `Thank you for continuing your service with Inspire. Before I go over the terms, I need to verify a couple of pieces of information for the recording.`,
        `Can you please verify that the time is ${DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)} and the date is ${DateTime.now().toLocaleString(DateTime.DATE_MED)}?`,
        `Please state your name. (${account.first_name + ' ' + account.last_name})`,
        `I need to confirm you are an account owner or authorized to make changes to this ${productType} account. Is that correct?`,
        `${account.product_type_code === 'NaturalGas' ? `${naturalGasRecordingLanguage}` : ''}`,
        `${account.product_type_code === 'NaturalGas' ? 'Can you please confirm that you voluntarily wish to choose Inspire as your natural gas supplier today?' : ''}`,
        `Your ${account.market_code} ${offer.account_num_synonym} is ${account.account_number}. Is that correct?`,
        `Your service address is ${account.service_address_line_1} ${account.service_address_line_2} ${account.service_city}, ${account.service_state} ${account.service_zip_code}. Is that correct?`,
        `Great. I'm going to verify the terms of your new contract`,
        `${planLanguage}`,
        `The agreement term is ${offer.duration_friendly}. If you cancel the agreement before the term ends there is no cancellation fee. Do you understand?`,
        `Do you understand that at expiration of the Initial Term your contract will automatically renew, that Inspire will send you a renewal notice at least ${offer.days_notice_before_expiration}
          days prior to any renewal period, and that you can cancel your service with Inspire anytime?`,
        `As part of your plan change, you will forfeit any future cash back rewards. Any rewards you've already received are yours to keep. Do you understand?`,
        `${promoLang}`,
        `${rewardLang}`,
        `Do you acknowledge that your utility will continue to send you a monthly consolidated bill that contains Inspire's charges and any utility charges,
          they will also remain responsible for the delivery of electric power and energy to your home and will continue to respond to any service calls and emergencies?`,
        `Do you acknowledge that following the completion of this enrollment that Inspire will send you, within 1 business day, a copy of your contract via email or via mail if you've elected not to provide an email address?`,
        `Do you understand that${offer.rescission_text} and you may call us at ${offer.cs_contact_phone} to rescind.`,
        `Thank you for choosing Inspire as your ${productType} supplier. You’re all set.`,
      ]
  }
}
