import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../apis/tokens";
import useUser from "../../hooks/useUser";

const Callback = () => {
  const navigate = useNavigate()
  const { user, getAccessTokenSilently } = useAuth0()
  const { setUser } = useUser()

  const getGarconToken = async () => {
    const token = await getAccessTokenSilently()
    let redirectURL = localStorage.getItem('location')
    await getToken(token, user.email, setUser)
    if (redirectURL) {
      localStorage.removeItem('location')
      navigate(redirectURL)
    } else {
      navigate('/')
    }
  }

  useEffect(() => {
    if (user) {
      getGarconToken()
    }
  })

  return (null)
}

export default Callback
