import React from 'react'
import { useTable, useSortBy, useFlexLayout } from 'react-table'
import Table from 'react-bootstrap/Table'

const ReactTableBasic = ({ columns, data, handleClick, canHover, className, title}) =>  {

  const defaultColumn = React.useMemo(() => ({
    minWidth: 30,
    width: 150,
    maxWidth: 200,
  }),[])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
      columns: columns,
      data: data,
      defaultColumn,
    },
    useSortBy,
    useFlexLayout
  )

  return (
    <div className={className}>
      <h4>{title}</h4>
      <Table striped bordered hover={canHover} responsive size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th scope="col" {...column.getHeaderProps((column.getSortByToggleProps()), [
                { className: column.className,
                  style: column.style,
                },
              ])}>
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ↓'
                        : ' ↑'
                      : ' ↕︎'}
                  </span>
              </th>
            ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps( { style: { cursor: "pointer" }})}
                onClick={() => handleClick && handleClick(row.original)}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default ReactTableBasic
