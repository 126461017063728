import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/pro-light-svg-icons'

const RefundCalculationRow = ({refundCalculationObject, removeRowFromRefundCalculation}) => {

  const calculateRowPriceDifference = () => {
    const calculatedPriceDifference = (parseFloat(refundCalculationObject.usage) * parseFloat(refundCalculationObject.inspireRate)) - (parseFloat(refundCalculationObject.usage) * parseFloat(refundCalculationObject.quotedRate))
    const roundedPriceDifference = Math.round(calculatedPriceDifference * 100) / 100
    return roundedPriceDifference
  }

  return (
    <>
      <hr />
      <Row className="my-2">
        <Col>
          <Form.Group controlId="month">
            <Form.Label>Month</Form.Label>
            <Form.Control
              type="text"
              name="month"
              readOnly
              value={refundCalculationObject.month}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="usage">
            <Form.Label>Usage</Form.Label>
            <Form.Control
              type="text"
              name="usage"
              readOnly
              value={refundCalculationObject.usage}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="inspireRate">
            <Form.Label>Inspire Rate</Form.Label>
            <Form.Control
              type="text"
              name="inspireRate"
              readOnly
              value={refundCalculationObject.inspireRate}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="quotedRate">
            <Form.Label>Quoted Rate</Form.Label>
            <Form.Control
              type="text"
              name="quotedRate"
              readOnly
              value={refundCalculationObject.quotedRate}
            />
          </Form.Group>
        </Col>
        <Col>
          <p><b>Difference</b></p>
          {calculateRowPriceDifference()}
        </Col>
        <Col>
          <Button
            className="mt-3"
            onClick={() => removeRowFromRefundCalculation(refundCalculationObject)}
            variant="danger"
          >
            <FontAwesomeIcon
              size="sm"
              color="white"
              icon={faTrashCan}
              fixedWidth
            />
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default RefundCalculationRow
