import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Dropdown, Row } from 'react-bootstrap'
import SalesLocation from './SalesLocation'
import EnrollmentDocs from './EnrollmentDocs'
import TPVinfo from './TPVinfo'
import SalesInfo from './SalesInfo'
import { getOrdersList } from '../../../apis/orders'
import Contracts from './Contracts'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'

const ServiceSummary = ({ account }) => {
  const [orderList, setOrdersList]  = useState([])
  const [order, setOrder] = useState(null)

  useEffect(() => {
    getOrdersList(account.account_id, setOrdersList)
  }, [account.account_id])

  useEffect(() =>{
    if(!isEmpty(orderList)){
      setOrder(orderList[0])
    }
  }, [orderList])

  return (
    <div>
      <Card>
        <Card.Body >
          <Card.Title tag="h3">
            <Dropdown className="d-inline me-2">
              <Dropdown.Toggle>{orderList?.length} Orders</Dropdown.Toggle>
              <Dropdown.Menu>
                {isEmpty(orderList) ?
                  <Dropdown.Item key={uuidv4()}>No orders present</Dropdown.Item>
                : orderList.map((order) => (
                    <Dropdown.Item key={order.order_id} onClick={(()=>setOrder(order))}>{order.order_id} on {DateTime.fromISO(order.order_dt).toLocaleString(DateTime.DATE_MED)}</Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
          </Card.Title>
          <hr />
          {order &&
            <div>
              <h3>Order #{order.order_id} via {order.source_code}</h3>
              <Row className="g-2">
                <Col md="6" xl className="d-flex">
                  <Container>
                    <SalesInfo order={order} account={account} />
                  </Container>
                </Col>
                <Col md="6" xl className="d-flex">
                  <Container>
                    <TPVinfo order={order} account={account} />
                  </Container>
                </Col>
              </Row>
              <Row className="g-2">
                <Col md="6" xl className="d-flex">
                  <Container>
                    <Contracts account={account} />
                  </Container>
                </Col>
                <Col md="6" xl className="d-flex">
                  <Container>
                    <EnrollmentDocs order={order} />
                  </Container>
                </Col>
              </Row>
              <Row className="g-2">
                <Col md="6" xl className="d-flex">
                  <Container>
                    <SalesLocation order={order} />
                  </Container>
                </Col>
              </Row>
            </div>
          }
        </Card.Body>
      </Card>
    </div>
  )
}

export default ServiceSummary
