import React from 'react'
import { Container, Col, Row, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFireBurner } from '@fortawesome/pro-duotone-svg-icons'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons'
import { faTrophy, faHouse, faBoltLightning, faHashtag } from '@fortawesome/pro-light-svg-icons'

const PlanDetails = ({ account }) => {

  const determineProduct = () => {
    return account.product_type_code === 'Electric' ? <FontAwesomeIcon size="lg" color="#E86371" icon={faBoltLightning} fixedWidth /> : <FontAwesomeIcon size="lg" color="#d5bfad" icon={faFireBurner} />
  }

  const determineRevenueClass = () => {
    return account.revenue_class_name === 'Residential' ? <FontAwesomeIcon size="lg" color="#E86371" icon={faHouse} fixedWidth /> : <FontAwesomeIcon size="lg" color="#d5bfad" icon={faBuilding} />
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <ul className="list-unstyled">
              <li className="mb-1">
              {determineProduct()}{" "}{account.plan_name} - <Badge bg="salmonLight" text="black">{account.product_type_code}</Badge>
              </li>
              <li className="mb-1">
              {determineRevenueClass()}{" "}{account.revenue_class_name}
              </li>
              <li className="mb-1">
              <FontAwesomeIcon size="lg" className="me-1" color="#E86371" icon={faTrophy} fixedWidth/>Rewards Package: {account.plan_rewards_description}
              </li>
              <li className="mb-1">
              <FontAwesomeIcon size="lg" className="me-1" color="#E86371" icon={faHashtag} fixedWidth/>Member Number: {account.member_number}
              </li>
            </ul>
          </Col>
          <Col>
            <ul className="list-unstyled">
              <li className="mb-1">
              Channel: {account.channel_name}
              </li>
              <li className="mb-1">
                Partner: {account.channel_partner_code}
              </li>
              <li className="mb-1">
                Source: <span  style={{backgroundColor: "#F4BABB"}} text="black">{account.source_description}</span>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PlanDetails
