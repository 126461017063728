import React, { useEffect, useState } from 'react'
import { Card, Button, ButtonGroup, Col, Row, Container } from 'react-bootstrap'
import Select from 'react-select'
import { getMemberEvents, getProspectEvents } from '../../../apis/prospects'
import MemberEvent from './MemberEvent'
import AddContactModal from './AddContactModal'
import AddNoteModal from './AddNoteModal'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'

const MemberEventsContainer = ({ member, prospect }) => {
  const[reloadEvents, setReloadEvents] = useState(false)
  const[memberEvents, setMemberEvents] = useState([])
  const[filteredEvents, setFilteredEvents] = useState([])
  const[eventCategory, setEventCategory] = useState({})
  const[showAddContact, setShowAddContact] = useState(false)
  const[showAddNote, setShowAddNote] = useState(false)

  useEffect(() => {
    if (prospect) {
      getProspectEvents(prospect, setMemberEvents)
    } else {
      getMemberEvents(member, setMemberEvents)
    }
    setEventCategory({ value: 'MX Contact and Notes', label: 'MX Contacts and Notes' })
  }, [member, prospect, reloadEvents])

  useEffect(() => {
    if(!isEmpty(memberEvents)) {
      switch(eventCategory.value) {
        case 'All':
          setFilteredEvents(memberEvents.filter(memberEvent => (
            !!memberEvent.type
          )))
          break
        case 'Automated':
          setFilteredEvents(memberEvents.filter(memberEvent => (
            memberEvent.type === 'Mail' || memberEvent.type === 'Email' || memberEvent.type === 'Referral'
            )))
          break
        case 'Cases':
          setFilteredEvents(memberEvents.filter(memberEvent => (
            memberEvent.type === 'Case'
          )))
          break
        case 'Plan Changes':
          setFilteredEvents(memberEvents.filter(memberEvent => (
            memberEvent.type === 'Order' || memberEvent.type === 'Account'
          )))
          break
        default:
          setFilteredEvents(memberEvents.filter(memberEvent => (
            ['Note', 'Contact', 'SMS', 'NPS', 'CSAT', 'Scheduling', 'Gladly'].includes(memberEvent.type)
          )))
      }
    }
  }, [memberEvents, eventCategory])

  const eventCategoryOptions = [
    {value: 'All', label: 'All'},
    {value: 'Cases', label: 'Cases'},
    {value: 'MX Contact and Notes', label: 'MX Contacts and Notes'},
    {value: 'Automated', label: 'Automated'},
    {value: 'Plan Changes', label: 'Plan Changes'}
  ]

  const handleAddNote = () => {
    setShowAddNote(true)
  }

  const handleAddContact = () => {
    setShowAddContact(true)
  }

  const handleCloseContactModal = () => {
    setShowAddContact(false)
  }

  const handleCloseNoteModal = () => {
    setShowAddNote(false)
  }

  const refreshEvents = () => {
    setReloadEvents(!reloadEvents)
  }

  const scrollContainerStyle = { maxHeight: "500px", overflowY: "scroll" };

  return (
    <div className="mt-3">
      <Card.Title tag="h3">
        Member Events
      </Card.Title>
      <Row>
        <Col md={5}>
          <Select
            className="react-select-container"
            data-testid="event-select"
            aria-label="Event Category Options"
            size="med"
            options={eventCategoryOptions}
            onChange={(e) => setEventCategory(e)}
            defaultValue={eventCategory}
            value={eventCategory}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#B0DBE9',
                primary: '#0E7C9E',
              },
            })}
          />
        </Col>
        <Col md={{ span: 3, offset: 4 }}>
          <ButtonGroup size="lg" className="me-2" aria-label="Add Contact and Add Note Buttons">
            <Button size="sm" aria-label="Add Contact" variant="success" onClick={handleAddContact}>Add Contact</Button>
            <Button aria-label="Add Note" variant="success" onClick={handleAddNote}>Add Note</Button>
          </ButtonGroup>
        </Col>
      </Row>
      <hr />
      <Container>
        <div style={scrollContainerStyle}>
          {isEmpty(filteredEvents) ?
          <b>No events recorded for this member in this category</b>
          : filteredEvents.map(event => (
            <MemberEvent key={uuidv4()} event={event}/>
          ))}
        </div>
      </Container>
      {showAddContact &&
        <AddContactModal
          handleClose={handleCloseContactModal}
          member={member}
          show={showAddContact}
        />
      }
      {showAddNote &&
        <AddNoteModal
          handleClose={handleCloseNoteModal}
          member={member}
          show={showAddNote}
          refreshEvents={refreshEvents}
        />
      }
    </div>
  )
}

export default MemberEventsContainer
