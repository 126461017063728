import React from 'react'
import { Button, Card, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressBook,
  faBarsProgress,
  faBriefcaseArrowRight,
  faCalendar,
  faCommentSmile,
  faPencil,
  faTemperatureHalf,
} from '@fortawesome/pro-light-svg-icons'
import { Briefcase } from 'react-feather'
import { DateTime } from 'luxon'

const CaseHighlightsCard = ({ caseDetails }) => {
  return (
    <Card className="flex-fill mx-3">
      <Card.Body className="py-4">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            { !caseDetails?.case_guid ?
              <h3 className="mb-4">Missing Case Information</h3>
            :
              <>
                <h3 className="mb-4">At-A-Glance</h3>
                <Col>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faBriefcaseArrowRight} fixedWidth/>
                      Case ID: {caseDetails.case_guid} &nbsp;
                      <Button size="sm" onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_CRM_URL}/cases/${caseDetails.case_guid}`)}>Copy Link to Case</Button>
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faCalendar} fixedWidth/>
                      Created: {DateTime.fromISO(caseDetails.case_created_dt ).toLocaleString(DateTime.DATE_MED) || 'N/A'}
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faPencil} fixedWidth/>
                      Created By: {caseDetails.created_by_user_name || 'N/A'}
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faTemperatureHalf} fixedWidth/>
                      Tier: {caseDetails.case_type_description}
                    </li>
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faBarsProgress} fixedWidth/>
                      Status: {caseDetails.case_status_code}
                      {caseDetails.case_status_change_reason_desc &&
                        <>
                          <br />
                          <span className="ms-4">{caseDetails.case_status_change_reason_desc}</span>
                        </>
                      }
                    </li>
                    {caseDetails.department_code !== 'MX' &&
                      <>
                        <li className="mb-1">
                        <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faAddressBook} fixedWidth/>
                          Original Channel Code: {caseDetails.channel_description || 'N/A'}
                        </li>
                        <li className="mb-1">
                        <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faCommentSmile} fixedWidth/>
                          Latest Channel Code: {caseDetails.latest_channel_code || 'N/A'}
                        </li>
                      </>
                    }
                    <li className="mb-1">
                      <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faCommentSmile} fixedWidth/>
                      Reported By:&nbsp;
                      {caseDetails.reported_by_prospect_guid ?
                        <>
                          <a href={`/prospect/${caseDetails.reported_by_prospect_guid}`} target='_blank' rel="noreferrer">
                            {caseDetails.prospect_first_name ? caseDetails.prospect_first_name.concat(' ').concat(caseDetails.prospect_last_name || '') : 'View Prospect'}
                          </a>
                          <ul className="list-unstyled">
                          {caseDetails.accounts?.map((account, index) => (
                            <li key={index}>
                            <a className="ms-4" href={`/profiles/${account.membership_profile_id}/accounts/${account.account_id}`} target='_blank' rel="noreferrer">
                              View Account:
                            </a>
                            &nbsp;Account ID: {account.account_id} Order ID: {account.order_id}
                            </li>
                            ))}
                          </ul>
                        </>
                      :
                        'N/A'
                      }
                    </li>
                  </ul>
                </Col>
              </>
            }
            </div>
          <div className="stat">
            <Briefcase className="align-middle text-success" />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default CaseHighlightsCard
