import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { getProspect } from "../../apis/prospects"
import { Card, Col, Container, Row, Tabs, Tab } from "react-bootstrap"
import MemberProfile from "../../components/member/MemberProfile"
import MemberEventsContainer from "../../components/member/memberEvents/MemberEventsContainer"
import LinkedAccounts from "../../components/member/LinkedAccounts"
import PartialOrders from "../../components/member/PartialOrders"
import ProspectCases from "../../components/prospect/ProspectCases"
import { isEmpty } from "lodash"

const Prospect = () => {
  const [prospect, setProspect] = useState({})
  const { prospect_guid } = useParams()
  const [key, setKey] = useState('prospect events')
  const [reloadMember, setReloadMember] = useState(false)
  const [accountId, setAccountId] = useState(null)

  useEffect(() => {
    getProspect(prospect_guid, setProspect)
  }, [prospect_guid, reloadMember])

  useEffect(() => {
    if (!isEmpty(prospect.accounts)) {
      setAccountId (prospect.accounts[0].account_id)
    }
  }, [prospect.accounts, setAccountId])

  return (
    <React.Fragment>
      <Helmet title="Prospect" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Prospect</h1>
        {prospect.prospect_guid &&
          <Row>
            <Col md="4" xl="3">
              <MemberProfile prospect={prospect} reloadMember={reloadMember} setReloadMember={setReloadMember} />
            </Col>
            <Col md="8" xl="9">
              <Card>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                  variant="tabs"
                  bg="info"
                >
                  <Tab eventKey="prospect events" title="Prospect Events">
                    <Card.Body>
                      <MemberEventsContainer prospect={prospect} />
                    </Card.Body>
                  </Tab>
                  <Tab eventKey="linked accounts" title="Linked Accounts">
                    <LinkedAccounts accountId={accountId} />
                  </Tab>
                  <Tab eventKey="prospect orders" title="Partial Orders">
                    <PartialOrders prospect={prospect} />
                  </Tab>
                  <Tab eventKey="cases" title="Cases">
                    <ProspectCases prospect={prospect} setReloadMember={setReloadMember} />
                  </Tab>
                </Tabs>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </React.Fragment>
  )
}

export default Prospect
