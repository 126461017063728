import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { DateTime, Interval } from 'luxon'
import * as DOMPurify from 'dompurify'
import ConversationModal from './ConversationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationIcons from '../memberNotifications/NotificationIcons'


const MemberEvent = ({event}) => {
  const [showConversationModal, setShowConversationModal] = useState(false)

  const parseCaseDate = () => {
    const startDate = DateTime.fromISO(event.date)
    const endDate = DateTime.local()
    const duration = Interval.fromDateTimes(startDate, endDate).toDuration('days').toObject()
    return Math.round(duration.days)
  }

  const handleViewConversation = (e) => {
    setShowConversationModal(true)
  }

  const handleCloseConversationModal = () => {
    setShowConversationModal(false)
  }

  return (
    <>
      <div className="d-flex">
      <Col md={1}>
        <Row className='me-5'>
          <FontAwesomeIcon
            size="2x"
            className='align-middle'
            color={"#0E7C9E"}
            icon={NotificationIcons(event.icon)}>
          </FontAwesomeIcon>
        </Row>
        <Row className='align-start'>
          <small className="text-muted">{parseCaseDate()} days ago </small>
        </Row>
      </Col>
      <Col md={10}>
          <small className="float-end">{event.date ? DateTime.fromISO(event.date).toLocaleString(DateTime.DATETIME_FULL) : 'n/a'}</small>
          <h4 className='mb-0'>{event.title}</h4>
          <p className='mb-0' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.description)}}></p>
          { event.type === 'Gladly' &&
            <Button className="square bg-primary rounded-pill mt-2" size="sm" onClick={(e)=> handleViewConversation(e)}>View Conversation</Button>
          }
          { showConversationModal &&
            <ConversationModal
              conversationId={event.conversation_id}
              show={showConversationModal}
              handleClose={handleCloseConversationModal}
            />
          }
      </Col>
      </div>
      <hr />
    </>
  )
}

export default MemberEvent
