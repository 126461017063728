import React, { useRef, useState, useEffect } from 'react'
import { getAllUsers } from '../../../apis/users'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'

const Item = ({ entity: { name }, selected }) => {
  if (selected){
    return (
      <div className="autocomplete-select" style={{ backgroundColor: '#F5F5F5' }}>
        <b>@{name}</b>
      </div>
    )
  }
  return (
    <div className="autocomplete-select">
      <b>@{name}</b>
    </div>
  )
}

const AutoComplete = ({ value, onChange }) => {
  const [users, setUsers] = useState([])
  const rtaRef = useRef()

  useEffect(() => {
    getAllUsers(setUsers)
  }, [])

  return (
    <ReactTextareaAutocomplete
      className="form-control mb-2 w-100"
      listStyle={{
        listStyleType: 'none',
        padding: 0,
      }}
      loadingComponent={() => <span>Loading</span>}
      placeholder="@slackUsername to ping teammate"
      ref={rtaRef}
      trigger={{
        '@': {
          dataProvider: input => {
            return users.filter(u => {
              if (u.slack_username && u.slack_username.includes(input)){
                return true
              }
              return false
            }).map(user => {
              return { name: user.slack_username }
            })
          },
          component: Item,
          output: (item, trigger) => `${trigger}${item.name}`,
        },
      }}
      value={value}
      onChange={e => onChange(e)}
    />
  )
}

export default AutoComplete
