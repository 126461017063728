import React from 'react'
import { Modal } from 'react-bootstrap'
import Address from './Address'

const AddressModal = ({
  addressId,
  addressType,
  accountId,
  prospectGuid,
  handleClose,
  show,
  handleUpdateCaseAttribute,
  caseAddressAttribute,
  updateBillAddressGuid }) => {

  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton><h3 className='mb-0'>Edit Address</h3></Modal.Header>
        <Modal.Body>
          {addressId &&
            <Address
              addressId={addressId}
              addressType={addressType}
              accountId={accountId}
              prospectGuid={prospectGuid}
              handleClose={handleClose}
              handleUpdateCaseAttribute={handleUpdateCaseAttribute}
              caseAddressAttribute={caseAddressAttribute}
              updateBillAddressGuid={updateBillAddressGuid}
            />
          }
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default AddressModal
