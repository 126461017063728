import axios from 'axios'

export const getShopper = async (shopper_short_key, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/shoppers/?short_key=${shopper_short_key}`)
    callback(response?.data)
    return response?.data
  } catch (error) {
    throw error
  }
}

export const updateShopper = async (updatedShopper, handleAlert) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/sales/app/shopper`, updatedShopper)
    if (response.status === 200) return
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const createShopper = async (formInfo, userInfo) => {
  try {
    const params = {
      agent_id: userInfo.agent_id,
      source_code: userInfo.source_code,
      first_name: formInfo.first_name,
      last_name: formInfo.last_name,
      email: formInfo.email,
      primary_phone: formInfo.primary_phone,
      account_number: formInfo.account_number,
      revenue_class_code: formInfo.revenue_class_code,
      market_code: formInfo.market_code,
      service_zip_code: formInfo.zip_code,
      service_state: formInfo.service_state,
      service_city: formInfo.service_city,
      service_address_line_1: formInfo.service_address_line_1,
      service_address_line_2: formInfo.service_address_line_2,
      business_name: formInfo.business_name,
      billing_address_line_1: formInfo.billing_address_line_1,
      billing_address_line_2: formInfo.billing_address_line_2,
      billing_city: formInfo.billing_city,
      billing_state: formInfo.billing_state,
      billing_zip_code: formInfo.billing_zip_code,
      contact_first_name: formInfo.contact_first_name,
      contact_last_name: formInfo.contact_last_name,
      contact_email: formInfo.contact_email,
      contact_relationship: formInfo.contact_relationship,
      campaign_id: formInfo.campaign
    }
    return await axios.post(`${process.env.REACT_APP_GARCON_API}/shoppers`, params)
  } catch (e) {
    throw e
  }
}


export const savePartial = async(shopperPayload, handleAlert) => {
  try {
    // adding some fallback logic because savePartial is called in two different places
    const shopperGuid = shopperPayload.shopper_guid || shopperPayload.short_key
    return await axios.post(`${process.env.REACT_APP_GARCON_API}/shoppers/${shopperGuid}`, shopperPayload)
  } catch (error) {
    handleAlert(`Something went wrong saving partial: ${error}`, false)
    throw error
  }
}
