import axios from 'axios'

export const getContactDispositions = async(callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/contacts/dispositions`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const addNewContact = async(contact) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/contacts/contact/offline`, contact)
    return response
  } catch (error) {
    throw error
  }
}


export const getSurveyResponses = async(callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/csat_survey_responses`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}
