import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Button, Form, ListGroup, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { getShopper } from '../../apis/shoppers'
import { getOffer } from '../../apis/offers'
import useToast from '../../hooks/useToast'
import newOrderScripts from '../../utils/newOrderScripts'
import { getPTC } from '../../apis/pricing'


const OrderVerificationSteps = () => {
  const [loading, setLoading] = useState(true)
  const [shopperData, setShopperData] = useState(null)
  const [offer, setOffer] = useState({})
  const [ptc, setPTC] = useState(null)
  const { shopper_short_key } = useParams()
  const navigate = useNavigate()
  const { handleAlert } = useToast()

  useEffect(() => {
    getShopper(shopper_short_key, setShopperData)
      .then((shopper) => {
        if (shopper) {
          getPTC(shopper[0], setPTC, handleAlert)
          getOffer(shopper[0].offer_code, setOffer, handleAlert)
        }
      })
      .finally(() => setLoading(false))
  }, [shopper_short_key, handleAlert])

  return (
    <>
      <Helmet title="Order Verification" />

      <h1 className='mb-3'>Order Verification Steps</h1>
      <ListGroup variant="flush" style={{ maxWidth: '1000px' }}>
        {loading &&
          <ListGroup.Item>
            <Spinner
              as="span"
              animation="border"
              data-testid="order-verification-spinner"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2 ml-4"
            />
          </ListGroup.Item>
        }
        {shopperData &&
          <h4>Great, thank you so much for this information! MR./MRS. {shopperData[0].last_name}, now I am required to read you a series of questions to confirm the details of your application.</h4>
        }
        {offer.offer_code && ptc && !loading &&
          newOrderScripts(offer, ptc, shopperData[0]).map((script, index) => (
            <ListGroup.Item key={index}>
              <Form.Check type="checkbox" label={script} />
            </ListGroup.Item>
          ))
        }
        <Button
          variant="primary"
          className="mt-3 btn-lg"
          onClick={() => navigate(`/order/${shopper_short_key}/final`)}
        >
          Confirm order
        </Button>
        <Button variant="link" onClick={() => navigate(`/order/${shopper_short_key}/join`)}>
          Back
        </Button>
      </ListGroup>
    </>
  )
}

export default OrderVerificationSteps
