import React, { useEffect, useState } from "react";
import { PenTool } from "react-feather";
import { Card, Col, Row, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract } from "@fortawesome/pro-light-svg-icons";
import { getContractHistory } from "../../../apis/accounts";
import { DateTime } from "luxon"
import { isEmpty } from "lodash";

const Contracts = ({ account }) => {
  const [contracts, setContracts] = useState([])

  useEffect(() => {
    getContractHistory(account.account_id, setContracts)
  }, [account.account_id])

  return (
    <Row>
      <Col>
        <Card>
          {contracts &&
            <Card.Body>
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                    <h3 className="mb-2">Contracts</h3>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <PenTool className="align-middle text-success" />
                    </div>
                </div>
              </div>
              {!isEmpty(contracts) ?
              <div className="mb-0">
                {Object.keys(contracts).map((key, value) =>
                <ul key={key} className="list-unstyled">
                  <li className="mb-1">
                  <FontAwesomeIcon size="sm" className="me-1" color="#E86371" icon={faFileContract} fixedWidth/>
                    {contracts[key].plan_name} at {contracts[key].rate_friendly} from {DateTime.fromISO(contracts[key].contract_start_dt).toLocaleString(DateTime.DATE_MED)} to {DateTime.fromISO(contracts[key].contract_end_dt).toLocaleString(DateTime.DATE_MED)}
                  </li>
                </ul>
                )}
              </div>
              :
                <div>
                  <h3><Badge bg="" className="badge-soft-success me-2"> No Contracts Available</Badge></h3>
                </div>
              }
            </Card.Body>
          }
        </Card>
      </Col>
    </Row>
  )
}

export default Contracts
