import React, { useState } from 'react'
import { Accordion, Badge, Alert, Button } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTruck, faPen, faMoneyCheck, faCalendar } from '@fortawesome/pro-light-svg-icons'
import ReissueCheck from './ReissueCheck'
import CheckHistory from './CheckHistory'

const CheckDetail = ({account, bill, index}) => {
  const [showEditReissue, setShowEditReissue] = useState(false)

  const editReissueButtonText = () => {
    return showEditReissue ? 'Cancel' : 'Update Info and Reissue Check'
  }

  const determineBillType = (bill) => {
    return bill.reward ?
    <span key={bill.account_payable_bill_id}><b>Reward</b> - {bill.reward.reward_description}</span>
    :
    <span key={bill.account_payable_bill_id}><b>Refund</b> - {bill.case?.case_type_category.category_name}</span>
  }

  return (
    <Accordion key={bill.account_payable_bill_id}>
      <Accordion.Item eventKey={index} className="bg-white">
        <Accordion.Header className="d-flex">
          <Badge bg="lightElectric" text="black" className="me-2">
            {bill.amount}
          </Badge>
          {determineBillType(bill)}
          <div className="ms-auto">
            {bill.check_status || 'PENDING APPROVAL'} ({DateTime.fromISO(bill.created_at).toLocaleString(DateTime.DATE_MED)})
          </div>
        </Accordion.Header>
          <Accordion.Body>
          <div className="d-flex">
            <ul className="list-unstyled">
              <li className="mb-1">
                <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faFile} fixedWidth/>
                Invoice #:&nbsp;{bill.invoice_number}
              </li>
              <li className="mb-1">
                <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faTruck} fixedWidth/>
                Vendor:&nbsp;{bill.vendor.vendor_name}
              </li>
              <li className="mb-1">
                <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faPen} fixedWidth/>
                Memo:&nbsp;{bill.check_memo}
              </li>
              <li className="mb-1">
                <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faMoneyCheck} fixedWidth/>
                Check #:&nbsp;{bill.check_number}
              </li>
            </ul>
            <ul className="ms-auto list-unstyled">
            <li className="mb-1">
              <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faCalendar} fixedWidth/>
              Check Date:&nbsp;
              {bill.check_dt ? DateTime.fromISO(bill.check_dt).toLocaleString(DateTime.DATE_MED) : ''}
            </li>
            <li className="mb-1">
              <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faCalendar} fixedWidth/>
              Est. Delivery Date:&nbsp;
              {bill.check_arrives_by_dt ? DateTime.fromISO(bill.check_arrives_by_dt).toLocaleString(DateTime.DATE_MED) : ''}
            </li>
            <li className="mb-1">
              <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faCalendar} fixedWidth/>
              Cleared Date:&nbsp;
              {bill.check_cleared_dt ? DateTime.fromISO(bill.check_cleared_dt).toLocaleString(DateTime.DATE_MED) : ''}
            </li>
            <li className="mb-1">
              <FontAwesomeIcon size="lg" className="me-1" color="#025169" icon={faCalendar} fixedWidth/>
              Expiration Date:&nbsp;
              {bill.check_expiration_dt ? DateTime.fromISO(bill.check_expiration_dt).toLocaleString(DateTime.DATE_MED) : ''}
            </li>
            </ul>
            </div>
            {bill.reissuable ?
              <div className="my-3">
                <Button className="btn btn-primary mb-2" onClick={() => setShowEditReissue(!showEditReissue)}>
                  {editReissueButtonText()}
                </Button>
                {showEditReissue &&
                  <ReissueCheck
                    account={account}
                    bill={bill}
                    setShowEditReissue={setShowEditReissue}
                  />
                }
              </div>
              :
              <div className="my-3">
                <Alert variant="info" className="p-2">
                  <b>Check is not reissuable</b>
                </Alert>
              </div>
            }
            {bill.check_error_message &&
              <Alert variant="danger" className="p-2">
              <b>Error:&nbsp;</b> {bill.check_error_message}
              </Alert>
            }
            <CheckHistory
              bill={bill}
            />
          </Accordion.Body>
        </Accordion.Item>
    </Accordion>
  )
}

export default CheckDetail
