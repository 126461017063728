/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import { saveAccountAddress } from '../../apis/accounts'
import { autocompleteAddress,
  getAddressFromId,
  getAddressFromGuid,
  validateAddress,
  saveProspectAddress } from '../../apis/address'
import useToast from '../../hooks/useToast'
import useUser from '../../hooks/useUser'
import MapMarker from '../../utils/mapMarker'
import { isEmpty } from 'lodash'

const Address = ({
  addressId,
  addressType = '',
  accountId,
  prospectGuid,
  handleClose,
  handleUpdateCaseAttribute,
  caseAddressAttribute,
  updateBillAddressGuid }) => {
  const { userInfo } = useUser()
  const { handleAlert } = useToast()
  const [address, setAddress] = useState({})
  const [validatedAddress, setValidatedAddress] = useState({})
  const [mapMarkerLat, setMapMarkerLat] = useState('')
  const [mapMarkerLong, setMapMarkerLong] = useState('')
  const [updatedAddressGuid, setUpdatedAddressGuid] = useState('')
  const [selectedAddress, setSelectedAddress] = useState({
    value: '',
    label: '',
    street: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
  })

  useEffect(() => {
    if(address.address_id) {
      setSelectedAddress({
        ...selectedAddress,
        street: address.street,
        unit: address.unit,
        city: address.city,
        state: address.state_code,
        zip: address.zip,
      })
      setMapMarkerLat(address.latitude)
      setMapMarkerLong(address.longitude)
    }
  }, [address])

  useEffect(() => {
    getAddressFromId(addressId, setAddress, handleAlert)
  }, [addressId])

  useEffect(() => {
    if (!isEmpty(updatedAddressGuid)) getAddressFromGuid(updatedAddressGuid, setAddress, handleAlert)
  }, [updatedAddressGuid])

  useEffect(() => {
    if (validatedAddress?.components) {
      setSelectedAddress({...selectedAddress, zip: validatedAddress.components.zipcode})
    }
    if (validatedAddress?.metadata) {
      setMapMarkerLat(validatedAddress.metadata.latitude)
      setMapMarkerLong(validatedAddress.metadata.longitude)
    }
  }, [validatedAddress])

  const onStreetChange = (newStreetAddress) => {
    if (newStreetAddress) {
      setSelectedAddress({...selectedAddress, street: newStreetAddress})
    }
  }

  const handleFormFieldInput = (e) => {
    setSelectedAddress({...selectedAddress, [e.target.name]: e.target.value})
  }

  const changeSelectedAddress = (selectedAddressFromDropDown) => {
    const updatedAddress = (selectedAddressFromDropDown == null) ? {} : selectedAddressFromDropDown
    setSelectedAddress(updatedAddress)
    validateAddress(updatedAddress, setValidatedAddress, handleAlert)
  }

  const handleStreet = (street) => {
    if (street.length > 3){
      return autocompleteAddress(street)
        .then((response) => {
          let options = response.data.map((smartStreet) => ({
            value: smartStreet.street_line,
            label: smartStreet.text,
            street: smartStreet.street_line,
            city: smartStreet.city,
            state: smartStreet.state
          }))
          return options
        })
        .catch((error) => {
          handleAlert(`Cannot handle a smart street ${error}`, false)
        })
    }
  }

  const updateAddress = async () => {
    if (prospectGuid) {
      const response = await saveProspectAddress(prospectGuid, selectedAddress, userInfo, handleAlert)
      if (response.data) {
        if (caseAddressAttribute) {
            handleUpdateCaseAttribute({
            case_attribute_id: caseAddressAttribute.case_attribute_id,
            value: response.data.address_guid
          })
        } else {
          updateBillAddressGuid(response.data.address_guid)
        }
        setTimeout(() => {
          handleClose()
        }, 3000)
      } else {
        handleAlert('Something went wrong updating the address', false)
      }
    } else {
      saveAccountAddress(accountId, selectedAddress, addressType, userInfo, setUpdatedAddressGuid, handleAlert)
    }
  }

  return (
    <React.Fragment>
      <Container>
       {address &&
        <>
          <Form>
            <Row>
              <Col md="6">
                <Form.Label>{addressType} Address 1</Form.Label>
                <AsyncSelect
                  className="react-select-container"
                  classNamePrefix="react-select"
                  data-testid="street-address-input"
                  placeholder={selectedAddress?.street||"Type address..."}
                  defaultOptions={[]}
                  name="street"
                  loadOptions={handleStreet}
                  onInputChange={onStreetChange}
                  onChange={(e) => changeSelectedAddress(e)}
                  value={selectedAddress?.street}
                />
              </Col>
              <Col md="4">
                <Form.Label>{addressType} Address 2</Form.Label>
                <Form.Control
                  type="text"
                  name="unit"
                  size="lg"
                  defaultValue={selectedAddress.unit}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Form.Label htmlFor="city">{addressType} City</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="city"
                  size="lg"
                  className="mb-3"
                  onChange={(e) => handleFormFieldInput(e)}
                  value={selectedAddress.city}
                />
              </Col>
              <Col md="2">
                <Form.Label htmlFor="state">{addressType} State</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="state"
                  size="lg"
                  className="mb-3"
                  onChange={(e) => handleFormFieldInput(e)}
                  value={selectedAddress.state}
                />
              </Col>
              <Col md="2">
                <Form.Label htmlFor="zip_code">{addressType} Zip Code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="zip"
                  size="lg"
                  className="mb-3"
                  onChange={(e) => handleFormFieldInput(e)}
                  value={selectedAddress.zip}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Button variant="primary" onClick={updateAddress}>Save address</Button>
            </Row>
          </Form>
          <hr />
          {mapMarkerLat && mapMarkerLong &&
            <Row>
              <MapMarker lat={+mapMarkerLat} long={+mapMarkerLong} />
            </Row>
          }
        </>
      }
      </Container>
    </React.Fragment>
  )
}

export default Address
