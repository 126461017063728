import axios from 'axios'


export const getCase = async (caseGuid, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/view`)
    callback(response.data)
  }
  catch (error) {
    throw error
  }
}

export const createCase = async (caseForm, handleAlert) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cases`, caseForm)
    if (response.status === 200) {
      handleAlert(`Case created! Case Guid: ${response?.data.case_guid}`, true)
      return response?.data
    }
  }
  catch (error) {
    handleAlert(`Unable to create case: ${error}`, false)
    throw error
  }
}

export const getCaseAttributes = async (caseGuid) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/${caseGuid}/attributes`)
    return response?.data
  }
  catch (error) {
    throw error
  }
}

export const getAddressFromAttribute = async (addressGuid, callback) => {
  try{
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/addresses/${addressGuid}`)
    callback(response.data)
  } catch(error) {
    throw error
  }
}
