import React, { useState } from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap'
import CreateCaseButton from '../../cases/createCase/CreateCaseButton'
import CreateCaseFormDefault from '../../cases/createCase/CreateCaseFormDefault'
import AccountCasesTable from './AccountCasesTable'

const AccountCasesContainer = ({ account, setReloadMember }) => {
  const [showCaseForm, setShowCaseForm] = useState(false)

  return (
    <div>
      <Card>
          <Card.Body >
          <Card.Title>
            <Row>
              <Col className="mt-3">
                Account Cases
              </Col>
              <Col className="d-flex">
                <div className="ms-auto">
                  <CreateCaseButton showCaseForm={showCaseForm} setShowCaseForm={setShowCaseForm}  />
                </div>
              </Col>
              </Row>
          </Card.Title>
          <hr />
          <CreateCaseFormDefault showCaseForm={showCaseForm} setShowCaseForm={setShowCaseForm} member={account} setReloadMember={setReloadMember}/>
          <Container className="mt-3">
            <AccountCasesTable
            prospect={account.membership_profile.prospect}
            accountId={account.account_id}
            />
          </Container>
        </Card.Body>
      </Card>
    </div>
  )
}

export default AccountCasesContainer
