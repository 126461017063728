/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'
import { Container, Col, Row, Form, Button } from 'react-bootstrap'
import useToast from '../../../hooks/useToast'
import useUser from '../../../hooks/useUser'
import { getAccountReject, getRejectStatuses, saveReject } from '../../../apis/rejects'
import { checkForExistingAccount } from '../../../apis/accounts'
import { DateTime } from 'luxon'
import { validateField } from '../../../utils/formValidation'
import AccountNumber from '../../../utils/accountNumber'
import ECLModal from './ECLModal'
import isEmpty from 'lodash/isEmpty'

const RejectTool = ({ account }) => {
  const [reject, setReject] = useState({})
  const [rejectStatuses, setRejectStatuses] = useState([])
  const [loading, setLoading] = useState(true)
  const [saveMessage, setSaveMessage] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [showECLModal, setShowECLModal] = useState(false)
  const { handleAlert } = useToast()
  const { userInfo } = useUser()

  const [formInfo, setFormInfo] = useState({
    account_number: account.account_number || '',
    reject_status_code: reject.reject_status || '',
    reject_disposition: reject.reject_disposition || '',
    notes: reject.notes || '',
    reject_rep: userInfo.email
  })

  // inputClassName is just here to be passed to the reusable AccountNumber component
  // it's used in the NewOrder components
  const inputClassName = (inputName) => inputName ? '' : 'mb-3'
  const errorText = (inputErrorValue) => inputErrorValue ? <Form.Text style={{ color: '#E86371' }}>{inputErrorValue}<br /></Form.Text> : ''

  useEffect(() => {
    getAccountReject(account.account_id, setLoading, setReject, handleAlert)
    getRejectStatuses(setRejectStatuses, handleAlert)
  }, [account])


  const handleFormInfo = (e) => {
    setFormInfo({...formInfo, [e.target.name]: e.target.value})
  }

  const openECLModal = () => {
    setShowECLModal(true)
  }

  const handleCloseECLModal = () => {
    setShowECLModal(false)
  }

  const dispositionOptions = () => {
    const defaultDispositions = ['Live Call', 'Call Back', 'Left Voicemail', 'Left Message', 'No Answer', 'Ops Work']
    const resubmitDispositions = ['ECL', 'Bill Image', 'Inbound Phone Call', 'Outbound Phone Call', 'Email', 'Ops Work', 'Chat', 'Text Message']
    if (reject.status === 'RESUBMITTED' || formInfo.status === 'RESUBMITTED') {
      return resubmitDispositions
    } else {
      return defaultDispositions
    }
  }

  const validateForm = useCallback(() => {
    const checkFields = () => {
      const requiredFields = ['account_number', 'reject_status_code', 'reject_disposition']
      const fields = Object.keys(formInfo)
      return fields.reduce((errors, field) => {
        const required = requiredFields.find(fieldName => fieldName === String(field))
        const error = validateField(account.market, field, formInfo[field], required)
        if (error) errors[field] = error
        return errors
      }, {})
    }

    setFormErrors({})
    const errors = checkFields()
    setFormErrors(errors)
    return errors
  }, [formInfo, account.market, setFormErrors])

  // need to double check this logic with mx
  const handleSaveReject = (e) => {
    e.preventDefault()
    const errors = validateForm()
    if (Object.keys(errors).length > 0) {
      setSaveMessage('Please fix the form errors')
      return
    }
    const updatedReject = {...reject, ...formInfo}
    if (formInfo.status === 'WORKED') {
      saveReject(updatedReject, setSaveMessage, handleAlert)
    } else {
      return checkForExistingAccount(updatedReject, handleAlert)
      .then((response) => {
        if (response.exists) {
          setSaveMessage('Account number already exists!')
        } else {
          saveReject(updatedReject, setSaveMessage, handleAlert)
        }
      })
      .finally(() => {
        setTimeout(() => {
          setSaveMessage('')
        }, 5000)
      })
    }
  }

  return (
    <Container className="px-4 py-2">
      {reject?.reject_id && !loading &&
        <>
          <h3> Reject Information </h3>
          <Row>
            <Col md={6}><b>Reject Code:</b> {reject.reject_code}</Col>
            <Col md={6}><b>Secondary ID:</b> {reject.reject_reason}</Col>
          </Row>
          <Row>
            <Col md={6}><b>Reject Reason:</b> {reject.secondary_account_id}</Col>
            <Col md={6}><b>Secondary Date:</b> {DateTime.fromISO(reject.reject_dt).toLocaleString(DateTime.DATE_MED)}</Col>
          </Row>
          <Form.Group className="my-3">
            <Row>
              <Col md={6}>
                <AccountNumber handleFormInfo={handleFormInfo} marketInfo={account.market} formInfo={formInfo} inputClassName={inputClassName} errorText={errorText} formErrors={formErrors}  />
              </Col>
              <Col md={6}>
                  <div className="mt-4">
                    <Button variant="success" onClick={() => openECLModal()}>Lookup Account Number</Button>
                  </div>
              </Col>
            </Row>
            {!isEmpty(rejectStatuses) &&
              <>
                <Form.Label>Status</Form.Label>
                <Form.Select className="mb-3" name="reject_status_code" onChange={e => handleFormInfo(e)}>
                  <option>{formInfo.status}</option>
                  {rejectStatuses.map(status => {
                      return <option key={status.key} value={status.key}>{status.label}</option>
                  })}
                </Form.Select>
                {errorText(formErrors.reject_status_code)}
              </>
            }
            <Form.Label>Disposition</Form.Label>
            <Form.Select className="mb-3" name="reject_disposition" onChange={e => handleFormInfo(e)}>
              <option>{formInfo.disposition}</option>
              {dispositionOptions().map(disposition => {
                  return <option key={disposition} value={disposition}>{disposition}</option>
              })}
            </Form.Select>
            {errorText(formErrors.reject_disposition)}
            <Form.Label className="mt-2">Notes</Form.Label>
            <Form.Control as="textarea" name="notes" className="mb-3" rows={3} value={formInfo.notes} onChange={(e) => handleFormInfo(e)} />
            <Button variant="primary" type="submit" onClick={(e) => handleSaveReject(e)}>
              Submit
            </Button>
            <br />
            <small>{saveMessage}</small>
          </Form.Group>
          <hr />
          {showECLModal &&
            <ECLModal
              show={showECLModal}
              close={handleCloseECLModal}
              reject={reject}
              account={account}
              setFormInfo={setFormInfo}
              formInfo={formInfo}
            />
          }
        </>
      }
    </Container>
  )
}

export default RejectTool;
