import { DateTime } from 'luxon'
import { formatter } from '../../../../utils/currencyFormatter'

export const rewardModalTableColumns = (reward) => {
  switch (reward.template) {
    case 'loyalty_program':
      return (
        [
          {
            Header: 'Invoice Date',
            accessor: 'invoice_dt',
            Cell: (e) => (
              <div>
              {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
              </div>
            )
          },
          { Header: 'Invoice Amount',
            accessor: 'invoice_amt',
          },
          { Header: 'Details',
            accessor: 'accrual_description',
          },
          {
            Header: 'Reward Multiplier',
            accessor: 'loaylty_program_multiplier',
            Cell: (e) => (
              <div>
              {e.value ? (e.value * 100) : '' }
              </div>
            )
          },
          {
            Header: 'Accrual Amount',
            accessor: 'accrued_amount',
          }, {
            Header: 'Accrual Date',
            accessor: 'accrued_dt',
            Cell: (e) => (
              <div>
              {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
              </div>
            )
          },  {
            Header: 'Vesting Date',
            accessor: 'vesting_dt',
            Cell: (e) => (
              <div>
              {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
              </div>
            )
          }
        ]
      )
    default:
      return (
        [
          {
            Header: 'Reward',
            accessor: 'accrued_amount',
            Cell: (e) => (
              <div>
              {e.value ? `${formatter.format(e.value)} ${e.row.original.reward_units || ''}` : ''}
              </div>
            )
          }, {
            Header: 'Accrual Date',
            accessor: 'accrued_dt',
            Cell: (e) => (
              <div>
              {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
              </div>
            )
          }, {
            Header: 'Vesting Date',
            accessor: 'vesting_dt',
            Cell: (e) => (
              <div>
              {e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : ''  }
              </div>
            )
          }
        ]
      )
  }
}
