import React, { useState } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap"
import { requestAccountDrop, getDropReasons } from '../../../apis/accounts'
import { getRetentionOffers } from '../../../apis/offers'
import { snoozeWinbacks } from '../../../apis/offers'
import isEmpty from 'lodash/isEmpty'
import useUser from "../../../hooks/useUser"
import useToast from '../../../hooks/useToast'
import { v4 as uuidv4 } from 'uuid'

const CancelButton = ({ account, setDropReasons, reasons, setConfirmationCode }) => {
  const { userInfo } = useUser();
  const { handleAlert } = useToast()
  const [offers, setOffers] = useState(null)
  const [payload, updatePayload] = useState({
    account_drop_reason_id: null,
    is_escalation: false,
    drop_offer_code: null,
    audit: {
      user_name: userInfo.email
    }
  })

  const dropAccount = () => {
    requestAccountDrop(account.account_id, payload, handleAlert, setConfirmationCode)
  }

  const cancel = async () => {
    getDropReasons(setDropReasons)
    const retentionOffers = await getRetentionOffers(account?.account_id)
    setOffers(retentionOffers?.results)
  }

  return (
    <>
      {
        isEmpty(reasons) ?
          <Button
            className="m-3 btn-lg"
            variant="danger"
            onClick={cancel}>
            Cancel Membership
          </Button>
          :
          <Row>
            <h3>Are you sure you want to cancel the Member?</h3>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Reason for Cancellation:</Form.Label>
                <Form.Select value={payload.account_drop_reason_id || '' } onChange={e => updatePayload({ ...payload, account_drop_reason_id: e.target.value })} aria-label="Reason for Cancellation:">
                  {reasons.map(i =>
                    <option key={uuidv4()} value={i.account_drop_reason_id}>{i.description}</option>
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Change Account's Offer:</Form.Label>
                <Form.Select value={payload?.drop_offer_code || ''} onChange={e => updatePayload({ ...payload, drop_offer_code: e.target.value })}>
                  <option key={uuidv4()} value={''}></option>
                  {offers && offers.map(o =>
                    <option key={uuidv4()} value={o.offer_code}>{o.plan_name}</option>
                  )}
                </Form.Select>
              </Form.Group>
              <Button
                className="mb-1"
                variant="danger"
                onClick={() => snoozeWinbacks(account.account_id, handleAlert)}>
                Snooze Winbacks
              </Button>
              <Button
                className="mb-1"
                variant="primary"
                onClick={dropAccount}>
                Yes, I've Done All That I Could!
              </Button>
              <br />
              <Button
                variant="link"
                onClick={() => setDropReasons(null)}>
                Back
              </Button>
            </Col>
          </Row>
      }
    </>
  )
};

export default CancelButton;
