import React from 'react'
import { Button } from 'react-bootstrap'

const CreateCaseButton = ({ showCaseForm, setShowCaseForm }) => {
  return (
    <Button
      className="m-3 btn-lg"
      variant="primary"
      onClick={() => setShowCaseForm(!showCaseForm)}>
      Create Case
    </Button>
  )
}

export default CreateCaseButton;
