import React from 'react'
import { useTable, usePagination,  useResizeColumns, useBlockLayout, useExpanded } from 'react-table'
import Table from 'react-bootstrap/Table'
import { Row, Col, Button } from 'react-bootstrap'

const ReactTableExpanding = ({ columns, data, handleClick, canHover }) =>  {

  const defaultColumn = React.useMemo(() => ({
    minWidth: 30,
    width: 110,
    maxWidth: 300,
  }), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    } = useTable(
    {
    columns: columns,
    data: data,
    initialState: { pageIndex: 0, pageSize: 10 },
    defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
    useExpanded,
    usePagination,
  )

  return (
    <>
      <Table striped bordered hover={canHover} responsive size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th scope="col" {...column.getHeaderProps()}>
                {column.render('Header')}
                <div className={`resizer ${
                    column.isResizing ? 'isResizing' : ''
                  }`}
                  {...column.getResizerProps()}
                />
              </th>
            ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps( { style: { cursor: "pointer" }})}
                onClick={() => handleClick && handleClick(row.original)}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div style={{  margin: "0 auto", textAlign: "center" }}>
      <Row>
        <Col style={{  marginLeft: "inherit" }}>
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>{' '}
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>{' '}
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>{' '}
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>{' '}
        </Col>

        <Col md={4}>
        <span >
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span >
          | Go to page:{' '}
          <input
            className="form-control"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '50px', display: "inherit", lineHeight: "1.5" }}
          />
        </span>{' '}
        </Col>
        <Col style={{  marginRight: "20px" }}>
        <select
          md={{ span: 1, offset: 10 }}
          className="custom-select d-block "
          style={{ lineHeight: "0.75", padding: "0rem 1.75rem 0rem 0.75rem"  }}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        </Col>
        </Row>
      </div>
    </>
  )
}

export default ReactTableExpanding
