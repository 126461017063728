import React from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import OfferCards from "../../components/offers/OfferCards"

const Offers = () => {
  const { shopper_short_key } = useParams()

  return (
    <>
      <Helmet title="Offers" />
      <h1>Offers</h1>
      <OfferCards shopper_short_key={shopper_short_key} />
    </>
  )
}

export default Offers
