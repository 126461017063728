import axios from 'axios'

export const getUtility = async (utilityCode, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/utilities/${utilityCode}`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting utility info: ${error}`, false)
    throw error
  }
}
