import React from 'react'
import { Card } from 'react-bootstrap'
import ReactTableBasic from '../../ReactTableBasic'
import { DateTime } from 'luxon'
import { isEmpty } from 'lodash'
import useToast from '../../../hooks/useToast'
import { addTransaction } from '../../../apis/accounts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPuzzle } from '@fortawesome/pro-light-svg-icons'


const PossibleTransactions = ({ member, possTransactions, resetTransactions }) => {
  const accountId = member.account_id
  const { handleAlert } = useToast()

  const handleTransaction = async (e) => {
    addTransaction(accountId, e.row.original, handleAlert, resetTransactions)
  }

  const tableColumns = [
    {
      Header: 'Internal ID',
      accessor: 'account_transaction_id',
      width: 85,
    },
    {
      Header: 'Raw Transaction ID',
      accessor: 'transaction_id',
      width: 100,
    },
    {
      Header: 'Transaction Code',
      accessor: 'transaction_action_code',
      width: 80,
    },
    {
      Header: 'Transaction Qualifier',
      accessor: 'transaction_qualifier',
      width: 80,
    },
    {
      Header: 'Transaction Response Qualifier',
      accessor: 'transaction_response_qualifier',
      width: 80,
    },
    {
      Header: 'Reason Code',
      accessor: 'reason_code',
      width: 75,
    },
    {
      Header: 'Reason Description',
      accessor: 'reason_description',
      width: 95,
    },
    {
      Header: 'Transaction Date',
      accessor: 'transaction_date',
      width: 80,
      Cell: (e) => (
        e.value ? DateTime.fromISO(e.value).toLocaleString(DateTime.DATE_MED) : 'N/A'
      )
    },
    {
      Header: 'Utility',
      accessor: 'sender_utility.utility_name',
      width: 75,
    },
    {
      Header: 'Account Number',
      accessor: 'account_number',
      width: 75,
    },
    {
      Header: 'Assign to Account',
      accessor: '',
      width: 50,
      Cell: e => (
        <div className="text-center">
            <FontAwesomeIcon size="2x" color="#FF8676" icon={faPuzzle} onClick={() => handleTransaction(e)} />
        </div>

      ),
    },
  ]

  return (
    <div>
      <hr />
      <>
      {!isEmpty(possTransactions) ?
        <div>
          <ReactTableBasic
            title="Possible Account Transactions"
            columns={tableColumns}
            data={possTransactions}
            canHover={true}
          />
        </div>
      :
        <Card.Title>
          No possible transactions for the listed account number
        </Card.Title>
      }
      </>
    </div>
  )
}

export default PossibleTransactions
