import axios from 'axios'
import { DateTime } from 'luxon'

export const getAddressFromId = async(addressId, callback, handleAlert) => {
  try {
    const response  = await axios.get(`${process.env.REACT_APP_GARCON_API}/addresses/id/${addressId}`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting this address: ${error}`, false)
    throw error
  }
}

export const getAddressFromGuid = async(addressGuid, callback, handleAlert) => {
  try {
    const response  = await axios.get(`${process.env.REACT_APP_GARCON_API}/addresses/${addressGuid}`)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong getting this address: ${error}`, false)
    throw error
  }
}

export const autocompleteAddress = async(street) => {
  try {
    return await axios.get(`${process.env.REACT_APP_GARCON_API}/addresses/smartystreets/autocomplete/${street}`)
  } catch (error) {
    throw error
  }
}

export const validateAddress = async (address, callback, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/addresses/smartystreets/validate`, {params : { street: address.street, city: address.city, state: address.state} })
    callback(response.data[0])
  } catch (error) {
    handleAlert(`Something went wrong validating the address: ${error}`, false)
    throw error
  }
}

export const saveProspectAddress = async (prospectGuid, address, userInfo, handleAlert) => {
  const payload = {
    street: address.street,
    unit: address.unit,
    city: address.city,
    state_code: address.state,
    zip: address.zip,
    address_attribute_source_code: 'INSPIRE',
    vendor_code: 'INSPIRE',
    audit: {
      user_name: userInfo.email,
      user_id: userInfo.user_id,
      source: 'CRM',
      date: DateTime.now().toISO()
    }
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/prospects/prospect/${prospectGuid}/address`, payload)
    if(response){
      handleAlert('Address Updated!', true)
      return response
    } else {
      handleAlert(response.statusText, false)
    }
  } catch (error) {
    handleAlert(`Something went wrong saving address ${error}`, false)
    throw error
  }
}
