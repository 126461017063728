import React from 'react'
import { Col, Card, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  windTurbine from '../../assets/img/illustrations/Spot-windturbine-lakeblue.png'
import { parseIcon } from './helpers/EnergyImpactHelpers'

const EnergyImpact = ({ impact }) => {
  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="wind-turbine flex-fill">
          <Card.Body className="p-0 d-flex flex-fill">
            <Row className="g-0 w-100">
              <Col>
                <div className="m-2">
                  <h3 className="wind-turbine-text">
                    Congrats! You've helped save:
                  </h3>
                </div>
                <img
                  style={{width: 75}}
                  src={windTurbine}
                  alt="Wind Turbine"
                  className="img-fluid illustration-img"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      {impact.map((impact, index) => {
        const {first_amount, first_unit, icon} = impact
        return (
          <Col key={`${first_unit}-${index}`} md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">{first_amount}</h3>
                    <p>{first_unit}</p>
                    <FontAwesomeIcon size="2x" color="#FF88A4" icon={parseIcon(icon)} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        )
      })}
    </Row>
  )
}

export default EnergyImpact;
