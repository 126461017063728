import axios from 'axios'

export const getOrdersList = async (account_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${account_id}/orders`)
    callback(response.data.sort((a, b) => {
      return (b.order_dt < a.order_dt) ? -1 : ((b.order_dt > a.order_dt) ? 1 : 0);
    }))
  } catch (error) {
    throw error
  }
}

export const getOrder = async (order_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/orders/order/${order_id}/details`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const submitOrder = async (params, callback, handleAlert) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/sales/app/orders`, params)
    callback(response.data)
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}
