import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'
import useToast from '../../../hooks/useToast'
import { voidAndReissueCheck } from '../../../apis/rewards'
import { getAddressFromAttribute } from '../../../apis/case'
import AddressModal from '../../member/AddressModal'

export const ReissueCheck = ({account, bill, setShowEditReissue}) => {
  const [address, setAddress] = useState({})
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [billReissueInfo, setBillReissueInfo] = useState({
    first_name: account.membership_profile.first_name,
    last_name: account.membership_profile.last_name,
    address_guid: account.mailing_address_address_guid
  })
  const { handleAlert } = useToast()

  useEffect(() => {
    getAddressFromAttribute(billReissueInfo.address_guid, setAddress)
  }, [billReissueInfo.address_guid])

  const handleVoidAndReissueCheck = () => {
    const params = {
      accountId: account.account_id,
      billReissueInfo: billReissueInfo,
      accountPayableBillId: bill.account_payable_bill_id
    }
   voidAndReissueCheck(params, handleAlert)
   setShowEditReissue(false)
  }

  const updateBillAddressGuid = (newAddressGuid) => {
    setBillReissueInfo({...billReissueInfo, address_guid: newAddressGuid})
  }

  return(
    <>
      <Row className="my-3">
        <Col className="me-4">
          <Row>
            <Form.Group controlId="first-name" className="mb-2">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                htmlSize="40"
                onChange={(e) => setBillReissueInfo(billReissueInfo => ({...billReissueInfo, [e.target.name]: e.target.value}))}
                value={billReissueInfo.first_name}
              />
            </Form.Group>
            </Row>
            <Row>
            <Form.Group controlId="last-name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                htmlSize="40"
                onChange={(e) => setBillReissueInfo(billReissueInfo => ({...billReissueInfo, [e.target.name]: e.target.value}))}
                value={billReissueInfo.last_name}
              />
            </Form.Group>
          </Row>
        </Col>
        <Col className="ms-3">
          <Row className="mt-2">
            Address
          </Row>
          <Row className="border border-dark px-4 py-2">
              {address.street} {address.unit || ''} <br />
              {address.city}, {address.state_code} {address.zip}
              <Button className="mt-3" variant="primary" onClick={() => setShowAddressModal(true)}>Edit</Button>
              <AddressModal
                addressId={address.address_id}
                prospectGuid={account.membership_profile.prospect_guid}
                handleClose={() => setShowAddressModal(false)}
                show={showAddressModal}
                updateBillAddressGuid={updateBillAddressGuid}
              />
          </Row>
        </Col>
      </Row>
      <Row className="my-4">
        <Button className="btn btn-danger" onClick={() => handleVoidAndReissueCheck()}>
          Void and Reissue Check
        </Button>
      </Row>
    </>
  )
}

export default ReissueCheck
