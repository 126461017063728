/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { Helmet } from "react-helmet-async"
import { Col, Container, Row, Form, Button, Spinner } from "react-bootstrap"
import useUser from '../../hooks/useUser'
import { getCampaigns, getMarketInfo, updateZip } from '../../apis/offers'
import Select from "react-select"
import { createProspect, getProspect } from '../../apis/prospects'
import { useNavigate, useLocation } from 'react-router-dom'
import { createShopper } from '../../apis/shoppers'
import isEmpty from 'lodash/isEmpty'
import { validateField } from '../../utils/formValidation'
import AccountNumber from '../../utils/accountNumber'
import AsyncSelect from 'react-select/async'
import useToast from '../../hooks/useToast'
import { autocompleteAddress, validateAddress } from '../../apis/address'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const NewOrder = () => {
  const { userInfo } = useUser()
  const { handleAlert } = useToast()
  const [prospect, setProspect] = useState({})
  const [billing_check, setBillingCheck] = useState(false)
  const [contact_check, setContactCheck] = useState(false)
  const query = useQuery()
  const prospect_guid = query.get("prospect_guid")
  const [campaigns, setCampaigns] = useState([])
  const [gettingOffers, setGettingOffers] = useState(false)
  const [marketOptions, setMarketOptions] = useState({})
  const [marketInfo, setMarketInfo] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [submitClicked, setSubmitCicked] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const newMarkets = []
  const [validatedAddress, setValidatedAddress] = useState({})
  const [selectedAddress, setSelectedAddress] = useState({
    value: '',
    label: '',
    street: '',
  })
  const [formInfo, setFormInfo] = useState({
    revenue_class_code: 'RESI',
    first_name: '',
    last_name: '',
    primary_phone: '',
    email: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_relationship: '',
    zip_code: '',
    service_state: '',
    service_city: '',
    service_address_line_1: '',
    service_address_line_2: '',
    business_name: '',
    billing_street: '',
    billing_suite: '',
    billing_city: '',
    billing_state_code: '',
    billing_zip: '',
    market_code: '',
    account_number: '',
    campaign: '',
    prospect_guid: ''
  })

  const handleZip = async (zip_code) => {
    if (zip_code.length !== 5) {
      setFormInfo({ ...formInfo, service_state: '', service_city: '', zip_code })
    }
    else {
      const response = await updateZip(zip_code)
      if (response?.data && !isEmpty(response?.data)) {
        setFormInfo({ ...formInfo, service_state: response.data[0].state_code, service_city: response.data[0].city, zip_code: zip_code })
        response.data.forEach((market) => {
          newMarkets.push({
            value: market.market_code,
            label: market.market_code,
          })
          setMarketOptions(newMarkets)
        })
      } else if (isEmpty(response?.data)) {
        handleAlert(`No zip code information was found for ${zip_code}`, false)
      }
    }
  }

  const handleFormInfo = (e) => {
    e.preventDefault()
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value })
  }

  const validateForm = useCallback(() => {
    const checkFields = () => {
      const requiredFields = ['first_name', 'last_name', 'service_state', 'service_city']
      const fields = Object.keys(formInfo)
      return fields.reduce((errors, field) => {
        const required = requiredFields.find(fieldName => fieldName === String(field))
        const error = validateField(marketInfo, field, formInfo[field], required)
        if (error) errors[field] = error
        return errors
      }, {})
    }

    setFormErrors({})
    const errors = checkFields()
    setFormErrors(errors)
    return errors
  }, [formInfo, marketInfo, setFormErrors])

  const handleNewProspect = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    setSubmitCicked(true)
    const errors = validateForm()
    if (Object.keys(errors).length === 0) {
      const response = await createProspect(formInfo, userInfo)
      if (response.status === 200) {
        navigate(`/prospect/${response.data.prospect_guid}`)
      }
    }
    setSubmitting(false)
  }

  const handleGetOffers = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    setSubmitCicked(true)
    const errors = validateForm()
    if (Object.keys(errors).length === 0) {
      setGettingOffers(true)
      const response = await createShopper(formInfo, userInfo)
      if (response.status === 200) {
        navigate(`/order/${response.data.short_key}/offers`)
      }
      setGettingOffers(false)
    }
    setSubmitting(false)
  }

  useEffect(() => {
    getCampaigns(userInfo, setCampaigns)
    if (prospect_guid){
      getProspect(prospect_guid, setProspect)
      }
  }, [prospect_guid])

  useEffect(() => {
    getMarketInfo(formInfo, setMarketInfo)
  }, [formInfo.market_code])

  useEffect(() => {
    if (submitClicked) validateForm()
  }, [formInfo, submitClicked])

  const campaignOptions = campaigns?.map(campaign => ({ value: campaign.campaign_id, label: campaign.campaign_name }))
  const submitButtonDisabled = !!Object.keys(formErrors).length
  const inputClassName = (inputName) => inputName ? '' : 'mb-3'
  const errorText = (inputName) => inputName ? <Form.Text style={{ color: '#E86371' }}>{inputName}</Form.Text> : ''

  useEffect(() => {
    if (prospect.prospect_guid) {
    setFormInfo({
      ...formInfo,
      first_name: prospect.first_name || '',
      last_name: prospect.last_name || '',
      primary_phone: prospect.primary_phone || '',
      email: prospect.email?.email_address || '',
      zip_code: prospect.service_zip_code || '',
      service_state: prospect.service_state || '',
      service_city: prospect.service_city || '',
      service_address_line_1: prospect.service_address_line_1 || '',
      service_address_line_2: prospect.service_address_line_2 || '',
      market_code: prospect.market_code || '',
      account_number: prospect.account_number || '',
      campaign: prospect.campaign_id || '',
      prospect_guid: prospect.prospect_guid || ''
    })
  }
  }, [prospect])

  useEffect(() => {
    if(formInfo.zip_code) {
      handleZip(formInfo.zip_code)
    }
  }, [formInfo.zip_code])

  const handleStreet = (street) => {
    if (street.length > 3){
      return autocompleteAddress(street)
        .then((response) => {
          let options = response.data.map((smartStreet) => ({
            value: smartStreet.street_line,
            label: smartStreet.text,
            street: smartStreet.street_line,
            city: smartStreet.city,
            state: smartStreet.state
          }))
          return options
        })
        .catch((error) => {
          handleAlert(`Cannot handle a smart street ${error}`, false)
        })
    }
  }

  const onStreetChange = (newStreetAddress) => {
    if (newStreetAddress) {
      setSelectedAddress({...selectedAddress, street: newStreetAddress})
    }
  }

  const changeSelectedAddress = (selectedAddress) => {
    selectedAddress = (selectedAddress == null) ? {} : selectedAddress
    setSelectedAddress(selectedAddress)
    validateAddress(selectedAddress, setValidatedAddress, handleAlert)
  }

  useEffect(() => {
    if (validatedAddress?.components) {
      setFormInfo({...formInfo,
        zip_code: validatedAddress.components.zipcode,
        service_address_line_1: validatedAddress.delivery_line_1,
      })
    }
  }, [validatedAddress])

  return (
    <React.Fragment>
      <Helmet title="New Order" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">New Order</h1>
        <Form>
          <Row>
            <Col md="4">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="first_name"
                size="lg"
                className={[inputClassName(formErrors.first_name)]}
                onChange={handleFormInfo}
                value={formInfo.first_name}
                isInvalid={formErrors.first_name}
              />
              {errorText(formErrors.first_name)}
            </Col>
            <Col md="4">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="last_name"
                size="lg"
                className={[inputClassName(formErrors.last_name)]}
                value={formInfo.last_name}
                onChange={handleFormInfo}
                isInvalid={formErrors.last_name}
              />
              {errorText(formErrors.last_name)}
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                required
                type="tel"
                name="primary_phone"
                size="lg"
                className={[inputClassName(formErrors.primary_phone)]}
                value={formInfo.primary_phone}
                onChange={handleFormInfo}
                isInvalid={formErrors.primary_phone}
              />
              {errorText(formErrors.primary_phone)}
            </Col>
            <Col md="4">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                size="lg"
                className={[inputClassName(formErrors.email)]}
                onChange={handleFormInfo}
                value={formInfo.email}
                isInvalid={formErrors.email}
              />
              {errorText(formErrors.email)}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Check
                label="Add contact info?"
                name="contact_check"
                defaultChecked={contact_check}
                onClick={e => setContactCheck(e.target.checked)}
                className="mb-2"
              />
            </Col>
          </Row>
          {contact_check &&
            <span>
              <Row>
                <Col md="4">
                  <Form.Label htmlFor="contact_first_name">Contact First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_first_name"
                    size="lg"
                    className="mb-3"
                    onChange={handleFormInfo}
                    value={formInfo.contact_first_name}
                  />
                </Col>
                <Col md="4">
                  <Form.Label htmlFor="contact_last_name">Contact Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_last_name"
                    size="lg"
                    className="mb-3"
                    onChange={handleFormInfo}
                    value={formInfo.contact_last_name}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Form.Label htmlFor="contact_email">Contact Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="contact_email"
                    size="lg"
                    className="mb-3"
                    onChange={handleFormInfo}
                    value={formInfo.contact_email}
                  />
                </Col>
                <Col md="3">
                  <Form.Label htmlFor="contact_relationship">Contact Relationship</Form.Label>
                  <Select
                    aria-label="Type"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="contact_relationship"
                    options={[
                      { value: 'Spouse/Domestic Partner', label: 'Spouse/Partner' },
                      { value: 'Roommate', label: 'Roommate' },
                      { value: "Tenant", label: "Tenant" },
                      { value: "Immediate Family", label: "Immediate Family" },
                      { value: "Other", label: "Other" },
                    ]}
                    onChange={e => setFormInfo({ ...formInfo, contact_relationship: e.value })}
                  />
                </Col>
              </Row>
            </span>
          }
          <hr className="my-5" />
          <Row>
            <Col md="2">
              <Form.Label htmlFor="zip_code">Zip Code</Form.Label>
              <Form.Control
                required
                type="text"
                name="zip_code"
                data-testid="zip_code"
                size="lg"
                className={inputClassName(formErrors.zip_code)}
                value={formInfo.zip_code}
                onChange={e => handleZip(e.target.value)}
                isInvalid={formErrors.zip_code}
              />
              {errorText(formErrors.zip_code)}
            </Col>
            <Col md="2">
              <Form.Label htmlFor="state">State</Form.Label>
              <Form.Control
                required
                type="text"
                name="service_state"
                data-testid="service_state"
                size="lg"
                className={inputClassName(formErrors.service_state)}
                value={formInfo.service_state}
                onChange={handleFormInfo}
                isInvalid={formErrors.service_state}
              />
              {errorText(formErrors.service_state)}
            </Col>
            <Col md="4">
              <Form.Label htmlFor="city">City</Form.Label>
              <Form.Control
                required
                type="text"
                name="service_city"
                size="lg"
                className={inputClassName(formErrors.service_city)}
                value={formInfo.service_city}
                onChange={handleFormInfo}
                isInvalid={formErrors.service_city}
              />
              {errorText(formErrors.service_city)}
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Form.Label>Address 1</Form.Label>
              <AsyncSelect
                required
                name="service_address_line_1"
                data-testid="service-address"
                placeholder={formInfo.service_address_line_1||"Type address..."}
                defaultOptions={[]}
                loadOptions={handleStreet}
                value={formInfo.service_address_line_1}
                isInvalid={formErrors.service_address_line_1}
                className={inputClassName(formErrors.service_address_line_1)}
                onInputChange={onStreetChange}
                onChange={changeSelectedAddress}
              />
              {errorText(formErrors.service_address_line_1)}
            </Col>
            <Col md="3">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                name="service_address_line_2"
                size="lg"
                onChange={handleFormInfo}
                value={formInfo.service_address_line_2}
              />
            </Col>
          </Row>
          <Row className="mb-3">
          </Row>
          <Row className="mb-2">
            <Col md="2">
              <label>Type</label>
              <Select
                className={["react-select-container", inputClassName(formErrors.revenue_class_code)]}
                classNamePrefix="react-select"
                name="revenue_class_code"
                options={[
                  { value: 'RESI', label: 'Residential' },
                  { value: 'SMALL-COM', label: 'Commercial' },
                ]}
                onChange={e => setFormInfo({ ...formInfo, revenue_class_code: e.value })}
                defaultValue={{ label: "Residential", value: 'RESI' }}
                isInvalid={formErrors.revenue_class_code}
              />
              {errorText(formErrors.revenue_class_code)}
            </Col>
            {formInfo.revenue_class_code === "SMALL-COM" &&
              <Col md="4">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  name="business_name"
                  size="lg"
                  onChange={handleFormInfo}
                  value={formInfo.business_name}
                />
              </Col>
            }
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Check
                label="Add mailing address?"
                name="billing_check"
                defaultChecked={billing_check}
                onClick={e => setBillingCheck(e.target.checked)}
                className="mb-2"
              />
            </Col>
          </Row>
          {billing_check &&
            <span>
              <Row>
                <Col md="4">
                  <Form.Label>Mailing Address 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_street"
                    size="lg"
                    className="mb-3"
                    value={formInfo.billing_street}
                  />
                </Col>
                <Col md="4">
                  <Form.Label>Mailing Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_suite"
                    size="lg"
                    value={formInfo.billing_suite}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Form.Label htmlFor="city">Mailing City</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_city"
                    size="lg"
                    className="mb-3"
                    value={formInfo.billing_city}
                  />
                </Col>
                <Col md="2">
                  <Form.Label htmlFor="state">Mailing State</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_state_code"
                    size="lg"
                    className="mb-3"
                    value={formInfo.billing_state_code}
                  />
                </Col>
                <Col md="2">
                  <Form.Label htmlFor="zip_code">Mailing Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_zip"
                    size="lg"
                    className="mb-3"
                    value={formInfo.billing_zip}
                  />
                </Col>
              </Row>
            </span>
          }
          <hr className="my-5" />
          { !isEmpty(formInfo.service_state) &&
            <span>
              <Row>
                <Col md="3">
                  <label>Utility Company</label>
                  <Select
                    className={["react-select-container", inputClassName(formErrors.market_code)]}
                    classNamePrefix="react-select"
                    onChange={e => setFormInfo({ ...formInfo, market_code: e.value })}
                    placeholder={"Select..."}
                    options={marketOptions}
                    isInvalid={formErrors.market_code}
                  />
                  {errorText(formErrors.market_code)}
                </Col>
                <Col md="1"></Col>
                <Col md="4">
                  <AccountNumber marketInfo={marketInfo} formInfo={formInfo} inputClassName={inputClassName} errorText={errorText} formErrors={formErrors} prospect={prospect} handleFormInfo={handleFormInfo} />
                </Col>
                <Row>
                  <Col>
                  </Col>
                </Row>
              </Row>
            </span>
          }
          <Row>
            {campaigns &&
              <Col md="3" className='mt-3'>
                <label>Campaign</label>
                <Select
                  aria-label="Select Campaign"
                  className="react-select react-select-container"
                  classNamePrefix="react-select"
                  defaultValue={prospect.campaign_id}
                  onChange={e => setFormInfo({ ...formInfo, campaign: e.value })}
                  placeholder={prospect.campaign_id || "Select..."}
                  options={campaignOptions}
                />
              </Col>
            }
          </Row>
          <br />
          <Row>
            {gettingOffers || submitting ?
              <Col md={{ offset: 9 }}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2 ml-4"
                />
                <span>Processing...</span>
              </Col>
              : <Col md={{ offset: 6 }}>
                <Button variant="success" className="m-3" type="submit" disabled={submitButtonDisabled} onClick={handleNewProspect}>
                  Create New Prospect
                </Button>

                <Button variant="primary" className="m-3" disabled={submitButtonDisabled} onClick={handleGetOffers}>
                  Get Offers
                </Button>
              </Col>
            }
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default NewOrder
