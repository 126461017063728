import axios from 'axios'
import { DateTime } from "luxon"

export const getCorrespondenceLetters = async(accountId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/correspondence_letters`)
    callback(response.data.data)
  } catch (error) {
    throw error
  }
}

export const getWelcomeKit = async(accountId, callback, handleAlert) => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/welcome_kit`)
    if(response.data){
      response.data['correspondence_type_code'] = 'WELCOME_LETTER'
      response.data['can_resend_via_email'] = true
      response.data['view'] = response.data['url']

      callback(response.data)
    }
  } catch (error) {
    handleAlert(`There was an error getting the welcome kit: ${error}`, false)
    throw error
  }
}

export const sendCorrespondence = async(accountCorrespondence, user, handleAlert) => {
  try {
    const params = {
      account_correspondence_id: accountCorrespondence.account_correspondence_id,
      audit : {
        user_name: user.email,
        source: 'CRM',
        date: DateTime.now().toISO()
      }
    }
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/correspondence/mail`, params)
    return response
  }
  catch (error) {
    handleAlert(`An error occurred sending correspondence: ${error}`, false)
    throw error
  }
}

export const emailCorrespondence = async (accountCorrespondence, user, handleAlert) => {
  try {
    const params = {
      account_correspondence_id: accountCorrespondence.account_correspondence_id,
      url: accountCorrespondence.archive_loc,
      audit: {
        user_name: user.email,
        source: 'CRM',
        date: DateTime.now().toISO()
      }
    }
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/correspondence/email`, params)
    return response
  } catch (error) {
    handleAlert(`An error occurred sending correspondence: ${error}`, false)
    throw error
  }
}

export const viewCorrespondence = async (accountId, correspondence, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/${accountId}/correspondence_letters/${correspondence.account_correspondence_id}`)
    if (response.data?.url) {
      window.open(response.data.url)
    } else {
      handleAlert('There was an error retrieving the correspondence', false)
    }
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}

export const viewTerms = async (termsArchiveLoc, handleAlert) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/private_terms/archive?terms_archive_loc=${termsArchiveLoc}`)
    if (response.data) {
      window.open(response.data)
    } else {
      handleAlert('There was an error retrieving the terms url', false)
    }
  } catch (error) {
    handleAlert(`Something went wrong: ${error}`, false)
    throw error
  }
}
