import axios from 'axios'

export const getConversationItems = async(conversationId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/conversations/conversations/${conversationId}/conversation_items`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}
