/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import useUser from '../../../hooks/useUser'
import { getAgentRefundSummary } from '../../../apis/agents'
import RefundAttributeTextInput from './RefundAttributeTextInput'
import RefundCalculationContainer from './RefundCalculationContainer'
import RewardsRefundContainer from './RewardsRefundContainer'
import { v4 as uuidv4 } from 'uuid'
import AddressModal from '../../member/AddressModal'
import findCaseAttribute from '../helpers/findCaseAttribute'

const CaseRefundContainer = ({caseTypeAttributes, setCaseTypeAttributes, address, handleUpdateCaseAttribute, accountId, prospectGuid, caseCreatedByAgentId}) => {
  const { userInfo } = useUser()
  const [agentRefundSummary, setAgentRefundSummary] = useState(null)
  const isRewardsRefund = caseTypeAttributes.some(attribute => attribute.data_name === 'refund_reward_ids')
  const [showAddressModal, setShowAddressModal] = useState(false)
  const caseAddressAttribute = findCaseAttribute('refund_address_guid', caseTypeAttributes)
  const caseRefundCalculationAttribute = findCaseAttribute('refund_calculation', caseTypeAttributes)

  useEffect(() => {
    caseCreatedByAgentId ? getAgentRefundSummary(caseCreatedByAgentId, setAgentRefundSummary) : getAgentRefundSummary(userInfo.user_id, setAgentRefundSummary)
  }, [caseTypeAttributes])

  const attributeTextInputs = () => {
    return caseTypeAttributes.filter(attribute => attribute.data_type === 'text').map(attributeInput => {
      return  <RefundAttributeTextInput
                key={uuidv4()}
                attributeInput={attributeInput}
                caseTypeAttributes={caseTypeAttributes}
                setCaseTypeAttributes={setCaseTypeAttributes}
                handleUpdateCaseAttribute={handleUpdateCaseAttribute}
              />
    })
  }

  return (
    <>
      <Row className="mt-3">
        <Form.Group controlId="agent-refund-summary">
            <Form.Label><b>Agent Refund Summary</b></Form.Label>
        </Form.Group>
      </Row>
      {agentRefundSummary &&
        <>
          <Row className="border">
            <Row className="mt-1">
              <Col md={2}>
                <b>Spent: </b>
              </Col>
              <Col md={2}>
                <b>Remaining: </b>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <p>$ {agentRefundSummary.spent}</p>
              </Col>
              <Col md={2}>
              <p>$ {Math.round((agentRefundSummary.limit - agentRefundSummary.spent) * 100) / 100}</p>
              </Col>
            </Row>
          </Row>
        </>
      }
      {address &&
        <>
          <Row className="mt-3">
            <Form.Group controlId="refund-address">
                <Form.Label><b>Refund Address</b></Form.Label>
            </Form.Group>
          </Row>
          <Row className="border my-1">
            <Row className="mt-1">
              <Col>
                {address.street} {address.unit} <br />
                {address.city}, {address.state_code} {address.zip}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <Button variant="primary" onClick={() => setShowAddressModal(true)} className="mb-2">Edit</Button>
                <AddressModal
                  addressId={address.address_id}
                  prospectGuid={prospectGuid}
                  handleClose={() => setShowAddressModal(false)}
                  show={showAddressModal}
                  handleUpdateCaseAttribute={handleUpdateCaseAttribute}
                  caseAddressAttribute={caseAddressAttribute}
                />
              </Col>
            </Row>
          </Row>
          {isRewardsRefund &&
            <RewardsRefundContainer
              caseTypeAttributes={caseTypeAttributes}
              setCaseTypeAttributes={setCaseTypeAttributes}
              accountId={accountId}
              handleUpdateCaseAttribute={handleUpdateCaseAttribute}
            />
          }
          {caseRefundCalculationAttribute &&
            <RefundCalculationContainer
              caseTypeAttributes={caseTypeAttributes}
              setCaseTypeAttributes={setCaseTypeAttributes}
              handleUpdateCaseAttribute={handleUpdateCaseAttribute}
            />
          }
          {attributeTextInputs()}
        </>
      }
    </>
  )
}

export default CaseRefundContainer
